<section>
    <div class="container-fluid">
      <br>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 colBtmMrg" *ngFor="let item of list">
                <div class="prod--wrapper">
                    <div class="prod--col prod--img">
                      <div id="productImage" class="prod--img-graphic skeleton-loader"></div>
                    </div>
                    <div class="prod--col prod--details">
                      <div class="prod--row prod--name w76">
                        <span id="productName" class="prod--name-text skeleton-loader"></span>
                      </div>
                      <div class="prod--row prod--description w87">
                        <span
                          id="productId"
                          class="prod--description-text skeleton-loader"
                          ></span>
                      </div>
                      <div class="prod--row prod--description">
                        <span
                          id="productId"
                          class="prod--description-text skeleton-loader"
                          ></span>
                      </div>
                    </div>
                  </div>              
            </div>
        </div>    
    </div>
</section>    
    
