import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoWhitespaceValidatorDirective } from './no-whitespace.directive';



@NgModule({
  declarations: [NoWhitespaceValidatorDirective],
  exports: [NoWhitespaceValidatorDirective],
  imports: [
    CommonModule
  ]
})
export class NoWhitespaceModule { }
