import { Component, HostListener, OnInit } from '@angular/core';
import { CommanservicesService } from 'src/app/services/commanservices.service';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  faqList:any=[{question:' Do you deliver only within India or overseas?',
  answer:' We deliver in over 4600 cities within India and to more than 70 countries. You can check the serviceability of an item'},
  {question:' Can I choose the delivery time?',
  answer:' All courier products are delivered between 9 am– 9 pm through our selected courier partners.'},
  {question:' Can I get my order delivered at midnight?',
  answer:' If you choose midnight delivery slot between 11pm to 11.59pm.'},
  {question:' What are the different modes of delivery?',
  answer:' Hand delivery,Courier,Delhi-NCR Courier delivery'}]
  showAnswer: boolean = false;
  isdropdownOpen: boolean = false;
  public data: any;
  faqList1:any=[];
  selectedGroup:any;
  currentIndex:number=0;
  constructor(public dataService: CommanservicesService,) { }

  ngOnInit(): void {
    this.getFAQ();
  }
  openDropdown(){
    this.isdropdownOpen = !this.isdropdownOpen
    
  }

  
  openAnswer(index) {
    if(this.selectedGroup.faqList && this.selectedGroup.faqList){
     
      this.selectedGroup.faqList.forEach(element => {
        if(element.faqId!=this.selectedGroup.faqList[index].faqId){
          element.selected=false;
        }
      });

      
      if( this.selectedGroup.faqList[index].selected){
        this.selectedGroup.faqList[index].selected=false;
      }else{
        this.selectedGroup.faqList[index].selected=true;
      }
      
    }
  }

  getFAQ() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_FAQ"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        let temList=this.data.result;
      
        if(temList && temList.length>0){
          temList.forEach(element => {
            if(element.faqList && element.faqList.length>0){
              this.faqList1.push(element);
            }
          });
        }



        if(this.faqList1 && this.faqList1.length>0){
          this.selectGroup(this.faqList1[0],0);
        }
      }
    }, err => {
    })
  }
  

  selectGroup(selectedGroup:any,index:number){ 
    this.isdropdownOpen = false; 
    this.currentIndex=index;
    if(selectedGroup && selectedGroup.faqList && selectedGroup.faqList.length>0){
      selectedGroup.faqList.forEach(element => {
        element.selected=false;
      });
    }
    this.selectedGroup=selectedGroup;
  }

  changeStatus(index:number,status:boolean){
    if(this.selectedGroup && this.selectedGroup.faqList && this.selectedGroup.faqList.length>0){
      if(this.selectedGroup && this.selectedGroup.faqList && this.selectedGroup.faqList.length>0){
        this.selectedGroup.faqList.forEach(element => {
          element.selected=false;
        });
      }
      this.selectedGroup.faqList[index].selected=status;
    }
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element1 = document.getElementById('desktopnavebar');
    if (window.pageYOffset > 10) {
      // let element = document.getElementById('navbar');
      // element.classList.add('sticky');
      element1.classList.add('desktop-header-sticky');
    } else {
      // let element = document.getElementById('navbar');
      // element.classList.remove('sticky');
      element1.classList.remove('desktop-header-sticky');
    }
  }

}
