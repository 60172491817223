<section id="mobileHeader" class="displayMobile">
    <div class="container-fluid">
        <div class="row mobileFixedHeader primaryGradient navBarColorGradient">
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mobileHeadPadT" data-toggle="modal"
                data-target="#mobileSideBar">
                <i class="fa fa-bars mMenuToggle mT5 " aria-hidden="true"></i>
            </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 padL0 mobileHeadPadT" data-toggle="modal"
                data-target="#mobileSideBar">
                <div class="headerWithBackArrow">
                    <span>FAQ</span>

                </div>
            </div>
        </div>
    </div>
</section>

<section class=" mt-4">
    <div class="container-fluid">
        <div class="displayMobile ">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
                    <div class="faqDropdown mt-4 ">
                        <div class="row card1 m-auto" (click)="openDropdown()" *ngIf="selectedGroup">
                            <div class="col-10 col-sm-10 col-md-10 col-lg-4 col-xl-4 ">
                               <div class="faqheading-width"><b>{{selectedGroup.groupName}}</b></div> </div>
                            <div class="col-2 col-sm-2 col-md-2 col-lg-4 col-xl-4 text-right">
                                <b><i class="fa fa-angle-down"></i></b>
                            </div>
                        </div>
                        <ul *ngIf="isdropdownOpen && faqList1 && faqList1.length>0" class=" faq-left">
                            <li *ngFor="let faq of faqList1 ;let i=index"  [ngClass]="{'active':i==currentIndex}" (click)="selectGroup(faq,i)" >
                                {{faq.groupName}}</li>
                        </ul>
                    </div>

                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 m-auto">
                    <div class="faq-qa">
                        <div class="card rounded-0" *ngIf="selectedGroup && selectedGroup.faqList && selectedGroup.faqList.length>0">
                            <div class="question-answer" *ngFor="let faq of selectedGroup.faqList;let ind=index ">
                                <label for="switch" class="row" >
                                    <h5 class="col-10 col-sm-10 col-md-10 col-lg-11 col-xl-11"  (click)="openAnswer(ind)">
                                        {{faq.question}}</h5>
                                    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 text-right">
                                       
                                        <i *ngIf="!faq.selected" class="fa fa-plus-circle text-color"
                                            aria-hidden="true"  (click)="changeStatus(ind,true)">
                                        </i>
                                        <i *ngIf="faq.selected" class="fa fa-minus-circle text-color"
                                            aria-hidden="true"  (click)="changeStatus(ind,false)">
                                        </i>
                                    </div>

                                </label>
                                <div class="answer" *ngIf="faq.selected">
                                    <p *ngIf="faq.answer" [innerHTML]="faq.answer">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="displayDesktop bottom-padding">
        <div class=" card faq-section">

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
                    <ul class="faq-left" >
                        <li *ngFor="let faq of faqList1 ;let i=index"  [ngClass]="{'active':i==currentIndex}" (click)="selectGroup(faq,i)" >
                            {{faq.groupName}}</li>
                         <!--<li class="active" >Delivery information</li>
                        <li rel="Order-modification">Order modification/Cancellation</li>
                        <li rel="Return-Refund-Policy">Return and Refund Policy</li>
                        <li rel="payment-related">Payment Related</li>
                        <li rel="Vouchers-points">Vouchers/Discounts/Points</li>
                        <li rel="My-Account">My Account &amp; My Cart</li> -->
                    </ul>

                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" *ngIf="selectedGroup">
                    <div class="card-body faq-qa">
                        <h3 class="displayDesktop"><b>{{selectedGroup.groupName}}</b></h3>
                        <div class="card rounded-0" *ngIf="selectedGroup.faqList && selectedGroup.faqList.length>0">
                            <div class="question-answer"  *ngFor="let faq of selectedGroup.faqList;let ind=index">
                                <label for="switch" class="row" >
                                    <h5 class="col-10 col-sm-10 col-md-10 col-lg-11 col-xl-11" (click)="openAnswer(ind)">
                                        {{faq.question}}</h5>
                                    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 text-right">
                                        <!-- <input type="checkbox" id="switch" name="switch{{ind}}" checked="checked"
                                            class="chekbox-hide"> -->
                                        <i *ngIf="!faq.selected" class="fa fa-plus-circle text-color"
                                            aria-hidden="true" (click)="changeStatus(ind,true)">
                                        </i>
                                        <i *ngIf="faq.selected" class="fa fa-minus-circle text-color"
                                            aria-hidden="true" (click)="changeStatus(ind,false)">
                                        </i>
                                    </div>

                                </label>
                                <div class="answer" *ngIf="faq.selected">
                                    <p *ngIf="faq.answer" [innerHTML]="faq.answer">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!selectedGroup.faqList || selectedGroup.faqList.length<=0">
                            <div class="question-answer">
                                Not FAQs Found...!!!
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        </div>
    </div>
</section>