import { Component, OnInit ,HostListener} from '@angular/core';
import {Location} from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { CommanservicesService } from 'src/app/services/commanservices.service';

@Component({
  selector: 'app-store-location',
  templateUrl: './store-location.component.html',
  styleUrls: ['./store-location.component.scss']
})
export class StoreLocationComponent implements OnInit {

  public storesList:any = []
  public tab:any;
  public  data: any;
  public isLoading:boolean  =false;
  public skeletonSection:any = [{},{},{},{},{},{}]



  constructor(
    public dataService:CommanservicesService,
    public _location:Location,
    private title: Title,
    private meta: Meta,
  ) { 

    this.title.setTitle("Store Location");
    this.meta.updateTag({ name: 'description', content: "An online grocer is either a brick-and-mortar supermarket or grocery store that allows online ordering, or a standalone e-commerce service that includes grocery items. ... The online ordering is done through e-commerce websites or mobile apps." });


  }

  ngOnInit(): void {
    this.getStore()
  }

  back(){
    this._location.back();
  }


  getStore() {
    this.isLoading = true;
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_WAREHOUSE"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.storesList = this.data.result;
        this.isLoading = false;
      }else{
        this.isLoading = false;
      }
    },err=>{
      this.isLoading = false;
    })
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element1 = document.getElementById('desktopnavebar');
    if (window.pageYOffset > 10) {
      // let element = document.getElementById('navbar');
      // element.classList.add('sticky');
      element1.classList.add('desktop-header-sticky');
    } else {
      // let element = document.getElementById('navbar');
      // element.classList.remove('sticky');
      element1.classList.remove('desktop-header-sticky');
    }
  }

}
