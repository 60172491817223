export const environment = {
  production: true,
  serverUrl: "https://erpapi.apnagrahak.com/v1/",
  serverUrl2: "https://masterapi.apnagrahak.com/v1/",
  ImageUrl: "https://apnagrahak.s3.ap-south-1.amazonaws.com/",
  tenantName: "",
  PincodeUrl: "https://api.postalpincode.in/",
  ImgBaseUrl: "https://ik.imagekit.io/fjimgkit/",

  // cashfree payment url
  paymentUrl: "https://www.cashfree.com/checkout/post/submit",
  akashmart: {
    apiKey: "AIzaSyCh2PTweCSOGEg_LKReEDMoAeUw0ny_38Y",
    authDomain: "akash-mart-online.firebaseapp.com",
    projectId: "akash-mart-online",
    storageBucket: "akash-mart-online.appspot.com",
    messagingSenderId: "930011110812",
    appId: "1:930011110812:web:12da0278618ad616c6fc59",
    measurementId: "G-HJZX37CMMR"
  },
  reliablemart: {
    apiKey: "AIzaSyDQ6hBxDBwzdyfqYayqSW21vXAD2K_eK0I",
    authDomain: "reliable-mart-86686.firebaseapp.com",
    projectId: "reliable-mart-86686",
    storageBucket: "reliable-mart-86686.appspot.com",
    messagingSenderId: "647014381181",
    appId: "1:647014381181:web:db0e7140745f7afa09e314",
    measurementId: "G-9QHGPGC1SL"
  },
  drmoss: {
    apiKey: "AIzaSyBVZrig-Jdui1NgjYxpVhcKtWBvODxsN6k",
    authDomain: "shakewell-nutrition.firebaseapp.com",
    projectId: "shakewell-nutrition",
    storageBucket: "shakewell-nutrition.appspot.com",
    messagingSenderId: "791769867560",
    appId: "1:791769867560:web:6c98bfa583029b435e1c1d",
    measurementId: "G-J3XNEXCJGT"
  }
};
