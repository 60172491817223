

// GET Action
export class getHomePageLists {
    static readonly type = '[PageList] Get';
}

// With Payload Home Page ID 
export class getPageId {
    static readonly type = '[PageId] Get';
    constructor(public id: number) { }
}

// Home Page Seq NO

export class getPageWithSeqNo {
    static readonly type = '[SeqNo] Post';
    constructor(public payload: {}) { }
}