import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { CategoryData } from "src/app/component/appModels/category.model";
import { AppConstantsService } from "src/app/constants/app-constants.service";
import { CommanservicesService } from "src/app/services/commanservices.service";
import { getCategorys } from "../actions/category.action";
export class CategoryStateModel {
    category:[];
    categoryLoaded: boolean
}

@State<CategoryStateModel>({
    name: 'category',
    defaults: {
        category: [],
        categoryLoaded: false
    }
})

@Injectable()
export class CategorySate {
    data: any;
    dummyObj: any = {};
    public URL: 'GET_CATEGORIES';
    public tab: any;
    item = { "pageNum": 1, "numPerPage": 14, "searchKey": null, "saveFilter": "", "userFilter": [] };
    constructor(private appConstants: AppConstantsService,
        public dataService: CommanservicesService,
        public route: ActivatedRoute,
        public common: CommanservicesService,
        public constant: AppConstantsService) {
    }

    // selecter has logic to select data
    @Selector()
    static getCategoryData(state: CategoryStateModel) {
        return state.category
    }

    // get loaded brand
    @Selector()
    static getCategoryLoaded(state: CategoryStateModel) {
        return state.categoryLoaded;
    }

    @Action(getCategorys)
    getCategorys({ getState, setState }: StateContext<CategoryStateModel>) {
        return this.dataService.postRequest(this.item, this.dataService.appConstant.SERVER_URLS['GET_CATEGORIES']).pipe(tap(result => {
            this.data = result;
            const state = getState();
            setState({
                ...state,
                category: this.data.result,
                categoryLoaded: true
            });
        }));
    }
}