<!-- !--------HEADER FOR MOBILE VIEW-------->
<section id="mobileHeader" class="mobileView" >
    <div class="container-fluid">
        <div class="row mobileFixedHeader primaryGradient navBarColorGradient" >
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mobileHeadPadT" (click)="back()">
                <div class="headerWithBackArrow">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    <span>{{item.displayName}}</span>
                </div>
            </div>
        </div>
    </div>
</section>
<a id="backToTopbuttonDetails"></a>
<section style="background-color:white !important">
    <div class="detail-page desktopView">
        <div class="container-fluid page-margin mt-2">
            <span class="breadCum desktopView cursorPointer">
                <b routerLink=""><i class="fa fa-home secondThemeColor" aria-hidden="true"></i> Home</b>
                <span *ngIf="item.itemName">&nbsp;<b class="fa fa-angle-right secondThemeColor" aria-hidden="true"></b>{{item.itemName}}
                </span>

            </span>
            <section class="content mt-3 container-fluid desktopView">
                <article class="demo-area" (mouseenter)="a()">
                    <div>
                        <div class="row  mb-4 three-cards">
                            <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-1 col-xxl-1 mt-3">
                                <div *ngFor="let img of item.itemImageBoList;let i=index" class="badge rounded-4"
                                    [ngClass]="{'rounded-3': i == currentimageIndex}">
                                    <div class="image-height">
                                        <div class="inner-span">
                                            <img src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}"
                                                loading="lazy" onError="this.src='assets/img/errorLogo.png'"
                                                (click)="openImg(i)" class="review-image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-6 col-xxl-6 mt-2">
                                <div class="card card-h">
                                    <div class="card-image text-center zoom-image">
                                        <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize"
                                            class="main-image-height"
                                            [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                            src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                            [attr.data-zoom]="dataService.appConstant.IMAGE_URL + item.imageFilePath + '?w=1000&ch=DPR&dpr=2'"
                                            loading="lazy" alt="Image" onError="this.src='assets/img/errorLogo.png'">

                                        <img *ngIf="item.isManageImagecardSize && item.imageCardSize"
                                            class="main-image-height"
                                            [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                            src="{{dataService.appConstant.IMAGE_BASE_URL}}{{item.imageCardSize}}/{{item.imageFilePath}}"
                                            [attr.data-zoom]="dataService.appConstant.IMAGE_URL + item.imageFilePath + '?w=1000&ch=DPR&dpr=2'"
                                            loading="lazy" alt="Image" onError="this.src='assets/img/errorLogo.png'">


                                    </div>
                                    <div class="out-of-stock-message"
                                        *ngIf="item.isInventory && !item.isStockAvailable">
                                        Out of Stock
                                    </div>

                                </div>
                            </div>

                            <div class="detail  col-12 col-sm-12 col-md-12-col-lg-5 col-xl-5 col-xxl-5 mt-2">
                                <div class="product-details">
                                    <div class="product-details-offer" *ngIf="item.discountName && !item.isMrpDiscount">
                                        <p>{{item.discountName | titlecase}}</p>
                                    </div>
                                    <div class="product-details-offer"
                                        *ngIf="!item.discountName && item.isMrpDiscount && item.discAmt && item.discAmt>0">
                                        <p>{{item.discAmt}} /-OFF</p>
                                    </div>
                                    <div class="row product-details-col">
                                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 ">
                                            <h5>
                                                <b class="brandClrName text-capitalize">{{item.brandName}}</b>
                                            </h5>
                                            <h4>
                                                <b class="itemName text-capitalize"
                                                    *ngIf="item.itemName">{{item.itemName}}</b>

                                            </h4>
                                            <div class="style-2">
                                                <ins>
                                                    <span *ngIf="!item.isBuyXGetYDiscount"
                                                        class="amount comboAmountF">{{currencySymbol}}{{item.finalPrice}}</span>
                                                    <span *ngIf="item.isBuyXGetYDiscount"
                                                        class="amount comboAmountF">{{currencySymbol}}{{item.withoutDiscAmount}}</span>
                                                    <span class="sale-price pl-1"
                                                        *ngIf="item.discountName && !item.isBuyXGetYDiscount">
                                                        <span>{{currencySymbol}}</span>
                                                        <span>{{item.withoutDiscAmount}}</span>
                                                    </span>

                                                </ins>
                                                <small class="text-color mx-3"
                                                    *ngIf="item.isMrpDiscount && item.discAmt && item.discAmt>0"><del>
                                                        M.R.P. <span>{{currencySymbol}}</span>
                                                        <span>{{item.mrp}}</span>
                                                    </del></small>
                                                <div *ngIf="reviewRatingobject && reviewRatingobject.avgRating > 0"
                                                    class="d-flex">
                                                    <div class="rating">
                                                        <i class="fa fa-star-o star-icon" aria-hidden="true"><span
                                                                class="avg-rat">{{reviewRatingobject.avgRating}}</span></i>
                                                    </div>
                                                    <div>({{reviewRatingobject.ratingCount}})</div>
                                                </div>

                                            </div>
                                            <div class="custom-alert">

                                                <div class="error">
                                                    <div class="error__icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                            viewBox="0 0 24 24" height="24" fill="none">
                                                            <path fill="#393a37"
                                                                d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                    <div class="error__title">Item out of stock</div>
                                                    <!-- <div class="error__close"><svg xmlns="http://www.w3.org/2000/svg"
                                                            width="20" viewBox="0 0 20 20" height="20">
                                                            <path fill="#393a37"
                                                                d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z">
                                                            </path>
                                                        </svg></div> -->
                                                </div>
                                            </div>
                                            <div class="other-cards"
                                                *ngIf="item && item && item.parentItemAttributeBOList && item.parentItemAttributeBOList?.length > 0">
                                                <ng-container
                                                    *ngFor="let attribute of parentItemAttributeBOList;let i=index">
                                                    <div class="col-12 pl-0 d-flex justify-content-between">
                                                        <div>{{attribute.attributeName | titlecase}}:
                                                            <b>{{selectedValues[attribute.attributeName |
                                                                titlecase]}}</b>
                                                        </div>
                                                    </div>

                                                    <div class="row pt-1 pl-2 cursorPointer">
                                                        <div class="col-3 pl-1 pr-1"
                                                            *ngFor="let attributeValue of attribute.attributeValueList;let j=index"
                                                            id="sizeSpace">
                                                            <div id="{{attributeValue}}"
                                                                class="grid-item font-weight-bold "
                                                                [class.selected]="j === selectedSizeIndices[attribute.attributeName]"
                                                                [ngClass]="{'disabledVarient': isSelectedVarient && j === selectedSizeIndices[attribute.attributeName]}"
                                                                [options]="myOptions"   
                                                                (click)="selectNewVariant($event,j,attribute.attributeName,attributeValue)"
                                                                data-toggle="tooltip"
                                                            
                                                                title="click to select {{attributeValue}}">
                                                                <!-- tooltip="{{getTooltipContent(attributeValue)}}" > -->
                                                                {{attributeValue | titlecase}}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-container>

                                            </div>

                                            <!-- </div> -->
                                            <div>
                                                <div *ngIf="!item.isAdded && item.isStockAvailable"
                                                    (click)="addToCart(item)" class="addToCard but-color font-color">
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    &nbsp;&nbsp; Add to cart
                                                    <!-- <div class="addToCartLoader" *ngIf="item.isLoading">
                                                <img src="assets/img/spinner.gif">
                                            </div> -->
                                                </div>
                                                <div *ngIf="item.isInventory && !item.isStockAvailable"
                                                    class="addToCard but-color font-color">
                                                    &nbsp;&nbsp; Out Of Stock
                                                </div>
                                                <div class="cartCount" *ngIf="item.isAdded && item.isStockAvailable">
                                                    <div class="flex">
                                                        <div class="but-color font-color incrementDecrement"
                                                            (click)="updateCartQuantity('decrease',item)">
                                                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="itemCount"><b>{{item.quantity}}</b>
                                                        </div>
                                                        <div class="but-color font-color incrementDecrement"
                                                            (click)="updateCartQuantity('increase',item)">
                                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="quick-row">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                                        <h6><b>Quick Overview</b></h6>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                        <div class="floatR quick-col">
                                                            <img src="assets/img/whatsapp.png" alt="Icon"
                                                                (click)="share('whatsApp')">
                                                            <img src="assets/img/fb.png" alt="Icon"
                                                                (click)="share('fb')">
                                                            <!-- <img src="assets/img/insta.png" alt="Icon" (click)="share('instagram')"> -->
                                                            <img src="assets/img/twitter.png" alt="Icon"
                                                                (click)="share('twitter')">
                                                            <img src="assets/img/Mail.png" alt="Icon"
                                                                (click)="share('Mail')">
                                                            <img src="assets/img/share.png" alt="Icon"
                                                                (click)="share('share')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="item.longDesc">
                            <h4 class="product-desc-title">
                                Product Description
                            </h4>
                            <p *ngIf="item.longDesc" [innerHTML]="item.longDesc"></p>
                        </div>
                    </div>
                </article>
            </section>

          


            <section id="services" class="services content container-fluid desktopView">
                <div  data-aos="fade-up">
            
                    <h4 class="product-desc-title">
                        Customer Reviews
                    </h4>
                    <div class="row" >
                    <div class="col-12 col-sm-12 col-md-6 col-lg-10 col-xl-10 col-xxl-10">
                    <div class="starRating" *ngIf="!reviewresponse || reviewresponse.length <= 0">
                        <i class="fa fa-star-o" aria-hidden="true" style="font-size: medium;"></i>
                        <i class="fa fa-star-o" aria-hidden="true" style="font-size: medium;"></i>
                        <i class="fa fa-star-o" aria-hidden="true" style="font-size: medium;"></i>
                        <i class="fa fa-star-o" aria-hidden="true" style="font-size: medium;"></i>
                        <i class="fa fa-star-o" aria-hidden="true" style="font-size: medium;"></i>
                    
                        <span class="review-block">  Be the first to write a review</span>
                    </div>
                    <div *ngIf="reviewresponse && reviewresponse.length > 0">
                        <div class="star-rating">
                            <ng-container
                                *ngFor="let i of [1, 2, 3, 4, 5]">
                                <span class="star"
                                    [class.filled]="i <= reviewRatingobject.avgRating">&#9733;</span>
                            </ng-container>
                            <span class="review-block" style="padding: 8px;">{{reviewRatingobject.avgRating}} based on {{reviewRatingobject.ratingCount}} reviews</span>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 col-xxl-2 text-right">
                        <button class="btn reviewbuttoncolor" *ngIf="!item.isReviewed && item.isPurchaseItem && !reviewForm" (click)="writeReview()">Write a Review</button>
                        <button class="btn reviewbuttoncolor" *ngIf="reviewForm" (click)="cancleReviewForm()">Cancel Review</button>
                    </div>
                    
                    </div>
                    <div>
                        <div class="row boder-card mt-2 mb-2" *ngIf="reviewForm">
                            <form name="WriteReviewform" #WriteReviewform="ngForm" class="mt-2 col-12"
                                        (ngSubmit)="WriteReviewform.valid && saveReviewforms(WriteReviewform)">
                                                                <div >
                                                                    <div class="starRating">
                                                                        <ul class="rate-area">
                                                                            <input type="radio" id="5-star" name="crating"
                                                                                [(ngModel)]="reviewFormData.rating"
                                                                                value="5">
                                                                            <label for="5-star" title="Amazing">5
                                                                                stars</label>
                                                                            <input type="radio" id="4-star" name="crating"
                                                                                [(ngModel)]="reviewFormData.rating"
                                                                                value="4">
                                                                            <label for="4-star" title="Good">4
                                                                                stars</label>
                                                                            <input type="radio" id="3-star" name="crating"
                                                                                [(ngModel)]="reviewFormData.rating"
                                                                                value="3">
                                                                            <label for="3-star" title="Average">3
                                                                                stars</label>
                                                                            <input type="radio" id="2-star" name="crating"
                                                                                [(ngModel)]="reviewFormData.rating"
                                                                                value="2">
                                                                            <label for="2-star" title="Not Good">2
                                                                                stars</label>
                                                                            <input type="radio" id="1-star"
                                                                                [(ngModel)]="reviewFormData.rating"
                                                                                name="crating" value="1"
                                                                                aria-required="true">
                                                                            <label for="1-star" title="Bad">1
                                                                                star</label>
                                                                        </ul>
                                                                    </div>
                                                                    <div>
                                                                    <input type="text" placeholder="write title name"
                                                                        class="form-control bg-white boder-card mt-2"
                                                                        #reviewTitle="ngModel"
                                                                        [(ngModel)]="reviewFormData.reviewTitle"
                                                                        name="reviewTitle" required>
                                                                        
                                                                    <div *ngIf="reviewTitle.valid || reviewTitle.touched || WriteReviewform.submitted"
                                                                        class="text-danger">
                                                                        <div *ngIf="reviewTitle.errors && reviewTitle.errors.required"
                                                                            class="text-danger">
                                                                            Title must be required
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <textarea type="text" placeholder="write your review"
                                                                        class="form-control bg-white boder-card mt-1"
                                                                        name="description" #reviewDescription="ngModel"
                                                                        [(ngModel)]="reviewFormData.reviewDescription"
                                                                        required>
                                                                <div *ngIf="reviewDescription.valid || reviewDescription.touched || WriteReviewform.submitted"
                                                                class="text-danger">
                                                                <div *ngIf="reviewDescription.errors && reviewDescription.errors.required"
                                                                    class="text-danger">
                                                                    Description must be required
                                                                </div>
                                                            </div>
                                                        </textarea>
                                                                    <div class="row mt-2">
                                                                        <div
                                                                            class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 mb-1">
                                                                            <button type="submit"
                                                                                class="btn btn-primary   whiteClr  reviewbuttoncolor  submitBtn"
                                                                                [disabled]="isButtonDisabled"
                                                                                [ngClass]="{'button--loading':isButtonDisabled}" style="background-color: green !important;"><span
                                                                                    class="button__text">Submit Review</span></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>

                        </div>
                        <div *ngIf="reviewresponse && reviewresponse.length>0">
                            <div class="row" style="line-height: 1.2;">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
                                    <div class="text-color f-13">Sort By</div>
                                    <select class="box border-0 fw-bold f-14" name="selectedValue"
                                        [(ngModel)]="selectedValue" (change)="getReviewList()">
                                        <option value="Popularity">Popularity</option>
                                        <option value="Latest">Latest</option>
                                    </select>
                                </div>
                            </div>

                        <div class="row mt-3 reviewCard"   *ngIf="reviewresponse && reviewresponse.length>0">
                            <!-- <div > -->
                                <div class="col-12 col-sm-12 col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" *ngFor="let reviewlist of reviewresponse | paginate: { id: 'brandpag', itemsPerPage: pagination.numPerPage,
                                currentPage: pagination.pageNum,totalItems:ratingCount} ;let i=index">
                                    <div class="icon-box starRating reviewCard">
                                        <div class="icon">
                                            <i class="bx bxl-dribbble"></i>
                                        </div>
                                        <small class="text-color f-13">{{reviewlist.ratingDate|date}}</small>
                                        <h4>
                                            <a>{{reviewlist.userName}}</a>
                                        </h4>
                                        <div class="star-rating">
                                            <ng-container
                                                *ngFor="let i of [1, 2, 3, 4, 5]">
                                                <span class="star"
                                                    [class.filled]="i <= reviewlist.rating">&#9733;</span>
                                            </ng-container>
                                        </div>

                                        <div class="review-cardwidth">{{reviewlist.reviewDescription}}</div>
                                        
                                       
                                    </div>
                                    
                                </div>
                            <!-- </div> -->
                        </div>

                        <div class="row mt-1 container-fluid" *ngIf="reviewresponse.length > 0"
                            style="color: #96989c;">
                            <div class="text-left d-flex">
                                <div *ngIf="reviewRatingCnt > 1"
                                    (click)="changeReviewCount('decrease')">
                                    <i class="fa-solid fa-arrow-left" style="color: #858a93;"></i>
                                    &nbsp;
                                </div>
                                {{reviewRatingCnt}} of {{pageNo}}
                                <div *ngIf="reviewRatingCnt != pageNo "
                                    (click)="changeReviewCount('increase')">
                                    &nbsp;&nbsp;<i class="fa-solid fa-arrow-right"
                                        style="color: #858a93;"></i>
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="mt-3 animated fadeDown " id="apna-grocery"
                *ngIf="recentlyViewList && recentlyViewList.length >4">
                <div>
                    <div class="row">
                        <div class="col-xl-12">
                            <ng-container>
                                <div >
                                    <h3 class="recent-product-title container-fluid">Recently Viewed Product
                                    </h3>
                                    <div>
                                        <owl-carousel-o [options]="apnagrocerySlider">
                                            <ng-container *ngFor="let item of recentlyViewList;let itemIndex=index">
                                                <ng-template carouselSlide>
                                                    <div class="card card-product-offer single-product">
                                                    <div class="card-img-top card-image1"  (click)="itemDetail(item)">
                                                        <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize"
                                                        [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                                        class="productListImage1 zoom"
                                                        src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                        loading="lazy"
                                                        />
                                                        <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize"
                                                        [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                                        class="productListImage1 zoom"
                                                        src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                        loading="lazy"
                                                        />
                                                       
                                                        <div class="out-of-stock-message" *ngIf="item.isInventory && !item.isStockAvailable">
                                                            Out of Stock
                                                        </div>
                                                    <div class="product-offer"
                                                    *ngIf="item.discPerc && item.discountName && item.isMrpDiscount == false">
                                                    <p>{{item.discPerc}}% OFF</p>
                                                </div>
                                                <div class="product-offer"
                                                    *ngIf="item.isBuyXGetYDiscount && item.discountName && item.isMrpDiscount == false">
                                                    <p>{{item.discountName}}</p>
                                                </div>
                                                <div class="product-offer"
                                                    *ngIf="!item.discountName && item.isMrpDiscount == true">
                                                    <p>{{currencySymbol}} {{item.discAmt}} OFF</p>
                                                </div>
                                                    </div>
                                                    <div class="card-body card-item-details">
                                                    <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top" show-delay="100">{{item.brandName}}</div>
                                                    <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                                    placement="top" show-delay="500" (click)="itemDetail(item)">{{item.itemName}}</h5>
                                                    <div class="style-1">
                                                        <del>
                                                            <span
                                                                *ngIf="item.discountName && item.isMrpDiscount == false && !item.isBuyXGetYDiscount"
                                                                class="discountprice">₹
                                                                {{item.withoutDiscAmount}}.00</span>
                                                            <span
                                                                *ngIf="!item.discountName && item.isMrpDiscount == true && !item.isBuyXGetYDiscount"
                                                                class="discountprice">₹
                                                                {{item.mrp}}.00</span>
                                                        </del>
                                                        <ins>
                                                            <span *ngIf="item.isBuyXGetYDiscount"
                                                                class="money">{{currencySymbol}}
                                                                {{item.withoutDiscAmount}}.00</span>
                                                            <span class="money" *ngIf="!item.isBuyXGetYDiscount">
                                                                {{currencySymbol}}
                                                                {{item.finalPrice}}.00</span>
                                                        </ins>
                                                    </div>
                                                        <div>
                                                        <div *ngIf="!item.isAdded && item.isStockAvailable"
                                                        (click)="addToCart(item)"
                                                            class="addToCard1 but-color font-color">
                                                            <i class="fa fa-shopping-cart"
                                                                    aria-hidden="true"></i>
                                                                &nbsp;&nbsp; Add to cart
                                                            <div class="addToCartLoader" *ngIf="item.isLoading">
                                                                <img src="assets/img/spinner.gif">
                                                            </div>
                                                        </div>

                                                        <div *ngIf="item.isInventory && !item.isStockAvailable" class="addToCard1 but-color font-color">
                                                            <i class="fa fa-shopping-cart"
                                                                    aria-hidden="true"></i>
                                                                &nbsp;&nbsp; Out Of Stock
                                                        </div>
                                                        <div class="cartCount" style="width: 100% !important;" *ngIf="item.isAdded && item.isStockAvailable">
                                                            <div class="flex alignCenter contentJustify">
                                                                <div class="but-color font-color incrementDecrement"
                                                                (click)="updateCartQuantityRecentlyViwe('decrease',item,itemIndex)">
                                                                    <i class="fa fa-angle-left"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                                <div class="itemCount"><b>{{item.quantity}}</b>
                                                                </div>
                                                                <div class="but-color font-color incrementDecrement"
                                                                (click)="updateCartQuantityRecentlyViwe('increase',item,itemIndex)">
                                                                    <i class="fa fa-angle-right"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </owl-carousel-o>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
       
        </div>
    </div>

    <div class="mobileView mt-4">
        <!-- row below class -->
        <div class=" mb-3 three-cards">
            <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-5 col-xxl-5 mt-3">
                <div class="mobileViewCard">
                    <div class="row px-2">
                        <div class="col-12 pl-0 pr-0 ">
                            <h5 class="mb-0">
                                <span id="brandNm">Brand:&nbsp;</span><b class="brandClrName1 text-capitalize">{{item.brandName}}</b>
                            </h5>
                            <h4>
                                <b class="mainitemName" *ngIf="item.itemName text-capitalize">{{item.itemName}}</b>
                            </h4>
                        </div>
                        <div *ngIf="item.itemImageBoList" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <section id="productDetailIMage-slider">
                                
                                <owl-carousel-o  [options]="detailslider">
                                    <ng-container *ngFor="let img of item.itemImageBoList;let i=index">
                                        <ng-template carouselSlide>
                                            <div class="SliderImage card-img-top card-image-mobile">

                                                <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize" 
                                                [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                                class="productListImage1" src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}"
                                                    loading="lazy" onError="this.src='assets/img/errorLogo.png'"
                                                    onclick="openModal();currentSlide(0)"
                                                    class="main-image-height">
                                                 <img *ngIf="item.isManageImagecardSize && item.imageCardSize" 
                                                 [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                                 class="productListImage1" 
                                                    src="{{dataService.appConstant.IMAGE_BASE_URL}}{{item.imageCardSize}}/{{img.filePath}}"
                                                    loading="lazy" onError="this.src='assets/img/errorLogo.png'"
                                                    onclick="openModal();currentSlide(0)"
                                                    class="main-image-height">
                                                   
                                                    <!-- <img src="assets/img/share.png" id="shareitem" alt="Icon" (click)="share('share')"> -->

                                                    <div class="out-of-stock-message" *ngIf="item.isInventory && !item.isStockAvailable">
                                                        Out of Stock
                                                </div>
    
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>

                             

                            </section>
                        </div>
                        <div *ngIf="!item.itemImageBoList" class="col-12 col-sm-12 col-md-12-col-lg-12 col-xl-12 col-xxl-12 mt-2">
                            <div class="card card-h SliderImage card-img-top card-image-mobile">
                                    <div class="card-image text-center zoom-image"  >
                                        <!-- src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" -->
                                        <img 
                                            src="../../../assets/img/errorLogo.png"     
                                            loading="lazy" alt="Image" class="main-image-height"
                                            >
                                    </div>
                                    <div class="out-of-stock-message" *ngIf="item.isInventory && !item.isStockAvailable">
                                        Out of Stock
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="detail  col-12 col-sm-12 col-md-12-col-lg-5 col-xl-5 col-xxl-5 mt-2 pl-0 pr-0">
                        <div class="product-details">
                            
                            <div class="row product-details-col">
                                <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 ">
                                    <!-- <h5>
                                        <b class="brandClrName text-capitalize">{{item.brandName}}</b>
                                    </h5>
                                    <h4>
                                        <b class="itemName" *ngIf="item.itemName text-capitalize">{{item.itemName}}</b>
                                        
                                    </h4> -->
                                    
                               
                                    <!-- <div *ngIf="item && item.itemVarientBoList && item.itemVarientBoList?.length > 0" class="badge varient">
                                        <span class="image-height m-auto varient">
                                            <div class="">
                                                <strong class="f-13">{{ item.varientStr}}</strong>
                                            </div>
                                        </span>
                                    </div> -->

                                    <!-- *ngIf="item.stockInHand!=0 && item && item.otherChildItemsBOList && item.otherChildItemsBOList?.length > 0" -->
                                    <div class="custom-alert">
                                        <div class="error">
                                            <div class="error__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                    viewBox="0 0 24 24" height="24" fill="none">
                                                    <path fill="#393a37"
                                                        d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div class="error__title">Item out of stock</div>
                                        </div>
                                    </div>
                                    <div class="other-cards"  *ngIf="item && item && item.parentItemAttributeBOList && item.parentItemAttributeBOList?.length > 0">
                                        <!-- <ng-container *ngFor="let variant of item.otherChildItemsBOList; let ind = index">
                                            <div class="badge varient varient-2 varient">
                                                <span class="image-height m-auto">
                                                    <div class="" (click)="selectVariant(variant)">
                                                        <strong class="f-13">{{ variant.varientStr }}</strong>
                                                    </div>
                                                </span>
                                            </div>
                                        </ng-container> -->
                                         
                                        <!-- <div class="col-12 pl-0 pb-2 d-flex justify-content-between">
                                            <div>Colour: <b>{{selectedColor}}</b></div>
                                            <p *ngIf="isAttributionSectionOpen2" class="cursorPointer fa fa-chevron-circle-up showHide" (click)="toggleSection('color')"></p> 
                                            <p *ngIf="!isAttributionSectionOpen2" class="cursorPointer fa fa-chevron-circle-down showHide" (click)="toggleSection('color')"></p>
                                          </div>
                                            <div  *ngIf="isAttributionSectionOpen2" class="pl-0 pt-0 grid-container cursorPointer">
                                              <div *ngFor="let color of colors;let i=index" id="sizeSpace">
                                              
                                                <div id="{{color}}"  class="grid-item font-weight-bold"  
                                                (click)="selectNewVariant('color',item.otherChildItemsBOList,$event,i,color)"
                                                  [class.selected]="i === selectedColorIndex">
                                                  {{color}}
                                                </div>
                                              </div>
                                            </div> -->

                                            
                                        <!-- <div class="col-12 d-flex pb-2 justify-content-between pl-0">
                                            <div>Size: <b>{{selectedSize}}</b></div>  
                                            <p *ngIf="isAttributionSectionOpen1" class="cursorPointer fa fa-chevron-circle-up showHide" (click)="toggleSection('size')"></p> 
                                            <p *ngIf="!isAttributionSectionOpen1" class="cursorPointer fa fa-chevron-circle-down showHide" (click)="toggleSection('size')"></p>
                                         </div>
                                            
                                             <div *ngIf="isAttributionSectionOpen1" class="pl-0 pt-0 grid-container cursorPointer">
                                               <div *ngFor="let size of sizes;let i=index" id="sizeSpace">
                                                 <div id="{{size}}" class="grid-item font-weight-bold" 
                                                 [class.selected]="i === selectedSizeIndex"  (click)="selectNewVariant('size',item.otherChildItemsBOList,$event,i,size)"
                                                  >
                                                   {{size}}
                                                 </div>
                                               </div>
                                             </div>
                                              -->
                                           
                                           <!-- <div class="col-12 d-flex pb-2 justify-content-between pl-0">
                                             <div>Material: <b>{{selectedMaterial}}</b></div>
                                             <p *ngIf="isAttributionSectionOpen3" class="cursorPointer fa fa-chevron-circle-up showHide" (click)="toggleSection('material')"></p> 
                                             <p *ngIf="!isAttributionSectionOpen3" class="cursorPointer fa fa-chevron-circle-down showHide" (click)="toggleSection('material')"></p>
                                           </div>
                                             
                                             <div *ngIf="isAttributionSectionOpen3" class="pl-0 pt-0 grid-container cursorPointer">
                                               <div *ngFor="let material of materials;let i = index" id="sizeSpace">
                                                 <div id="{{material}}"  class="grid-item font-weight-bold"  
                                                 (click)="selectNewVariant('material',item.otherChildItemsBOList,$event,i,material)"  [class.selected]="i === selectedMaterialIndex"
                                                    >
                                                   {{material}}
                                                 </div>
                                               </div>
                                             </div> -->

                                             <ng-container *ngFor="let attribute of parentItemAttributeBOList;let i=index">
                                                <div class="col-12 pl-0 d-flex justify-content-between">
                                                <div>{{attribute.attributeName}}: <b>{{selectedValues[attribute.attributeName]}}</b></div>  
                                                <!-- <p *ngIf="isAttributionSectionOpen1[i]" class="cursorPointer fa fa-chevron-circle-up" (click)="toggleSection(i)"></p> 
                                                <p *ngIf="!isAttributionSectionOpen1[i]" class="cursorPointer fa fa-chevron-circle-down" (click)="toggleSection(i)"></p> -->
                                                </div>
                                                    <!-- (mouseover)="showSizeTooltip(size)"
                                                        (mouseout)="hideSizeTooltip()" *ngIf="isAttributionSectionOpen1[i]"-->
                                                    <div  class="row pt-1 pl-2 cursorPointer">
                                                    <div class="col-4 pl-1 pr-1" *ngFor="let attributeValue of attribute.attributeValueList;let j=index" id="sizeSpace">
                                                        <div id="{{attributeValue}}" class="grid-item font-weight-bold" 
                                                         [class.selected]="j === selectedSizeIndices[attribute.attributeName]"
                                                        [options]="myOptions" (click)="selectNewVariant(item.otherChildItemsBOList,$event,j,attribute.attributeName,attributeValue)"
                                                        >
                                                        {{attributeValue}}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </ng-container>
                                    </div>
                                   
                              

                                <!-- Price section -->

                                <div class="style-2">
                                    <ins>
                                        <div class="product-details-offer" *ngIf="item.discountName && !item.isMrpDiscount">
                                <p>{{item.discountName}}</p>
                            </div>
                            <div class="product-details-offer" *ngIf="!item.discountName && item.isMrpDiscount && item.discAmt && item.discAmt>0">
                                <p>{{item.discAmt}} /-OFF</p>
                            </div>
                                        <span *ngIf="!item.isBuyXGetYDiscount"
                                            class="amount comboAmountF">{{currencySymbol}}{{item.finalPrice}}</span>
                                        <span *ngIf="item.isBuyXGetYDiscount"
                                            class="amount comboAmountF">{{currencySymbol}}{{item.withoutDiscAmount}}</span>
                                        <span class="sale-price pl-1"
                                            *ngIf="item.discountName && !item.isBuyXGetYDiscount">
                                            <span>{{currencySymbol}}</span>
                                            <span>{{item.withoutDiscAmount}}</span>
                                        </span>
                                        
                                    </ins>
                                    <small class="text-color mx-3" *ngIf="item.isMrpDiscount && item.discAmt && item.discAmt>0">
                                        <del id="discount">
                                        M.R.P. <span>{{currencySymbol}}</span>
                                        <span>{{item.mrp}}</span>
                                    </del></small>
                                <div *ngIf="reviewRatingobject && reviewRatingobject.avgRating > 0" class="d-flex">
                                    <div class="rating">
                                        <i class="fa fa-star-o star-icon" aria-hidden="true"><span class="avg-rat">{{reviewRatingobject.avgRating}}</span></i>
                                    </div>
                                    <div>({{reviewRatingobject.ratingCount}})</div>
                                </div>

                            </div>

                                <div class="mt-1">
                                <div *ngIf="!item.isAdded  && item.isStockAvailable"
                                (click)="addToCart(item)"
                                    class="addToCardmobile but-color font-color">
                                    <i class="fa fa-shopping-cart"
                                            aria-hidden="true"></i>
                                        &nbsp;&nbsp; Add to Cart
                                    <div class="addToCartLoader" *ngIf="item.isLoading">
                                        <img src="assets/img/spinner.gif">
                                    </div>
                                </div>
                                <div *ngIf="item.isInventory && !item.isStockAvailable"
                                    class="addToCardmobile but-color font-color">
                                        &nbsp;&nbsp; Out Of Stock
                                </div>
                                <div class="cartCount cartCount-mobileview" *ngIf="item.isAdded && item.isStockAvailable">
                                    <div class="flex" style="justify-content: center !important;">
                                        <div class="but-color font-color incrementDecrement"
                                        (click)="updateCartQuantity('decrease',item)">
                                            <i class="fa fa-angle-left"
                                                aria-hidden="true"></i>
                                        </div>
                                        <div class="itemCount"><b>{{item.quantity}}</b>
                                        </div>
                                        <div class="but-color font-color incrementDecrement"
                                        (click)="updateCartQuantity('increase',item)">
                                            <i class="fa fa-angle-right"
                                                aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="quick-row">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                            <h6 class="text-center"><b>Quick Overview</b></h6>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mt-2">
                                            <div class="col-12 d-flex justify-content-center quick-col">
                                                <img src="assets/img/whatsapp.png" alt="Icon" (click)="share('whatsApp')">
                                                <img src="assets/img/fb.png" alt="Icon" (click)="share('fb')">
                                                <!-- <img src="assets/img/insta.png" alt="Icon" (click)="share('instagram')"> -->
                                                <img src="assets/img/twitter.png" alt="Icon" (click)="share('twitter')">
                                                <img src="assets/img/Mail.png" alt="Icon" (click)="share('Mail')">
                                                <img src="assets/img/share.png" alt="Icon" (click)="share('share')">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div  *ngIf="item.longDesc">
                                    <h4 class="product-desc-title">
                                        About this item
                                    </h4>
                                    <!-- Product Description -->
                                    <p *ngIf="item.longDesc" [innerHTML]="item.longDesc"></p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                    <div class="card-one mt-2 " >
                        <div class="mobileViewCard1 card-height-review ca">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 col-xxl-8">
                                    <div class="d-flex">
                                        <strong class="f-18">Reviews</strong>
                                        <div class="text-right"
                                            *ngIf="reviewRatingobject && reviewRatingobject.avgRating > 0">
                                            <!-- <span class="reviewRating">{{reviewRatingobject.avgRating}} <i
                                                class="fa fa-star changeStartClr" aria-hidden="true"></i></span> -->
                                            <span class="mx-1 f-13 text-secondary">
                                                ({{ratingCount}}Review )
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class=" boder-card mt-2 mb-2" *ngIf="!item.isReviewed && item.isPurchaseItem">
                                <div class="p-3">

                                    <span *ngIf="!item.isReviewed && item.isPurchaseItem && !reviewForm"
                                        class="d-flex"><i class="fa fa-edit"></i>
                                        <h6 class="fw-bold cursorPointer mx-1" (click)="writeReview()"> Write an
                                            Review </h6>

                                    </span>
                                    <div>
                                        <form name="WriteReviewform" #WriteReviewform="ngForm"
                                            (ngSubmit)="WriteReviewform.valid && saveReviewforms(WriteReviewform)">
                                            <div *ngIf="reviewForm">
                                                <div class="">
                                                    <ul class="rate-area">
                                                        <input type="radio" id="5-star" name="crating"
                                                            [(ngModel)]="reviewFormData.rating" value="5">
                                                        <label for="5-star" title="Amazing">5
                                                            stars</label>
                                                        <input type="radio" id="4-star" name="crating"
                                                            [(ngModel)]="reviewFormData.rating" value="4">
                                                        <label for="4-star" title="Good">4
                                                            stars</label>
                                                        <input type="radio" id="3-star" name="crating"
                                                            [(ngModel)]="reviewFormData.rating" value="3">
                                                        <label for="3-star" title="Average">3
                                                            stars</label>
                                                        <input type="radio" id="2-star" name="crating"
                                                            [(ngModel)]="reviewFormData.rating" value="2">
                                                        <label for="2-star" title="Not Good">2
                                                            stars</label>
                                                        <input type="radio" id="1-star"
                                                            [(ngModel)]="reviewFormData.rating" name="crating"
                                                            value="1" aria-required="true">
                                                        <label for="1-star" title="Bad">1
                                                            star</label>
                                                    </ul>
                                                </div>
                                                <input type="text" placeholder="write title name"
                                                    class="form-control bg-white boder-card mt-2"
                                                    #reviewTitle="ngModel"
                                                    [(ngModel)]="reviewFormData.reviewTitle" name="reviewTitle"
                                                    required>
                                                <div *ngIf="reviewTitle.valid || reviewTitle.touched || WriteReviewform.submitted"
                                                    class="text-danger">
                                                    <div *ngIf="reviewTitle.errors && reviewTitle.errors.required"
                                                        class="text-danger">
                                                        Title must be required
                                                    </div>
                                                </div>
                                                <textarea type="text" placeholder="write your review"
                                                    class="form-control bg-white boder-card mt-2"
                                                    name="description" #reviewDescription="ngModel"
                                                    [(ngModel)]="reviewFormData.reviewDescription" required>
                                    <div *ngIf="reviewDescription.valid || reviewDescription.touched || WriteReviewform.submitted"
                                    class="text-danger">
                                    <div *ngIf="reviewDescription.errors && reviewDescription.errors.required"
                                        class="text-danger">
                                        Description must be required
                                    </div>
                                </div></textarea>
                                                <div class="row mt-2">
                                                    <div
                                                        class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2">
                                                        <button type="submit"
                                                            class="btn btn-secondary p-1 whiteClr  submitBtn"
                                                            (click)="cancleReviewForm()">Cancel</button>
                                                    </div>

                                                    <div
                                                        class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2">
                                                        <button type="submit"
                                                            class="btn btn-primary secGradient secGradient whiteClr  p-1 submitBtn"
                                                            [disabled]="isButtonDisabled"
                                                            [ngClass]="{'button--loading':isButtonDisabled}"><span
                                                                class="button__text">POST</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div class="text-center mt-2"
                                *ngIf="!reviewRatingobject || reviewRatingobject.avgRating <= 0">No reviews
                                available...</div>
                            <div *ngIf="reviewresponse?.length > 0">
                                <div class="row" style="line-height: 1.2;">
                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9"></div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
                                        <div class="text-color f-13">Sort By</div>
                                        <select class="box border-0 fw-bold f-14" name="selectedValue"
                                            [(ngModel)]="selectedValue" (change)="getReviewList()">
                                            <option value="Popularity">Popularity</option>
                                            <option value="Latest">Latest</option>
                                        </select>
                                    </div>

                                </div>

                                <!-- <div *ngFor="let reviewlist of reviewresponse | paginate: { id: 'brandpag', itemsPerPage: pagination.numPerPage,
                            currentPage: pagination.pageNum,totalItems:listcount} ;let i=index"> -->
                                <div *ngFor="let reviewlist of reviewresponse | paginate: { id: 'brandpag', itemsPerPage: pagination.numPerPage,
                            currentPage: pagination.pageNum,totalItems:ratingCount} ;let i=index">

                                    <div class="review-border mt-3">
                                        <div class="dotted-border">
                                            <div class="row">
                                                <div
                                                    class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                                    <div class="">
                                                        <span
                                                            class="badge rounded bg-primary text-white p-2 fw-bold">
                                                            {{reviewlist.name}}
                                                        </span>

                                                    </div>
                                                </div>
                                                <div
                                                    class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                    <div class="row">
                                                        <div
                                                            class="col-7 col-sm-7 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                                            <strong
                                                                class="fw-bold f-14">{{reviewlist.userName}}</strong>
                                                            <div class="star-rating">
                                                                <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
                                                                    <span class="star"
                                                                        [class.filled]="i <= reviewlist.rating">&#9733;</span>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                                            <small
                                                                class="text-color f-13">{{reviewlist.ratingDate
                                                                |
                                                                date}}</small>
                                                        </div>

                                                    </div>
                                                    <p class="text-color f-13">{{reviewlist.reviewDescription}}
                                                    </p>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row mt-1" *ngIf="reviewresponse?.length > 0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end">
                                    <div class="text-right">
                                        <pagination-controls (pageChange)="getPagination($event)" id="brandpag">
                                        </pagination-controls>
                                    </div>

                                </div>
                            </div> -->
                            <div class="row mt-1" *ngIf="reviewresponse.length > 0" style="color: #96989c;">
                                <div class="text-left d-flex">
                                    <div *ngIf="reviewRatingCnt > 1" (click)="changeReviewCount('decrease')">
                                        <i class="fa-solid fa-arrow-left" style="color: #858a93;"></i> &nbsp;
                                    </div>
                                    {{reviewRatingCnt}} of {{pageNo}}
                                    <div *ngIf="reviewRatingCnt != pageNo "
                                        (click)="changeReviewCount('increase')">
                                        &nbsp;&nbsp;<i class="fa-solid fa-arrow-right"
                                            style="color: #858a93;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        </div>
    </div>   
    <!-- <skeleton-loader *ngIf="isLoading"></skeleton-loader> -->
    <div id="myModal" class="modal">
        <span class="close cursor" onclick="closeModal()">&times;</span>
        <div class="modal-content ">

            <div class="mySlides text-center product " *ngFor="let img of item.itemImageBoList;let i=index">
                <img src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy"
                    onError="this.src='assets/img/errorLogo.png'" class="image-popup">
                <div class="zoom-overlay"></div>
            </div>

            <div class="column mt-2 d-flex justify-content-center">
                <div *ngFor="let img of item.itemImageBoList;let i=index" class="badge rounded-4"
                    [ngClass]="{'rounded-5': i == currentimageIndexpopup}">
                    <span class="image-height">
                        <span class="inner-span">
                            <img src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}" loading="lazy"
                                onError="this.src='assets/img/errorLogo.png'" (click)="openImg(i)" class="review-image">
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>