import { Component, OnInit,PLATFORM_ID ,Inject} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'under-maintenance',
  templateUrl: './under-mantanance.component.html',
  styleUrls: ['./under-mantanance.component.scss']
})
export class UnderMantananceComponent implements OnInit {

  public data:any;


  array:any = [
    {
      type:1,
      name:"A",
      uType:1
    },
    {
      type:1,
      name:"B",
      uType:2
    }
    ,{
      type:2,
      name:"C",
      uType:3
    }
    ,{
      type:2,
      name:"D",
      uType:3
    }
  ]


  public isButtonDisabled:boolean = false;
  constructor(
    public dataService: CommanservicesService,
    private title: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.sendMessage('pageChange')
   }

   }

  ngOnInit(): void {
    this.getOrganization();
  }


  //GET ORGANIZATION
  getOrganization() {
    this.dataService.getOrganization(this.dataService.appConstant.SERVER_URLS['GET_ORGANIZATION']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === result.status) {
        this.router.navigate(['/']);
        this.dataService.sendMessage('pageChange')
      }
    },err=>{
      this.dataService.throwError(err)
    })
  }



    //GWITH LOADER
    getOrganization1() {
      this.isButtonDisabled = true;
      this.dataService.getOrganization(this.dataService.appConstant.SERVER_URLS['GET_ORGANIZATION']).subscribe(result => {
        this.data = result;
        this.isButtonDisabled = false;
        if (this.dataService.appConstant.SUCCESS === result.status) {
          this.router.navigate(['/']);
          this.dataService.sendMessage('pageChange')
        }
      },err=>{
        this.isButtonDisabled = false;
        this.dataService.appConstant.errorMsg("Server under maintenance....")
      })
    }
  


}
