
import { Component, OnInit, Inject, PLATFORM_ID, HostListener, OnDestroy } from '@angular/core';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { DatePipe } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { OwlOptions } from 'ngx-owl-carousel-o';


import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { Select, Store } from '@ngxs/store';
import { CategorySate } from 'src/app/store/state/category.state';
import { Observable } from 'rxjs';
import { CategoryData } from '../appModels/category.model';
import { getCategorys } from 'src/app/store/actions/category.action';
import { Subscription } from 'rxjs/Subscription';
import { CookieService } from 'ngx-cookie-service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { FirebaseServiceService } from '../../services/firebase-service.service'
import { MenuBarSate } from 'src/app/store/state/menubar.state';
import { getMenuBar } from 'src/app/store/actions/menubar.action';
import { json } from 'express';
declare var $: any;

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent implements OnInit {
  @Select(CategorySate.getCategoryData) categoryList$: Observable<CategoryData[]>;

  @Select(CategorySate.getCategoryLoaded) categoryLoaded$: Observable<boolean>;

  categoryloadedSub: Subscription;
  @Select(MenuBarSate.getMenubarList) getMenuBarList$: Observable<any[]>;
  @Select(MenuBarSate.getMenubarLoaded) menubarListLoaded$: Observable<boolean>;
  menubarloadedSub: Subscription;
  public data: any = {};
  public apiResponse: any = {}
  public isCategoryLoading: boolean = false;
  public pagination: any = { pageNum: 1, numPerPage: 10 }
  public categoryList: any = []
  public menubarList: any = [];
  public parentCategoryIndex: any;
  public subCategoryIndex: any;
  public cartList: any = [];
  public currencySymbol: any;
  public cartObject: any = {};
  public searchProduct: any;
  public paginationProd: any = { pageNum: 1, numPerPage: 10 }
  public productList: any = [];
  public freshProductList: any = [];
  public selectedVariantIndex: any;
  public productListIndex: any;
  public proceedToAdd: any = {};
  public couponList: any = [];
  public isProductLoading: boolean = false;
  public showSearchBarInHeader:Boolean=false;

  //LOGIN
  public signInForm: FormGroup;
  public loginWithOtpForm: FormGroup;
  public forgotPassForm: FormGroup;
  public isLoadMore: boolean = true;
  public isLoading: boolean = false;
  public isServerError: boolean = false;
  public loginTab: any;
  public isLoginOtpRequested: boolean = false;
  public signUpInPopup: any;
  public isInstanceLogin: boolean = false;

  public formData: any = {};
  public pass: string;
  public passIcon: string;
  public pass1: string;
  public passIcon1: string;
  public uuid: any;
  public tab: any;
  public resendOtp: any = {};

  //Loading
  public isButtonDisabled: boolean = false;
  public isShowWarning: boolean = false;
  public isShowSuccess: boolean = false;
  public isShowError: boolean = false;
  public actionMessage: any;
  public isShowActionToast: boolean = false;
  public showSearchIcon:boolean=false;
  public loginWithOtpTap: any;
  public user: any = {};
  public userUniqueId: any;

  public faqList:any=[];
  //LOGIN END
  advertisingSlider: OwlOptions = {
    loop: true,
    items: 3,
    autoWidth: true,
    mouseDrag: true,
    autoplay: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };




  time: any;
  timer: number = 120;
  timeLeft: number;
  interval;
  disableStatus: boolean = true;
  isOTPSelected: boolean = false;
  isOTPVerified: boolean = false;
  public isUserAllInfoAvailable: boolean = true;
  item = { "pageNum": 1, "numPerPage": 14, "searchKey": null, "saveFilter": "", "userFilter": [] };
  productCount: any;
  showButton = false;
  otpUserInfoData: any = {};
  deferredPrompt: any;
  warehouseData: any = {};
  public storesList: any = [];
  checkbtnDis: boolean = false;
  public productSerachList: any = [];
  fromSignup: boolean = false;
  public footerData: any = {};
  signIn: any;
  brandobject: any = {};
  brandlist: any = [];
  itemlist: any = [];
  offerlist: any = [];
  selectedidex: number = 0;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }
  orgnizationData: any = {};
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private title: Title,
    private store: Store,
    private meta: Meta,
    public formBuilder: FormBuilder,
    public dataService: CommanservicesService,
    public fireBaseSerive: FirebaseServiceService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public ngZone: NgZone,
    public cookie: CookieService,
    public tokenStorage: TokenStorageService,
  ) {

    this.pass = "password";
    this.passIcon = "hide";

    this.pass1 = "password";
    this.passIcon1 = "hide";

    // setTimeout(() => {
    this.orgnizationData = JSON.parse(localStorage.getItem("User_Org"));
    this.loadAllApis();
    // }, 100);

    if (isPlatformBrowser(this.platformId)) {


      this.signInForm = this.formBuilder.group({
        email: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
            ),
          ]),
        ],
        password: [null, Validators.required],
      });

      this.loginWithOtpForm = this.formBuilder.group({
        mobile: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ]),
        ]
      });


      this.forgotPassForm = this.formBuilder.group({
        email: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
            ),
          ]),
        ],
      });


      this.currencySymbol = this.dataService.appConstant.getCurrencySymbol();

      this.parentCategoryIndex = 0;
      this.subCategoryIndex = 0;

      this.loginWithOtpTap = 'enterNo'
      this.signUpInPopup = 'signIn'
      this.loginTab = 'withPassword';
      this.tab = 'enterno';
      this.user = JSON.parse(localStorage.getItem("userInfo"));
      this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
      if (this.warehouseData) {
        this.paginationProd.warehouseId = this.warehouseData.warehouseId;
      }

      if (this.user) {
        this.userUniqueId = this.user.userUniqueId
        this.cartList = JSON.parse(localStorage.getItem("cartList"));
      } else {
        let cartList = JSON.parse(localStorage.getItem("tempCartList"));
        if (cartList) {
          this.cartList = cartList;
          this.tempCartTotalCalulation();
        }

      }

      this.dataService.getMessage().subscribe((msg) => {
        if (msg === "ProceedForLogin") {
          this.isInstanceLogin = true;
          $('#signInModal').modal('show');
        }
        if (msg == 'pageChange') {
          this.searchProduct = null;
        }
        if (msg == 'Selected_Warehouse') {
          this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
          if (this.warehouseData) {
            this.paginationProd.warehouseId = this.warehouseData.warehouseId;
          }
        }

        if (msg == 'clearSearchBar') {
          this.searchProduct = null;
        }

        if (msg == 'logged_out') {
          this.user = {};
          this.dataService.sendMessage("cartListUpdated");
        }

        if (msg == 'cartListUpdated') {
          if (this.user) {

            this.cartList = JSON.parse(localStorage.getItem("cartList"));
            this.cartObject = JSON.parse(localStorage.getItem("cartTotal"));
            if (this.cartObject) {
              this.cartObject.totalAmount = parseFloat(this.cartObject.totalAmount)
              this.cartObject.savedAmount = parseFloat(this.cartObject.savedAmount)
            }
            if (this.cartList && this.cartList.length >= 1) {
              this.cartList.forEach(element => {
                if (!element.isStockAvailable) {
                  this.checkbtnDis = true;
                } else {
                  this.checkbtnDis = false;
                }
              });
            } else {
              this.checkbtnDis = false;
            }
          } else {
            let cartList: any = JSON.parse(localStorage.getItem("tempCartList"));
            if (cartList) {
              this.cartList = cartList;
              this.tempCartTotalCalulation();
            }
          }

          this.updateProductList();
        }


        if (msg === "open-signIn") {
          this.openSignInModal('signIn');
        }
        if (msg === "open-createAccount") {
          this.openSignInModal('createAccount');
        }
        if (msg == 'Change_Store') {
          this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
          if (this.warehouseData.warehouseId) {
            if (this.storesList && this.storesList.length >= 1) {
              $('#storeModel').modal('show');
            }
          }
        }
        if (msg == 'get_org_data') {

        }
      });

    }
  }



  ngOnInit(): void {
    this.dataService.getDomainWiseOrgnization();
    this.orgnizationData = this.dataService.currentMessage.subscribe(message => this.orgnizationData = message)
    // this.getDomainWiseOrgnization();

    
    if (this.menubarList && this.menubarList.length > 0) {
      this.brandlist = this.menubarList[0].brandList
    }

    this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
    if (!this.warehouseData.warehouseId && this.router.url != 'web/unsubscribe-notificationsetting/') {
      if (this.storesList && this.storesList.length >= 1) {
        $('#storeModel').modal('show');
      }
    }
    if (this.cartList && this.cartList.length >= 1) {
      this.cartList.forEach(element => {
        if (!element.isStockAvailable) {
          this.checkbtnDis = true;
        } else {
          this.checkbtnDis = false;
        }
      });
    } else {
      this.checkbtnDis = false;
    }
    // this.getMenuBarList();
    if (!localStorage.getItem('websiteSearchProList')) {
      //this.searchItems();
    } else {
      this.productSerachList = JSON.parse(localStorage.getItem('websiteSearchProList'));
    }

    this.getFooterData();

  }


  setindex(brandobj) {
    this.brandobject = brandobj
  }
  loadAllApis() {
    this.getStore();
    this.getCategory(this.item);
    // if (localStorage.getItem('userInfo')) {
    //   this.getMenuBarList();
    // }
    this.getMenuBarList();
    this.commonAppList();
    // this.getItems();
    this.getActiveCouponList();
  }

  // GET Warhouse
  getStore() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_WAREHOUSE"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.storesList = this.data.result;
        // let a=JSON.stringify( this.storesList)
        if (this.orgnizationData && this.orgnizationData.warehouseListCount == 1) {
          localStorage.setItem('warehouse', JSON.stringify(this.storesList[0]));
        }
        if(this.storesList && this.storesList.length==1){
          this.warehouseData = this.storesList[0];
          localStorage.setItem('warehouse', JSON.stringify(this.warehouseData));
          this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
          // this.dataService.sendMessage("Selected_Warehouse");
          // this.dataService.appConstant.successMsg("Store Updated Successfully");
         
        }
      }
    }, err => {
    })
  }

  selectWarehouse(store) {
    this.warehouseData = store;
    localStorage.setItem('warehouse', JSON.stringify(this.warehouseData));
    this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
    this.dataService.sendMessage("Selected_Warehouse");
    this.dataService.appConstant.successMsg("Store Updated Successfully");
    if (this.user && this.user.userUniqueId) {
      this.perferWarhouse(this.warehouseData.warehouseId);
    }
    this.dataService.getCartList();
    $('#storeModel').modal('hide');
  }

  public perferWarhouse(id) {
    if (this.user && this.user.userUniqueId) {
      this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS['PERFERRED_WAREHOUSE'] + id
        + '/' + this.user.userUniqueId).subscribe(result => {
          this.data = result;
          if (this.data.status === 200) {

          }
        });
    }
  }

  homeReload() {
    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
  }


  // coupon list
  getActiveCouponList() {
    var userUid: string;
    var user = JSON.parse(localStorage.getItem('userInfo'));
    if (user) {
      userUid = user.userUniqueId;
    }
    let request = {
      "pageNum": 1,
      "numPerPage": 10,
      "userUid": userUid,
      "searchKey": this.pagination.searchKey
    }
    this.dataService.openPostRequest(request, this.dataService.appConstant.SERVER_URLS['GET_ACTIVE_COUPON_DISCOUNT']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.couponList = this.data.result;
      }
    })
  }


  copyToClipboard(item) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = item.discountCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.dataService.appConstant.successMsg("Coupon code copied...")
  }



  commonAppList() {
    this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['APP_LIST_ITEM']).subscribe(response => {
      this.data = response.result;
      if (this.dataService.appConstant.SUCCESS === response.status) {
        localStorage.setItem('commonAppList', JSON.stringify(this.data));
      }
    });
  }
  goToPrivacyPolicy(tab) {
    this.router.navigate(['web/policyPage', tab]);
    if (tab == 'Privacy_Policy') {
      this.dataService.sendMessage("privacyPolicy")
    }else if(tab=='About_Us'){
      this.dataService.sendMessage("About_Us")
    } else if(tab=='Terms_and_Conditions') {
      this.dataService.sendMessage("tearmCondition")
    }else if(tab=='Shipping_Policy') {
      this.dataService.sendMessage("shippingPolicy")
    }  else if(tab=='Return_Policy') {
      this.dataService.sendMessage("returnPolicy")
    }

  }
  gotoFAQ(){
    this.router.navigate(['web/faq'])
  }

  //===================SIGN IN SING UP OPERATIONS========================/

  openSignInUP(type) {
    this.signUpInPopup = type;
    this.resendOtp = {};
    this.formData = {};
    this.loginWithOtpTap = 'enterNo'
    this.loginTab = 'withPassword';
    this.tab = 'enterno';
  }

  signOut() {
    $('#singOutMessageModal').modal('show');

  }

  signOutConfim() {
    this.dataService.sendMessage('logged_out')
    this.user = {};
    this.dataService.appConstant.successMsg("Log out successfully")
    $('#mobileSideBar').modal('hide');
    this.router.navigate(['']);
    this.dataService.sendMessage('clearSearchBar');
    this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
    localStorage.clear();
    this.dataService.sendMessage('cartListUpdated');
    // this.cartList = [];
    localStorage.setItem('warehouse', JSON.stringify(this.warehouseData));
    $('#singOutMessageModal').modal('hide');
    // window.location.reload();
    this.router.navigate(['']);
    this.cookie.deleteAll();
    location.reload();
  }

  openLoginPop(type: any) {
    this.loginTab = type;
  }

  sendLoginOTP() {
    this.isLoginOtpRequested = true;
  }

  openSignUP(value: any) {
    this.signUpInPopup = value;
  }

  proccedWithOtp() {
    this.loginTab = 'withPassword';
    this.signUpInPopup = 'signIn';
    this.fromSignup = true;
  }

  openSignInModal(tab: any) {
    $('#mobileSideBar').modal('hide');
    if (tab == 'signIn') {
      this.signUpInPopup = 'signIn'
    } else if (tab == 'createAccount') {
      this.signUpInPopup = 'createAccount'
    } else
      if (tab == 'signInOptions') {
        this.signUpInPopup = 'signInOptions'
      }
    this.fromSignup = false;
    $('#signInModal').modal('show');

  }

  openSignInWithOtp() {
    this.signUpInPopup = 'signIn';
    this.loginTab = 'withPassword';
  }

  openSignInWithEmail() {
    this.signUpInPopup = 'signIn';
    this.loginTab = 'withEmailPassword';
  }

  public signUp(): void {
    if (this.formData.confirmPass == this.formData.password) {
      var name = this.formData.name.split(" ");
      this.formData.firstName = name[0];
      this.formData.lastName = name[1];
      this.formData.loginWith = 0;
      if (this.formData.email.length == 0) {
        this.formData.email = null
      }
      this.formData.platform = "Website";
      this.formData.loginWith = 1;
      this.formData.uuid = this.makeid();
      this.isButtonDisabled = true;
      this.dataService.LoginRequest(this.formData, this.dataService.appConstant.SERVER_URLS['SIGN_UP']).subscribe((response => {
        this.data = response;
        if (this.data.status == 200) {
          this.formData = {};
          this.isShowActionToast = true;
          this.isShowSuccess = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
          this.signUpInPopup = 'signIn';
          this.loginTab = 'withEmailPassword'
        } else {
          this.isShowError = true;
          this.isShowActionToast = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
        }
      }), err => {
        this.isShowError = true;
        this.isShowActionToast = true;
        this.actionMessage = this.data.message;
        this.isButtonDisabled = false;
      })
    }

    setTimeout(() => {
      this.isShowError = false;
      this.isShowActionToast = false;
      this.isShowSuccess = false;
      this.isShowWarning = false
    }, 5000);

  }

  public loginWithOtp(form: NgForm): void {
    this.markFormTouched(this.loginWithOtpForm);
    if (this.loginWithOtpForm.valid) {
      // if (this.resendOtp.username.includes("@")) {
      //   this.resendOtp.email = this.formData.username;
      //   this.resendOtp.mobile = null;
      // } else {
      //   this.resendOtp.mobile = this.formData.username;
      //   this.resendOtp.email = null;
      // }


      // if (this.resendOtp.username.includes('@')) {
      //   this.resendOtp.email = this.resendOtp.username
      //   this.resendOtp.mobile = null;
      // } else {
      //   this.resendOtp.mobile = this.resendOtp.username
      //   this.resendOtp.email = null;
      // }
      this.resendOtp.mobile = this.resendOtp.username;
      this.isButtonDisabled = true;
      this.dataService.LoginRequest(this.resendOtp, this.dataService.appConstant.SERVER_URLS['SEND_TOP']).subscribe((response => {
        this.data = response;
        if (this.data.status == 200) {
          this.uuid = this.data.result.userUniqueId;
          this.isShowActionToast = true;
          this.isShowSuccess = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
          this.isLoginOtpRequested = true;
          this.tab = 'otp'
          this.loginWithOtpTap = 'enterOTP';
          this.timeLeft = 120;
          this.isOTPSelected = true;
          this.isOTPVerified = false;
          this.startTimer();
        } else {
          this.isShowError = true;
          this.isShowActionToast = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
        }
      }), err => {
        this.isShowError = true;
        this.isShowActionToast = true;
        this.actionMessage = this.data.message;
        this.isButtonDisabled = false;
      })


      setTimeout(() => {
        this.isShowError = false;
        this.isShowActionToast = false;
        this.isShowSuccess = false;
        this.isShowWarning = false
      }, 5000);
    }
  }


  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  makeid() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 16; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  public hideShowPassword(): void {
    this.pass = this.pass === "password" ? "mobile" : "password";
    this.passIcon = this.passIcon === "hide" ? "show" : "hide";
  }

  public hideShowPassword1(): void {
    this.pass1 = this.pass1 === "password" ? "mobile" : "password";
    this.passIcon1 = this.passIcon1 === "hide" ? "show" : "hide";
  }


  /************************FORGOT PASSWORD*********************************** */
  public openForgotPassword(): void {
    this.resendOtp = {};
    this.tab = 'enterno';
    $('#signInModal').modal('hide');
    this.router.navigate['']
    $('#forgotPassword').modal('show');
  }

  /*************SEND OTP********** */
  public sendOTP(form: NgForm) {

    this.markFormTouched(this.forgotPassForm);
    if (this.forgotPassForm.valid) {
      if (this.resendOtp.username.includes('@')) {
        this.resendOtp.email = this.resendOtp.username
        this.resendOtp.mobile = null;
      } else {
        this.resendOtp.mobile = this.resendOtp.username
        this.resendOtp.email = null;
      }
      this.isButtonDisabled = true;
      this.dataService.LoginRequest(this.resendOtp, this.dataService.appConstant.SERVER_URLS['FORGOT_PASSWORD']).subscribe((response => {
        this.data = response;
        if (this.data.status == 200) {
          this.uuid = this.data.result.userUniqueId;
          this.formData = {};
          this.isShowActionToast = true;
          this.isShowSuccess = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
          this.isLoginOtpRequested = true;
          this.tab = 'otp'
          this.timeLeft = 120;
          this.isOTPSelected = true;
          this.isOTPVerified = false;
          this.startTimer();
        } else {
          this.isShowError = true;
          this.isShowActionToast = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
        }
      }), err => {
        this.isShowError = true;
        this.isShowActionToast = true;
        this.actionMessage = this.data.message;
        this.isButtonDisabled = false;
      })


      setTimeout(() => {
        this.isShowError = false;
        this.isShowActionToast = false;
        this.isShowSuccess = false;
        this.isShowWarning = false
      }, 5000);
    }

  }

  /*************RRESEND OTP********** */
  public resendOTP() {
    if (this.resendOtp.username.includes('@')) {
      this.resendOtp.email = this.resendOtp.username
      this.resendOtp.mobile = null;
    } else {
      this.resendOtp.mobile = this.resendOtp.username
      this.resendOtp.email = null;
    }
    this.dataService.LoginRequest(this.resendOtp, this.dataService.appConstant.SERVER_URLS['RESEND_OTP']).subscribe((response => {
      this.data = response;
      if (this.data.status == 200) {
        this.uuid = this.data.result.userUniqueId;
        this.tab = 'otp'
        this.isShowActionToast = true;
        this.isShowSuccess = true;
        this.isLoginOtpRequested = true;
        this.actionMessage = this.data.message;

        this.timeLeft = 120;
        this.isOTPSelected = true;
        this.isOTPVerified = false;
        this.startTimer();
      } else {
        this.isShowError = true;
        this.isShowActionToast = true;
        this.actionMessage = this.data.message;
        this.isButtonDisabled = false;
      }
    }), err => {
      this.isShowError = true;
      this.isShowActionToast = true;
      this.actionMessage = this.data.message;
      this.isButtonDisabled = false;
    })
    setTimeout(() => {
      this.isShowError = false;
      this.isShowActionToast = false;
      this.isShowSuccess = false;
      this.isShowWarning = false
    }, 5000);

  }
  /*************VERIFY OTP********** */
  public loginWithOtpVerify(form: NgForm) {
    this.isButtonDisabled = true;
    this.resendOtp.userUniqueId = this.uuid;
    this.resendOtp.uuid = this.makeid();
    this.resendOtp.platform = "Website";
    this.resendOtp.loginWith = 1;
    this.dataService.LoginRequest(this.resendOtp, this.dataService.appConstant.SERVER_URLS['SIGN_IN_SIGN_UP_BY_OTP']).subscribe((response => {
      this.data = response;
      if (this.data.status == 200) {
        this.formData = {};
        this.dataService.appConstant.successMsg(this.data.message);
        if (this.data.result) {
          this.tokenStorage.saveUser(this.data.result);
          this.tokenStorage.saveToken(this.data.result.accessToken);
          this.tokenStorage.saveRefreshToken(this.data.result.refreshToken);
          this.addToCartTempItem(this.data.result.userUniqueId);
          // this.dataService.appConstant.getAccessToken(this.data.result.accessToken);
          // this.cookie.set('refresh_token', this.data.result.refreshToken, 1, '/', 'localhost', true, "None");
          delete this.data.result.accessToken;
        }
        this.user = this.data.result;
        this.isButtonDisabled = false;
        // localStorage.setItem("agGrocery", JSON.stringify(this.data.result));
        this.validateUserInformation();
        if (this.isUserAllInfoAvailable == true) {
          $('#signInModal').modal('hide');
        }


        this.dataService.sendMessage("LoggedIn");
        this.dataService.getCartList();
        if (this.isInstanceLogin) {
          this.isInstanceLogin = false;
          this.dataService.sendMessage(sessionStorage.getItem('pageName'))
        }
      } else {
        this.isShowError = true;
        this.isShowActionToast = true;
        this.actionMessage = this.data.message;
        this.isButtonDisabled = false;
      }
    }), err => {
      this.isShowError = true;
      this.isShowActionToast = true;
      this.actionMessage = this.data.message;
      this.isButtonDisabled = false;
    })

    setTimeout(() => {
      this.isShowError = false;
      this.isShowActionToast = false;
      this.isShowSuccess = false;
      this.isShowWarning = false
    }, 5000);

  }
  /*************CHANGE PASSWORD********** */
  public changePass(form: NgForm) {
    if (this.resendOtp.confirmPass == this.resendOtp.newPassword) {
      this.isButtonDisabled = true;
      this.resendOtp.userUniqueId = this.uuid;
      this.isButtonDisabled = true;
      this.dataService.LoginRequest(this.resendOtp, this.dataService.appConstant.SERVER_URLS['CHANGE_PASS']).subscribe((response => {
        this.data = response;
        if (this.data.status == 200) {
          this.formData = {};
          this.isShowActionToast = true;
          this.isShowSuccess = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
          this.signUpInPopup = 'signIn';
          this.loginTab = 'withEmailPassword'
          $('#signInModal').modal('show');
          $('#forgotPassword').modal('hide');
        } else {
          this.isShowError = true;
          this.isShowActionToast = true;
          this.actionMessage = this.data.message;
          this.isButtonDisabled = false;
        }
      }), err => {
        this.isShowError = true;
        this.isShowActionToast = true;
        this.actionMessage = this.data.message;
        this.isButtonDisabled = false;
      })
    }

    setTimeout(() => {
      this.isShowError = false;
      this.isShowActionToast = false;
      this.isShowSuccess = false;
      this.isShowWarning = false
    }, 5000);
  }
  // Sign in with Google
  googleAuth() {
    this.fireBaseSerive.authLogin(new auth.GoogleAuthProvider(), this.orgnizationData.tenantName);
    // return this.authLogin(new auth.GoogleAuthProvider());
  }

  // Sign in with FACEBOOK
  facebookAuth() {
    return this.fireBaseSerive.authLogin(new auth.FacebookAuthProvider(), this.orgnizationData.tenantName);
  }


  public validateUserInformation(): void {
    this.isUserAllInfoAvailable = true;
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    if (this.user) {
      if (!this.user.firstName) {
        this.isUserAllInfoAvailable = false, this.tab = 'otp'
        this.loginWithOtpTap = 'otpUserInfo'
      }
      if (!this.user.lastName) {
        this.isUserAllInfoAvailable = false, this.tab = 'otp'
        this.loginWithOtpTap = 'otpUserInfo'
      }
      if (!this.user.mobile) {
        this.isUserAllInfoAvailable = false, this.tab = 'otp'
        this.loginWithOtpTap = 'otpUserInfo'
      }
      if (!this.user.email) {
        this.isUserAllInfoAvailable = false, this.tab = 'otp'
        this.loginWithOtpTap = 'otpUserInfo'
      }
    } else {
      this.isUserAllInfoAvailable = false;
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.loginWithOtpTap = 'enterNo';
        this.disableStatus = false;
        this.pauseTimer();
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  public loginWithPassword(): void {
    this.markFormTouched(this.signInForm);
    if (this.signInForm.valid) {
      if (this.formData.username.includes("@")) {
        this.formData.email = this.formData.username;
        this.formData.mobile = null;
      } else {
        this.formData.mobile = this.formData.username;
        this.formData.email = null;
      }

      this.formData.loginWith = 1;
      this.formData.platform = "Website";
      this.formData.uuid = this.makeid();
      this.isButtonDisabled = true;
      this.dataService
        .LoginRequest(this.formData,
          this.dataService.appConstant.SERVER_URLS["SING_IN"])
        .subscribe(
          (response) => {
            this.data = response;
            if (this.data.status == 200) {
              this.formData = {};
              this.dataService.appConstant.successMsg(this.data.message);
              if (this.data.result) {
                this.tokenStorage.saveUser(this.data.result);
                this.tokenStorage.saveToken(this.data.result.accessToken);
                this.tokenStorage.saveRefreshToken(this.data.result.refreshToken);
                this.addToCartTempItem(this.data.result.userUniqueId);
                delete this.data.result.accessToken;
              }
              this.user = this.data.result;
              this.isButtonDisabled = false;
              localStorage.setItem("userInfo", JSON.stringify(this.data.result));
              $('#signInModal').modal('hide');
              this.router.navigate['']
              this.dataService.sendMessage("LoggedIn");
              this.dataService.getCartList();
              if (this.isInstanceLogin) {
                this.isInstanceLogin = false;
                this.dataService.sendMessage(sessionStorage.getItem('pageName'))
              }
            } else {
              this.isShowError = true;
              this.isShowActionToast = true;
              this.actionMessage = this.data.message;
              this.isButtonDisabled = false;
            }
          },
          (err) => {
            this.isShowError = true;
            this.isShowActionToast = true;
            this.actionMessage = this.data.message;
            this.isButtonDisabled = false;
          }
        );

      setTimeout(() => {
        this.isShowError = false;
        this.isShowActionToast = false;
        this.isShowSuccess = false;
        this.isShowWarning = false
      }, 5000);
    }
  }
  goToCheckout() {
    $('#cartModal').modal('hide');
    if (this.user) {
      this.router.navigate(['/web/ag/cart']);
    } else {
      this.dataService.sendMessage("ProceedForLogin")

    }
  }


  itemDetail(item: any) {
    $('#searchDropDowns').dropdown('dispose');
    // $event.stopPropagation()
    if (item.itemLink) {
      this.router.navigate(['web/product-detail', item.itemLink]);
    } else {
      this.router.navigate(['web/product-detail'], { queryParams: { itemUid: item.itemUid } });
    }
    $('#cartModal').modal('hide');
  }

  addToCartTempItem(userUid: String) {
    let cartList = JSON.parse(localStorage.getItem('tempCartList'));
    if (cartList && cartList.length > 0) {
      this.router.navigate(['']);
      this.dataService.postRequest(cartList, this.dataService.appConstant.SERVER_URLS['BULK_ADD_TO_CART'] + userUid).subscribe(result => {
        this.data = result;
        this.dataService.getCartList();
      })
    } else {
      this.dataService.getCartList();
    }

    localStorage.removeItem("tempCartList");
  }

  // localStorage.removeItem("tempCartList");

  public updateCartQuantityProdList(i: any, type: any): void {
    this.productListIndex = i;
    if (this.user) {
      this.proceedToAdd = this.productList[this.productListIndex].otherChildItemsBOList[this.productList[this.productListIndex].selectedItem.selectedVariantIndex];
      if (type == "increase") {
        this.proceedToAdd.quantity += 1;
      } else {
        this.proceedToAdd.quantity -= 1;
      }
      if (this.proceedToAdd.quantity < 1) {
        this.productList[this.productListIndex].otherChildItemsBOList[this.productList[this.productListIndex].selectedItem.selectedVariantIndex].quantity = 1;
        this.productList[this.productListIndex].otherChildItemsBOList[this.productList[this.productListIndex].selectedItem.selectedVariantIndex].isAdded = false;
        this.productList[this.productListIndex].selectedItem.quantity = 1;
        this.productList[this.productListIndex].selectedItem.isAdded = false;
        this.removeItemFromCart(this.proceedToAdd);
      } else {
        this.dataService.putRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {

      this.proceedToAdd = this.cartList[i];
      let Qty = this.proceedToAdd.quantity;

      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.cartList[i].quantity = 1;
        this.cartList[i].isAdded = false;
        this.removeItemFromTempCart(this.proceedToAdd.itemUid);
      } else {
        this.cartList[i].quantity = Qty;
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === this.proceedToAdd.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === this.proceedToAdd.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }
      this.dataService.getCartList();
      this.tempCartTotalCalulation();
    }

  }
  updateProductList() {
    this.productList = this.freshProductList;

    if (this.user && this.cartList && this.cartList.length > 0) {
      this.cartList = JSON.parse(localStorage.getItem("cartList"));
      this.productList.forEach((element: any) => {
        if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
          element.otherChildItemsBOList.forEach((element2: any) => {
            this.cartList.forEach((element3: any) => {
              if (element3.itemUid == element2.itemUid) {
                if (element2.quantity > 0) {
                  element2.isAdded = true
                  element2.quantity = element3.quantity;
                  element2.cartId = element3.cartId;
                } else {
                  element2.isAdded = false
                  element2.quantity = 1;
                }
              }
            });
          });
        }

        //IF PRODUCT NOT HAVE ITEM GROUP ;
        //if (!element.otherChildItemsBOList) {
        element.isAdded = false;
        element.quantity = 1;
        this.cartList.forEach((element3: any) => {
          if (element3.itemUid == element.itemUid) {
            element.isAdded = true
            element.quantity = element3.quantity;
            element.cartId = element3.cartId;
          }
        });
        // }


      });
    } else {
      let tempCartList = JSON.parse(localStorage.getItem("tempCartList"));
      if (tempCartList) {
        this.cartList = tempCartList;
      }

      this.productList.forEach((element: any) => {
        if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
          element.otherChildItemsBOList.forEach((element2: any) => {
            element2.isAdded = false
            element2.quantity = 1;
            if (tempCartList && tempCartList.length > 0) {
              tempCartList.forEach(element3 => {
                if (element3.itemUid == element2.itemUid) {
                  if (element2.quantity > 0) {
                    element2.isAdded = true
                    element2.quantity = element3.quantity;
                  }
                }
              });
            }
          });
        }

        element.isAdded = false;
        element.quantity = 1;
        if (tempCartList && tempCartList.length > 0) {
          tempCartList.forEach(element3 => {
            if (element3.itemUid == element.itemUid) {
              element.isAdded = true
              element.quantity = element3.quantity;
              element.cartId = element3.cartId;
            }
          });
        }
      });
    }

  }

  /************UPDATE PROFILE*******/
  public updateProfile(appForm: NgForm): void {
    this.isButtonDisabled = true;
    this.user.userUid = this.user.userUniqueId;
    this.dataService.postRequest(this.user, this.dataService.appConstant.SERVER_URLS['UPDATE_PROFILE']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {

        let user = JSON.parse(localStorage.getItem("userInfo"));
        user.firstName=this.user.firstName;
        user.lastName=this.user.lastName;
        user.email=this.user.email;
        user.email=this.user.email;
        localStorage.setItem("userInfo", JSON.stringify(user));
        this.dataService.sendMessage("EDIT_PROFILE");
        this.isButtonDisabled = false;
        // this.tokenStorage.saveUser(this.data.result);
        this.validateUserInformation();
        this.dataService.appConstant.successMsg(this.data.message);
        this.isUserAllInfoAvailable = true;
        appForm.resetForm();
        $('#signInModal').modal('hide');
        this.router.navigate['']
      } else {
        this.isButtonDisabled = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorMsg(this.data.message);
    });

  }

  public getCategory(payload): void {
    // this.isLoading = true;
    this.categoryloadedSub = this.categoryLoaded$.subscribe(loadedBrands => {
      if (!loadedBrands) {
        this.store.dispatch(new getCategorys(payload));
      }
    })
    this.categoryList$.subscribe(result => {
      this.data = result;
      this.categoryList = result;
      sessionStorage.setItem('categoryList', JSON.stringify(this.categoryList))
      this.dataService.sendMessage("categoryUpdated")
    })
  }
  // getMenuBarList() {
  //   var user = JSON.parse(localStorage.getItem('userInfo'));
  //   let userUid: any;
  //   if (user) {
  //     userUid = user.userUniqueId;
  //   }
  //   this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['MENU_BAR_LIST'] + userUid).subscribe(result => {
  //     this.data = result;
  //     if (this.dataService.appConstant.SUCCESS === this.data.status) {
  //       this.menubarList = this.data.result;
  //     }
  //     else {
  //       this.dataService.appConstant.errorMsg(this.data.message);
  //     }
  //   }, err => {

  //   });





  // }
  getMenuBarList() {
    console.log("------------------------");
    
    this.menubarloadedSub = this.menubarListLoaded$.subscribe(loadedBrands => {
      if (!loadedBrands) {
        this.store.dispatch(new getMenuBar());
      }
    })
    this.getMenuBarList$.subscribe(result => {
      if (result) {
        this.menubarList = result;

        setTimeout(() => {
          if(!this.menubarList || this.menubarList.length<=0){
            this.showSearchBarInHeader=true;
          }else{
            this.showSearchBarInHeader=false;
          }
        }, 1500);
        
      }
    })
  }

  goToProfile(tab: any) {
    $('#mobileSideBar').modal('hide');
    this.router.navigate(['/web/ag/profile', tab]);
  }









  homeSearch(item) {
    if (item.itemUid) {
      this.router.navigate(['/web/ag/product'], { queryParams: { itemUid: item.itemUid } })
    } else {
      this.router.navigate(['/web/ag/product'], { queryParams: { tag: item.itemName } });
    }
  }




  public removeItemFromTempCart(itemUid: String): void {
    let cartList = JSON.parse(localStorage.getItem('tempCartList'));
    if (cartList && cartList.some(cart => cart.itemUid === itemUid)) {
      let index = cartList.findIndex(cart => cart.itemUid === itemUid);
      cartList.splice(index, 1);
      localStorage.setItem("tempCartList", JSON.stringify(cartList));
    }
    this.cartList = cartList;
    this.dataService.getCartList();
    this.tempCartTotalCalulation();
  }

  public tempCartTotalCalulation(): void {
    let cartList = JSON.parse(localStorage.getItem('tempCartList'));
    if (cartList) {
      let totalAmount = 0;
      cartList.forEach(element => {
        totalAmount = totalAmount + (element.quantity * element.finalPrice);
      });
      this.cartObject.totalAmount = totalAmount;
    }
  }


  public removeItemFromCart(cartlistobject): void {
    if (this.user) {
      this.dataService.deleteRequest(this.dataService.appConstant.SERVER_URLS['EDIT_CART'] + cartlistobject.cartId).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      });
    } else {
      this.removeItemFromTempCart(cartlistobject.itemUid);
    }

  }









  public isSearchLoading: boolean = false;

  searchProductByKey() {
    if (this.searchProduct.length > 2 || this.searchProduct == '') {
      this.paginationProd.pageNum = 1;
      this.paginationProd.searchKey = this.searchProduct;
      // this.getSearchedItem();
      if (!this.searchProduct) {
        this.router.navigate(['/web/ag/products']);
      }
    }
  }

  public getSearchedItem(): void {
    if (this.user) {
      this.paginationProd.userUid = this.user.userUniqueId;
    }
    this.paginationProd.popularity = true,
      this.paginationProd.sortType = "popularity"
    this.isLoadMore = true;
    this.paginationProd.pageNum = 1;
    this.isSearchLoading = true;
    if (this.productCount) {
      this.paginationProd.listCount = this.productCount;
    }
    this.dataService.openPostRequest(this.paginationProd, this.dataService.appConstant.SERVER_URLS['GET_SINGLE_ITEM']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.freshProductList = [];
        this.productList = [];
        this.freshProductList = this.data.result;
        this.productList = this.data.result;
        this.productCount = this.data.listCount;

        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        if (this.user && this.cartList && this.cartList.length > 0) {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                this.cartList.forEach((element3: any) => {
                  if (element3.itemUid == element2.itemUid) {
                    element2.isAdded = true
                    element2.quantity = element3.quantity;
                    element2.cartId = element3.cartId;
                  }
                });
              });
            }

            //IF PRODUCT NOT HAVE ITEM GROUP ;
            //  if (!element.otherChildItemsBOList) {
            element.isAdded = false;
            element.quantity = 1;
            this.cartList.forEach((element3: any) => {
              if (element3.itemUid == element.itemUid) {
                element.isAdded = true
                element.quantity = element3.quantity;
                element.cartId = element3.cartId;
              }
            });
            ///  }

          });
        } else {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                element2.isAdded = false
                element2.quantity = 1;
              });
            }
          });
        }

        //SELECT DEFAULT VARIANT OF ITEM;
        this.productList.forEach((element: any) => {
          if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
            element.selectedItem = element.otherChildItemsBOList[0];
            element.selectedItem.selectedVariantIndex = 0
            if (!element.selectedItem.quantity || element.selectedItem.quantity <= 0) {
              element.selectedItem.quantity = 1
            }
          }
        });




        this.isLoading = false;
        this.isSearchLoading = false;
      } else {
        this.isLoading = false;
        this.isSearchLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isSearchLoading = false;
      this.dataService.throwError(err)
    })
  }




  public getItems(): void {
    if (this.user) {
      this.paginationProd.userUid = this.user.userUniqueId;
    }
    this.paginationProd.popularity = true,
      this.paginationProd.sortType = "popularity"
    this.isLoadMore = true;
    this.paginationProd.pageNum = 1;
    this.isSearchLoading = true;
    if (this.productCount) {
      this.paginationProd.listCount = this.productCount;
    }
    this.dataService.openPostRequest(this.paginationProd, this.dataService.appConstant.SERVER_URLS['GET_SINGLE_ITEM']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.freshProductList = this.data.result;
        this.productList = this.data.result;
        this.productCount = this.data.listCount;
        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        if (this.user && this.cartList && this.cartList.length > 0) {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                this.cartList.forEach((element3: any) => {
                  if (element3.itemUid == element2.itemUid) {
                    element2.isAdded = true
                    element2.quantity = element3.quantity;
                    element2.cartId = element3.cartId;
                  }
                });
              });
            }

            //IF PRODUCT NOT HAVE ITEM GROUP ;
            // if (!element.otherChildItemsBOList) {
            element.isAdded = false;
            element.quantity = 1;
            this.cartList.forEach((element3: any) => {
              if (element3.itemUid == element.itemUid) {
                element.isAdded = true
                element.quantity = element3.quantity;
                element.cartId = element3.cartId;
              }
            });
            //  }

          });
        } else {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                element2.isAdded = false
                element2.quantity = 1;
              });
            }
          });
        }

        //SELECT DEFAULT VARIANT OF ITEM;
        this.productList.forEach((element: any) => {
          if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
            element.selectedItem = element.otherChildItemsBOList[0];
            element.selectedItem.selectedVariantIndex = 0
            if (!element.selectedItem.quantity || element.selectedItem.quantity <= 0) {
              element.selectedItem.quantity = 1
            }
          }
        });




        this.isLoading = false;
        this.isSearchLoading = false;
      } else {
        this.isLoading = false;
        this.isSearchLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isSearchLoading = false;
      this.dataService.throwError(err)
    })
  }


  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
    if (this.isLoadMore && !this.isProductLoading) {
      this.paginationProd.pageNum += 1;
      // this.getNewItems();
    }
  }



  public getNewItems(): void {
    if (this.user) {
      this.paginationProd.userUid = this.user.userUniqueId;
    }
    this.paginationProd.popularity = true,
      this.paginationProd.sortType = "popularity"
    this.isProductLoading = true;
    if (this.productCount) {
      this.paginationProd.listCount = this.productCount;
    }
    this.dataService.openPostRequest(this.paginationProd, this.dataService.appConstant.SERVER_URLS['GET_SINGLE_ITEM']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.freshProductList = this.data.result;
        this.productCount = this.data.listCount;
        if (this.data.result && this.data.result, length > 0) {
          this.data.result.forEach(element => {
            this.productList.push(element)
          });
        } else {
          this.isLoadMore = false;
        }



        if (this.data.result && this.data.result, length > 0) {
          this.data.result.forEach(element => {
            this.freshProductList.push(element)
          });
        }




        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        if (this.user && this.cartList && this.cartList.length > 0) {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                this.cartList.forEach((element3: any) => {
                  if (element3.itemUid == element2.itemUid) {
                    element2.isAdded = true
                    element2.quantity = element3.quantity;
                    element2.cartId = element3.cartId;
                  }
                });
              });
            }

            //IF PRODUCT NOT HAVE ITEM GROUP ;
            // if (!element.otherChildItemsBOList) {
            element.isAdded = false;
            element.quantity = 1;
            this.cartList.forEach((element3: any) => {
              if (element3.itemUid == element.itemUid) {
                element.isAdded = true
                element.quantity = element3.quantity;
                element.cartId = element3.cartId;
              }
            });
            //   }

          });
        } else {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                element2.isAdded = false
                element2.quantity = 1;
              });
            }
          });
        }

        //SELECT DEFAULT VARIANT OF ITEM;
        this.productList.forEach((element: any) => {
          if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
            element.selectedItem = element.otherChildItemsBOList[0];
            element.selectedItem.selectedVariantIndex = 0
            if (!element.selectedItem.quantity || element.selectedItem.quantity <= 0) {
              element.selectedItem.quantity = 1
            }
          }
        });
        this.isLoading = false;
        this.isProductLoading = false;
      } else {
        this.isLoading = false;
        this.isProductLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isProductLoading = false;
      this.dataService.throwError(err)
    })
  }


  /*=============================ADD TO CART================================*/


  selectVariant(ind: any) {
    let variant = this.productList[ind].selectedItem.selectedVariantIndex
    this.productList[ind].selectedItem = this.productList[ind].otherChildItemsBOList[this.productList[ind].selectedItem.selectedVariantIndex];
    this.productList[ind].selectedItem.selectedVariantIndex = variant;
    if (!this.productList[ind].selectedItem.isAdded) {
      this.productList[ind].selectedItem.quantity = 1;
    }
  }


  public addToCart(i: any): void {
    if (this.user) {
      this.productListIndex = i;
      this.productList[this.productListIndex].isLoading = true;
      this.proceedToAdd = this.productList[this.productListIndex].otherChildItemsBOList[this.productList[this.productListIndex].selectedItem.selectedVariantIndex];
      this.proceedToAdd.userUid = this.user.userUniqueId;
      this.proceedToAdd.quantity = 1;
      this.dataService.postRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['ADD_TO_CART']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.productList[this.productListIndex].otherChildItemsBOList[this.productList[this.productListIndex].selectedItem.selectedVariantIndex].isAdded = true;
          this.productList[this.productListIndex].selectedItem = this.productList[this.productListIndex].otherChildItemsBOList[this.productList[this.productListIndex].selectedItem.selectedVariantIndex];
          this.dataService.appConstant.successMsg("Item added into cart....")
          this.productList[this.productListIndex].isLoading = false;
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      }, err => {
        this.dataService.throwError(err)
      })

    } else {
      sessionStorage.setItem('pageName', "ListPage")
      sessionStorage.setItem('productListIndex', i)
      sessionStorage.setItem('selectedVariantIndex', this.selectedVariantIndex)
      this.dataService.sendMessage("ProceedForLogin")
    }


  }
  public updateCartQuantity(i: any, type: any): void {
    if (this.user) {
      if (type == "increase") {
        this.cartList[i].quantity += 1;
      } else {
        this.cartList[i].quantity -= 1;
      }
      if (this.cartList[i].quantity < 1) {
        let cartlistobject = this.cartList[i];
        this.cartList.splice(i, 1)
        this.removeItemFromCart(cartlistobject);
      } else {
        this.dataService.putRequest(this.cartList[i], this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {

      this.proceedToAdd = this.cartList[i];
      let Qty = this.proceedToAdd.quantity;

      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.cartList[i].quantity = 1;
        this.cartList[i].isAdded = false;
        this.removeItemFromTempCart(this.proceedToAdd.itemUid);
      } else {
        this.cartList[i].quantity = Qty;
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === this.proceedToAdd.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === this.proceedToAdd.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }
      this.dataService.getCartList();
      this.tempCartTotalCalulation();
    }


  }







  //WITHOUT ITEM GROUP ITEM ADD TO CART
  public addToCartNonItemGroup(productListIndex: any): void {
    this.productListIndex = productListIndex;

    if (this.user) {
      this.productList[this.productListIndex].isLoading = true;
      this.proceedToAdd = this.productList[this.productListIndex];
      this.proceedToAdd.userUid = this.user.userUniqueId;
      this.proceedToAdd.quantity = 1;
      this.dataService.postRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['ADD_TO_CART']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.proceedToAdd = this.productList[this.productListIndex].isAdded = true;
          this.dataService.appConstant.successMsg("Item added into cart....")
          this.productList[this.productListIndex].isLoading = false;
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      }, err => {
        this.dataService.throwError(err)
      })

    } else {
      sessionStorage.setItem('pageName', "HomePage")
      sessionStorage.setItem('productListIndex', this.productListIndex)
      this.dataService.sendMessage("ProceedForLogin")
    }
  }




  public updateCartQuantityItemGroup(productListIndex: any, type: any): void {
    if (this.user) {
      this.productListIndex = productListIndex;
      this.proceedToAdd = this.productList[this.productListIndex];
      if (type == "increase") {
        this.proceedToAdd.quantity += 1;
      } else {
        this.proceedToAdd.quantity -= 1;
      }

      if (this.proceedToAdd.quantity < 1) {
        this.productList[this.productListIndex].quantity = 1;
        this.productList[this.productListIndex].isAdded = false;
        this.removeItemFromCartItemGroup(this.proceedToAdd.cartId);
      } else {
        this.dataService.putRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {

      this.proceedToAdd = this.cartList[productListIndex];
      let Qty = this.proceedToAdd.quantity;

      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.cartList[productListIndex].quantity = 1;
        this.cartList[productListIndex].isAdded = false;
        this.removeItemFromTempCart(this.proceedToAdd.itemUid);
      } else {
        this.cartList[productListIndex].quantity = Qty;
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === this.proceedToAdd.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === this.proceedToAdd.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }

      this.tempCartTotalCalulation();
    }

  }


  public removeItemFromCartItemGroup(carId: any): void {
    this.dataService.openDeleteRequest(this.dataService.appConstant.SERVER_URLS['EDIT_CART'] + carId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.productList[this.productListIndex].quantity = 1;
        this.productList[this.productListIndex].isAdded = false;
        this.dataService.getCartList();
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    });
  }


  ngOnDestroy() {
    this.orgnizationData.unsubscribe();
    // this.categoryloadedSub.unsubscribe();
  }


  redirectToSearch(searchProduct) {
    if (searchProduct && searchProduct.length > 2) {
      this.router.navigate(['/web/ag/products'], { queryParams: { tag: searchProduct } })
    }
  }

  // DOWNLOAD APP BUTTON
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        this.deferredPrompt = null;
      });
  }

  openStore() {
    if (this.storesList && this.storesList.length > 1) {
      $('#storeModel').modal('show');
    }
  }

  searchItems() {
    this.isSearchLoading = true;
    this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
    // + this.warehouseData.warehouseId
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS['SEARCH_ITEM_FROM_HOME'] + this.warehouseData.warehouseId).subscribe(result => {
      this.data = result;
      if (this.data.status === 200) {
        this.productSerachList = this.data.result;
        this.isSearchLoading = false;
        localStorage.setItem('websiteSearchProList', JSON.stringify(this.productSerachList));
      }
    })
  }

  getFooterData() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_FOOTER_DATA"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.footerData = this.data.result;
        console.log('this.footrData',this.footerData);
        
      }
    }, err => {
    })
  }
  showSearch(){
    this.searchProduct=null;
    this.showSearchIcon=!this.showSearchIcon;
  }

  routLink(item:any){
    if(item.linkType =='1'){
       this.router.navigate([item.linkValue]);
    }
    else if(item.linkType =='9'){
      window.open(item.linkValue, '_blank');
    }
  }

  getFAQ() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_FAQ"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        // this.faqList = this.data.result;
        if(this.data.result &&  this.data.result.length>0){
          this.data.result.forEach(element => {
           if(element.faqList && element.faqList.length>0){
            this.faqList.push(element);
           }
          });
        }
      
      }
    }, err => {
    })
  }
  public validatePattern(event): void {
    if (!/[0-9]/.test(String.fromCharCode(event.charCode))) {
      event.preventDefault();
    }
    if ((event.which != 46) && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  }

}








