<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
  integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw=="
  crossorigin="anonymous" referrerpolicy="no-referrer" />
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>


<body >
  <!-- Desktop View -->
  <div class="desktop-view" id="desktopnavebar">
    <div class="container-fluid navBarH" id="sticky-header"[ngStyle]="{'background': orgnizationData.isAddGradientNavBar && orgnizationData.navBarColor ? orgnizationData.navBarColor : '', 'important': 'true'}">
      <div class="row" >
        <div class="col-12 col-sm-1 col-md-1  col-lg-1 col-xl-1 col-xxl-1  logo cursorPointer" routerLink="">
          <div class="d-flex"  *ngIf="orgnizationData && orgnizationData.isShowLogoWithNameOnWebsite">
            <img class="review-image2 logo-new" *ngIf="orgnizationData.websiteLogoFilePath"
            src="https://ik.imagekit.io/fjimgkit//tr:w-250,h-150,cm-pad_resize/{{orgnizationData.websiteLogoFilePath}}"
            loading="lazy" onError="this.src='assets/img/errorLogo.png'">
            <div class="orgName1 font-color">{{orgnizationData.organizationName}}</div>
          </div>
          <div class="d-flex"  *ngIf="orgnizationData && !orgnizationData.isShowLogoWithNameOnWebsite && !orgnizationData.isShowLogoOnWebsite">
            <div class="orgName1 font-color">{{orgnizationData.organizationName}}</div>
          </div>
          <div class="d-flex"  *ngIf="orgnizationData && orgnizationData.isShowLogoOnWebsite">
            <img class="review-image2 logo-new" *ngIf="orgnizationData.websiteLogoFilePath"
            src="https://ik.imagekit.io/fjimgkit//tr:w-250,h-150,cm-pad_resize/{{orgnizationData.websiteLogoFilePath}}"
            loading="lazy" onError="this.src='assets/img/errorLogo.png'">
            <div *ngIf="!orgnizationData.websiteLogoFilePath" class="orgName1">{{orgnizationData.organizationName}}</div>
          </div>
        </div>

        <div *ngIf="showSearchBarInHeader"  class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-8 " >
          <div class="row" >
            <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 "></div>
            <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-6 d-flex">
            <input type="text" (change)="redirectToSearch(searchProduct)" class="form-control1 "
              placeholder="Search Brands, Products" [(ngModel)]="searchProduct" name="searchProduct">
            <div class="" type="button" routerLink="/web/ag/products" [queryParams]="{tag:searchProduct}">
              <span class="d-flex  btnpadding badge bg-dark text-white">
                <i class="fas fa-search  mx-1"></i>
                Search</span>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf="!showSearchBarInHeader" class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-8 d-flex">
          
          <nav   class="navbar-expand-md navbar-dark" *ngIf="!showSearchBarInHeader && menubarList && menubarList.length >0" >
            <div *ngIf="menubarList && menubarList.length >0" class="collapse navm navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ml-auto hnavBar">
                <li class="nav-item cursorPointer nav-li-m">
                  <ul class="navbar-nav  w-100 ">
                    <ng-container *ngFor="let menue of menubarList; let i = index">
                        <li class="dropdown" *ngIf="!menue.isLink">
                          <a class="nav-link dropdownMenu font-color  prevent-select" data-toggle="dropdown"
                            [ngClass]="{'dropdown-toggle': menubarList.length>0}">
                            <span class="dropDownMenuName nav-title">
                              {{menue.menuName}} </span></a>
                          <div *ngIf="menue.isBrand" class=" dropdown-menu  p-0 categoryDropdown animated fadeIn ">
                            <div class="dropdown-menu-inner p-0">
                                <div class="brandname1">
                                    <ul class="mainCatUL  ">
                                      <li *ngFor="let brand of menue.brandList" (mouseenter)="setindex(brand)"
                                        class="catBrdR catDropBrdBtm dropdownLiColor capitalize-first brandnamewidth" routerLink="/web/ag/products"
                                        data-toggle="tooltip" title="{{ brand.brandName }}"
                                        [queryParams]="{ brandName: brand.brandName, brandUid: brand.brandUid }">
                                        {{ brand.brandName }}
                                      </li>
                                    </ul>
                                      <ul class="mainCatUL  level2Back catDropBrdBtm ">
                                        <h6 routerLink="/web/ag/products" data-toggle="tooltip" title="{{brandobject.brandName}}"
                                          [queryParams]="{brandName:brandobject.brandName,brandUid:brandobject.brandUid }">
                                          <b class="level2Cat capitalize-first f-14 ">{{brandobject.brandName}}</b>
                                        </h6>
                                        <li *ngFor="let item of brandobject.ecommItemList" (mouseenter)="subCategoryIndex = i"
                                          class="level2CatName capitalize-first catBrdR catDropBrdBtm brandnamewidth dropdownLiColor"
                                          routerLink="web/product-detail" data-toggle="tooltip" title="{{item.itemName}}"
                                          [queryParams]="{itemUid:item.itemUid }">
                                          {{item.itemName}}</li>
                                      </ul>
                                  <!-- Add your level 2 content here -->
                                </div>
                            </div>
                          </div>
                          <div *ngIf="menue.isItem" class=" dropdown-menu    categoryDropdown animated fadeIn ">
                            <div class="dropdown-menu-inner">
                              <ul class="dropdownScroll">
                                <li *ngFor="let itemobj of menue.itemList"
                                  class="catBrdR capitalize-first catDropBrdBtm dropdownLiColor brandnamewidth" routerLink="web/product-detail"
                                  [queryParams]="{itemUid:itemobj.itemUid }">
                                  <span *ngIf="menue.item.showItemName">
                                    {{itemobj.itemName}}
                                  </span>
                                  <span *ngIf="!menue.item.showItemName">
                                    {{itemobj.itemDisplayName}}
                                  </span>
                                </li>
          
                              </ul>
                            </div>
                          </div>
                          <div *ngIf="menue.isCategory" class=" dropdown-menu category-menu categoryDropdown animated fadeIn ">
                            <div class="dropdown-menu-inner">
                              <ul class="dropdownScroll">
                                <li *ngFor="let cat of menue.catList"
                                  class="catBrdR capitalize-first catDropBrdBtm dropdownLiColor brandnamewidth"
                                  routerLink="/web/ag/products"
                                      data-toggle="tooltip" title="{{cat.name}}"
                                      [queryParams]="{brandName:cat.name,categoryUid:cat.categoryUid }"
                                  routerLink="/web/ag/products"
                                  data-toggle="tooltip" title="{{cat.name}}"
                                  [queryParams]="{category: cat.name, category_uid: cat.categoryUid}">{{cat.name}}</li>
          
                              </ul>
                            </div>
                          </div>
                          <div  *ngIf="menue.isOffer"  class=" dropdown-menu w-100 categoryDropdown animated fadeIn">
                            <div class="dropdown-menu-inner">
                              <ul class="dropdownScroll">
                                <li *ngFor="let discount of menue.discountList"
                                  class="catBrdR capitalize-first catDropBrdBtm dropdownLiColor brandnamewidth" routerLink="/web/ag/products"
                                  data-toggle="tooltip" title="{{discount.discountName}}"
                                  [queryParams]="{discountName:discount.discountName,discFilters:discount.discountUid }">
                                  {{discount.discountName}}</li>
          
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li class="dropdown" *ngIf="menue.isLink" (click)="routLink(menue)" >
                          <a  class="nav-link dropdownMenu font-color  prevent-select" data-toggle="dropdown">
                            <span class="dropDownMenuName nav-title">
                              {{menue.menuName}} </span></a>
                        </li>
                    </ng-container>
                  </ul>
      
                </li>
                
              </ul>
            </div>
          </nav>
        </div>
        <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-3">
          <div class=" d-flex justify-c-r">
            <div class="button-group cursorPointer">
              <span class="d-flex" *ngIf="!user || !user.userId" data-toggle="modal" data-target="#signInModal"
                (click)="openSignInUP('signIn')">
                <i class="fas fa-user-circle font-color"></i> 
                <!-- <b class="f-18 icon-heading mx-1 font-color"> Login</b> -->
              </span>
              <span class="d-flex" (click)="showSearch()" *ngIf="menubarList && menubarList.length >0">
              <i class="fas fa-search font-color"></i> 
              </span>
              <div class="st-search-bar st-hidden" *ngIf="showSearchIcon">
                <input type="text" (change)="redirectToSearch(searchProduct)" class="wizzy-search-input DesktopSearchBoxWizzy"
                placeholder="Search Brands, Products" [(ngModel)]="searchProduct" name="searchProduct">
                <!-- <input class="wizzy-search-input DesktopSearchBoxWizzy" type="text" name="q" value="" autocapitalize="off" autocomplete="off" autocorrect="off" placeholder="search"> -->
                <span (click)="showSearch()" class="input-close-btn" ></span>
              </div>
              <span *ngIf="user && user.userId">
                <!-- routerLink="/web/ag/profile"    -->
                <div class="dropdown">
                  <div class="dropbtn d-flex" data-toggle="dropdown">
                    <i class="fa fa-user-circle font-color"></i> 
                    <!-- <b class="f-18 icon-heading mx-1 font-color"> Account</b> -->
                  </div>
                  <div class="dropdown-menu w-100 mt-3">
                    <div class="dropdown-menu-inner">
                      <li routerLink="/web/ag/profile" class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth ">
                        <a></a>Profile
                      </li>
                      <li routerLink="/web/ag/profile/my-Address"
                        class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth">
                        <a></a>Address
                      </li>
                      <li routerLink="/web/ag/profile/my-Orders"
                        class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth">
                        <a href="#"></a>Order
                      </li>
                      <li (click)="signOut()" class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth">
                        <a href="#"></a>Sign Out
                      </li>
                    </div>
                  </div>
                </div>

              </span>

              <span data-toggle="modal" data-target="#cartModal">
                <span class="d-flex"><i class="fa-solid fa-bag-shopping font-color"></i>
                  <span class="cartcount" *ngIf="cartList">{{cartList.length}}</span>
                  <span class="cartcount" *ngIf="!cartList">0</span>
                  <!-- <b class="f-18 icon-heading font-color mx-1"> Cart</b> -->
                 </span>

                <!-- <strong class="f-14">Cart</strong> -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <nav class="navbar  navbar-expand-md navbar-dark bg-dark" *ngIf="menubarList && menubarList.length >0" >
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul *ngIf="menubarList && menubarList.length >0" class="navbar-nav ml-auto">
          <li class="nav-item cursorPointer">
            <ul class="navbar-nav  w-100  px-3">
              <ng-container *ngFor="let item of menubarList; let i = index">
              <li class=" dropdown "  >
              
                <a class="nav-link dropdownMenu   prevent-select" data-toggle="dropdown"
                  [ngClass]="{'dropdown-toggle': menubarList.length>0}"><span class="dropDownMenuName">
                    {{item.menuName}} </span></a>
                <div *ngIf="item.isBrand" class=" dropdown-menu  p-0 categoryDropdown animated fadeIn ">
                  <div class="dropdown-menu-inner p-0">
                      <div class="brandname1">
                          <ul class="mainCatUL  ">
                            <li *ngFor="let brand of item.brandList" (mouseenter)="setindex(brand)"
                              class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth" routerLink="/web/ag/products"
                              data-toggle="tooltip" title="{{ brand.brandName }}"
                              [queryParams]="{ brandName: brand.brandName, brandUid: brand.brandUid }">
                              {{ brand.brandName }}
                            </li>
                          </ul>
                            <ul class="mainCatUL  level2Back catDropBrdBtm ">
                              <h6 routerLink="/web/ag/products" data-toggle="tooltip" title="{{brandobject.brandName}}"
                                [queryParams]="{brandName:brandobject.brandName,brandUid:brandobject.brandUid }">
                                <b class="level2Cat f-14 ">{{brandobject.brandName}}</b>
                              </h6>
                              <li *ngFor="let item of brandobject.ecommItemList" (mouseenter)="subCategoryIndex = i"
                                class="level2CatName catBrdR catDropBrdBtm brandnamewidth dropdownLiColor"
                                routerLink="web/product-detail" data-toggle="tooltip" title="{{item.itemName}}"
                                [queryParams]="{itemUid:item.itemUid }">
                                {{item.itemName}}</li>
                            </ul>
                      </div>
                  </div>
                </div>
                <div *ngIf="item.isItem" class=" dropdown-menu    categoryDropdown animated fadeIn ">
                  <div class="dropdown-menu-inner">
                    <ul class="dropdownScroll">
                      <li *ngFor="let item of item.itemList"
                        class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth" routerLink="web/product-detail"
                        [queryParams]="{itemUid:item.itemUid }">{{item.itemDisplayName}}</li>

                    </ul>
                  </div>
                </div>
                <div *ngIf="item.isCategory" class=" dropdown-menu category-menu categoryDropdown animated fadeIn ">
                  <div class="dropdown-menu-inner">
                    <ul class="dropdownScroll">
                      <li *ngFor="let cat of item.catList"
                        class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth"
                        routerLink="/web/ag/products"
                            data-toggle="tooltip" title="{{cat.name}}"
                            [queryParams]="{brandName:cat.name,categoryUid:cat.categoryUid }"
                        routerLink="/web/ag/products"
                        data-toggle="tooltip" title="{{cat.name}}"
                        [queryParams]="{category: cat.name, category_uid: cat.categoryUid}">{{cat.name}}</li>

                    </ul>
                  </div>
                </div>
                <div  *ngIf="item.isOffer"  class=" dropdown-menu w-100 categoryDropdown animated fadeIn">
                  <div class="dropdown-menu-inner">
                    <ul class="dropdownScroll">
                      <li *ngFor="let discount of item.discountList"
                        class="catBrdR catDropBrdBtm dropdownLiColor brandnamewidth" routerLink="/web/ag/products"
                        data-toggle="tooltip" title="{{discount.discountName}}"
                        [queryParams]="{discountName:discount.discountName,discFilters:discount.discountUid }">
                        {{discount.discountName}}</li>

                    </ul>
                  </div>
                </div>
              </li>
              </ng-container>
            </ul>

          </li>
          
        </ul>
      </div>
    </nav> -->
  </div>

  <!--Navbar-->
  <section id="mobileSideBarSection">
    <div class="modal left fade" id="mobileSideBar" tabindex="-1" role="dialog" aria-labelledby="mobileSideBarList"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="closeSidebar" data-dismiss="modal">
          <i class="icofont-close-squared"></i>
        </div>
        <div class="modal-content">
          <div class="modal-header primaryGradient">
            <div  *ngIf="orgnizationData && orgnizationData.isShowLogoWithNameOnWebsite">
              <!-- <span class="orgName"> {{orgnizationData.organizationName}} </span> -->
              <img class="review-image-mobile"
            src="{{dataService.appConstant.IMAGE_URL}}{{orgnizationData.filePath}}"
            loading="lazy" onError="this.src='assets/img/errorLogo.png'">
            </div>
            
          </div>
          <div class="modal-body">

            <div class="row mSideBarSignRow ">
              <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 tCenter pr-0" data-dismiss="modal"
                *ngIf="!user  || !user.userId" (click)="openSignInModal('signIn')">
                <div class="but-color font-color rectangleBtn2 mrgAuto" style="margin-right: 4px;">SIGN IN</div>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 tCenter pl-0" data-dismiss="modal"
                *ngIf="!user || !user.userId" (click)="openSignInModal('createAccount')">
                <div class="but-color font-color rectangleBtn2 mrgAuto" style="margin-left: 4px;">SIGN UP</div>
              </div>
            </div>


            <div class="mSideBarShopByCat" routerLink="/web/ag/categories" data-dismiss="modal">SHOP BY CATEGORY</div>

            <ul>
              <li routerLink="" data-dismiss="modal" (click)="homeReload()"> <i class="icofont-home"></i>
                <span>Home</span>
              </li>
              <hr>
              <li routerLink="/web/ag/profile" data-dismiss="modal" mt-3> <i class="fa-solid fa-user"></i>
                <span>My Profile</span>
              </li>
              <li routerLink="/web/ag/profile/my-Address" data-dismiss="modal"> <i class="fa-solid fa-location-dot"></i>
                <span>My Address</span>
              </li>
              <li routerLink="/web/ag/profile/my-Orders" data-dismiss="modal"><i
                  class="icofont-list account-list-icons"></i>
                <span>My Order</span>
              </li>
              <!-- <li routerLink="/web/ag/profile/ChangePassword" data-dismiss="modal"><i class="fa-solid fa-user"></i>
                <span>Change Password</span>
              </li> -->
            </ul>

            <hr>

            <ul>
              <!-- <li routerLink="/web/store_location" data-dismiss="modal"> <i class="fa fa-circle" aria-hidden="true"></i>
                <span>Store location</span>
              </li> -->
              <li *ngIf="faqList && faqList.length>0" (click)="gotoFAQ()" data-dismiss="modal">  <i class="fa fa-circle"
                aria-hidden="true"></i> <span> FAQ </span></li>
              <li *ngIf="footerData.isShowAboutUs" (click)="goToPrivacyPolicy('About_Us')" data-dismiss="modal"><i class="fa fa-circle"
                aria-hidden="true"></i> <span>About Us</span></li>
              <li *ngIf="footerData.isShowPrivacyPolicy" (click)="goToPrivacyPolicy('Privacy_Policy')" data-dismiss="modal"><i class="fa fa-circle"
                  aria-hidden="true"></i> <span>Privacy policy</span></li>
               <li *ngIf="footerData.isShowPrivacyPolicy" (click)="goToPrivacyPolicy('Shipping_Policy')" data-dismiss="modal"><i class="fa fa-circle"
                    aria-hidden="true"></i> <span>Shipping policy</span></li>
              <li *ngIf="footerData.isShowTermsConditions" (click)="goToPrivacyPolicy('Terms_and_Conditions')" data-dismiss="modal"> <i class="fa fa-circle"
                  aria-hidden="true"></i> <span>Term and condition</span></li>
               <li *ngIf="footerData.isShowReturnPolicy" (click)="goToPrivacyPolicy('Return_Policy')" data-dismiss="modal"> <i class="fa fa-circle"
                    aria-hidden="true"></i> <span>Refund and Return Policy</span></li>
              <div class="text">
                <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
                  <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
                </a>
                <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
                  <img class="playstore-icon" src="assets/img/AppStore.png" alt="Logo">
                </a>
              </div>
              <!-- <div>
                <a href="https://apnagrahak.com/">
                    <img class="poweredby" src="assets/img/Powered By AG3.png" alt="Logo">
                </a>
              </div> -->
            <!-- <div>
              <a href="https://sheleadsindia.in/">
                  <img class="poweredby" src="assets/img/shelead100-2.png" alt="Logo">
              </a>
            </div> -->
            <div>
              <img *ngIf="footerData.poweredByFilePath" class="review-image" src="{{dataService.appConstant.IMAGE_URL}}{{footerData.poweredByFilePath}}">
            </div>
            <div class="mt-3">
                 <img *ngIf="footerData.initiativeByFilePath" class="review-image" src="{{dataService.appConstant.IMAGE_URL}}{{footerData.initiativeByFilePath}}">
            </div>
            </ul>
          </div>
          <div class="modal-footer" (click)="signOut()" *ngIf="user && user.userId" data-dismiss="modal">
            <div class="but-color font-color rectangleBtn7  mrgAuto"><b>SIGN OUT</b></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- CART MODAL -->
  <div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="cartModalPop" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="closeButton" data-dismiss="modal">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="cartList?.length > 0">
            <div class="itemList" *ngFor="let item of cartList;let i=index">
              <div class="cartItemImage" [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                (click)="itemDetail(item)">
                <img class="animated fadeIn" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                  loading="lazy" onError="this.src='assets/img/errorLogo.png'">
              </div>
              <div class="row itemRow">
                <div class="col-md-5">
                  <div class="itemName" (click)="itemDetail(item)" [tooltip]="item.displayName" placement="top"
                    show-delay="500">
                    <div>{{item.itemName}}</div>
                    <div>
                      <span style="color: green;font-weight: bold;font-size: 12px;"
                        *ngIf="!item.selectedItem && item.discountName">
                        <span class="fa-stack fa-sm" style="font-size: 9px;">
                          <i class="fa fa-certificate fa-stack-2x"></i>
                          <i class="fa fa-tag fa-stack-1x fa-inverse"></i>
                        </span>{{item.discountName}}</span>
                    </div>
                    <div>
                      <span *ngIf="!item.isBuyXGetYDiscount"
                        class="amount comboAmountF"><b>{{currencySymbol}}{{item.finalPrice}}</b></span>
                      <span *ngIf="item.isBuyXGetYDiscount"
                        class="amount comboAmountF"><b>{{currencySymbol}}{{item.withoutDiscAmount}}</b></span>
                      <span class="mrpprice pl-3" *ngIf="!item.discountName && item.isMrpDiscount == true">MRP </span>
                      <span class="sale-price" *ngIf="!item.discountName && item.isMrpDiscount == true">
                        <span>{{currencySymbol}}</span>
                        <span><del>{{item.mrp}}</del></span>
                      </span>
                      <span class="sale-price pl-1"
                        *ngIf="item.discountName && !item.isBuyXGetYDiscount && item.isMrpDiscount == false">
                        <span>{{currencySymbol}}</span>
                        <span><del>{{item.withoutDiscAmount}}</del></span>
                      </span>
                    </div>
                    <!-- <div *ngIf="!item.isStockAvailable">
                    <label class="outofstock">Out Of Stock</label>
                  </div> -->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="flex contentJustify" *ngIf="item.isStockAvailable">
                    <div class="but-color font-color incrementDecreament" (click)="updateCartQuantity(i,'decrease')"><i
                        class="fa fa-angle-left" aria-hidden="true"></i></div>
                    <div class="itemCount"><b>{{item.quantity}}</b></div>
                    <div class="but-color font-color incrementDecreament" (click)="updateCartQuantity(i,'increase')"><i
                        class="fa fa-angle-right" aria-hidden="true"></i></div>
                  </div>
                  <div class="colrgreenFreeItem" *ngIf="item.totalFreeQty">
                    {{item.totalFreeQty}} FREE</div>
                  <div *ngIf="!item.isStockAvailable">
                    <label class="outofstock">Out Of Stock</label>
                  </div>
                </div>
                <div class="col-md-3 tCenter">
                  <div><b> {{currencySymbol}} {{item.finalPrice * item.quantity | number : fractionSize}}</b></div>
                  <div class="savedAmount" *ngIf="item.salePrice - (item.finalPrice * item.quantity) > 0">
                    Saved
                    <span *ngIf="item.salePrice - (item.finalPrice * item.quantity) > 0">{{currencySymbol}}
                      {{item.salePrice - (item.finalPrice * item.quantity) | number : fractionSize}}</span>
                    <span *ngIf="item.salePrice - (item.finalPrice * item.quantity) <= 0">{{currencySymbol}} 0</span>
                  </div>
                </div>
                <div class="col-md-1" (click)="removeItemFromCart(item)">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="noProductFoundCartPop" *ngIf="cartList?.length <= 0">
            <img src="assets/img/no-product-found1.png">
            <div class="subMsg">No item in your cart.....</div>
          </div>

        </div>
        <div class="modal-footer" *ngIf="cartList?.length > 0">
          <div class="row footerRow">
            <div class="col-md-2">
              <!-- <div class="themeColor savedAmount">You Saved</div> -->
              <div class="itemTotal"><b>TOTAL</b></div>
            </div>
            <div class="col-md-4">
              <!-- <div class="themeColor savedAmount">{{currencySymbol}} {{cartObject.savedAmount}}</div> -->
              <!-- <div class="themeColor savedAmount">{{currencySymbol}} 0</div> -->
              <div class="itemTotal"><b>{{currencySymbol}} {{cartObject.totalAmount | number:'1.2-2' }}</b></div>
            </div>
            <div class="col-md-6">
              <button type="button" class="btn but-color font-color btn-primary rectangleBtn1 appButton checkoutBTn"
                (click)="goToCheckout()" [disabled]="checkbtnDis">View Cart & Checkout &nbsp;&nbsp; <i
                  class="fa fa-angle-right" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-----SING IN / SING UP FORM-->
  <div class="modal fade commonPopUP" id="signInModal" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="signInModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header secGradient blackClr ">
          <h5 class="modal-title font-color" id="signInModalLabel"
            *ngIf="signUpInPopup == 'signIn' && fromSignup == false || signUpInPopup =='signInOptions'">Sign In
          </h5>
          <h5 class="modal-title font-color" id="signInModalLabel" *ngIf="signUpInPopup == 'createAccount'">Create Account</h5>
          <h5 class="modal-title font-color btn-color" id="signInModalLabel" *ngIf="signUpInPopup == 'singUP' && fromSignup == false">Sign
            Up
          </h5>
          <h5 class="modal-title font-color btn-color" id="signInModalLabel" *ngIf="signUpInPopup == 'signIn' && fromSignup == true">Sign Up
          </h5>
          <button type="button" class="close blackClr" data-dismiss="modal" aria-label="Close"
            *ngIf="isUserAllInfoAvailable">
            <span aria-hidden="true" class="blackClr">&times;</span>
          </button>
        </div>
        <div class="popupToaster  animated fadeInDown" *ngIf="isShowActionToast"
          [ngClass]="{'warning':isShowWarning,'success':isShowSuccess,'error':isShowError}">{{actionMessage}}</div>
        <div class="modal-body">

          <!------LOGIN------>
          <div *ngIf="signUpInPopup == 'signIn'">
            <div class="formContainer" *ngIf="loginTab == 'withEmailPassword'">
              <form name="form" #appForm="ngForm" [formGroup]="signInForm" (ngSubmit)="loginWithPassword()">
                <div class="form-group">
                  <label for="exampleInputEmail1">Email/Mobile no.<span class="redCrl">*</span></label>
                  <input class="form-control" type="email" placeholder=" Mobile / Email ID"
                  name="username"  [(ngModel)]="formData.username" formControlName="email" required>
                  <span class="text-danger"
                    *ngIf="signInForm.controls['email'].hasError('required') && (signInForm.controls['email'].dirty || signInForm.controls['email'].touched)">This
                    field is required</span>
                  <span class="text-danger"
                    *ngIf="signInForm.controls['email'].hasError('pattern') && (signInForm.controls['email'].dirty || signInForm.controls['email'].touched)">Please
                    Enter valid email address or phone number</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password<span class="redCrl">*</span></label>
                  <div class="positionRelative">
                    <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Password"
                      [(ngModel)]="formData.password" name="password" formControlName="password">
                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                      *ngIf="passIcon == 'show'"></i>
                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                      *ngIf="passIcon == 'hide'"></i>
                  </div>
                  <span class="text-danger"
                    *ngIf="signInForm.controls['password'].hasError('required') && (signInForm.controls['password'].dirty || signInForm.controls['password'].touched)">Password
                    required</span>
                  <div class="forGotPass" (click)="openForgotPassword()">Forgot Password?</div>
                </div>
                <br>
                <div class="tCenter">
                  <button type="submit" class="btn btn-primary secGradient blackClr ectangleBtn appButton submitBtn"
                    [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                    <span class="button__text">LOGIN</span>
                  </button>
                </div>
              </form>
            </div>

            <div class="formContainer" *ngIf="loginTab == 'withPassword'">

              <div *ngIf="loginWithOtpTap == 'enterNo' ">
                <form name="form" #appForm="ngForm" [formGroup]="loginWithOtpForm" (ngSubmit)="loginWithOtp(appForm)">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Enter Mobile No<span class="redCrl">*</span></label>
                    <input class="form-control" type="text" placeholder="Mobile" [(ngModel)]="resendOtp.username"
                   name="mobile"   formControlName="mobile"  (keypress)="validatePattern($event)" minlength=10
                      maxlength=10 required>
                    <span class="text-danger"
                      *ngIf="loginWithOtpForm.controls['mobile'].hasError('required') && (loginWithOtpForm.controls['mobile'].dirty || loginWithOtpForm.controls['mobile'].touched)">This
                      field is required</span>
                    <span class="text-danger"
                      *ngIf="loginWithOtpForm.controls['mobile'].hasError('pattern') && (loginWithOtpForm.controls['mobile'].dirty || loginWithOtpForm.controls['mobile'].touched)">Please
                      Enter valid mobile number</span>
                  </div>
                  <br>
                  <div class="tCenter">
                    <button type="submit" class="btn but-color font-color btn-primary blackClr rectangleBtn4 appButton submitBtn"
                      [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}"><span
                        class="font-color" routerLink="/">SUBMIT</span></button>
                  </div>
                </form>
              </div>

              <div *ngIf="loginWithOtpTap == 'enterOTP' ">
                <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && loginWithOtpVerify(appForm)">
                  <div class="form-group">
                    <label for="exampleInputPassword1">OTP<span class="redCrl">*</span></label>
                    <div class="positionRelative">
                      <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter OTP"
                        [(ngModel)]="resendOtp.otp" name="otp" #otp="ngModel" required>
                      <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                        *ngIf="passIcon == 'show'"></i>
                      <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                        *ngIf="passIcon == 'hide'"></i>
                    </div>
                    <div class="timeLeft">Time left:{{timeLeft}}</div>
                  </div>
                  <br>
                  <div class="tCenter">
                    <button type="submit" class="btn but-color font-color blackClr btn-primary rectangleBtn4 appButton submitBtn"
                      [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                      <span class="font-color">SUBMIT OTP</span></button>
                    <div class="createAccount">Didn't get OTP? <span class="underLine"
                        (click)="loginWithOtp()">Resend</span></div>
                  </div>
                </form>
              </div>
              <div *ngIf="loginWithOtpTap == 'otpUserInfo' && !isUserAllInfoAvailable">
                <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && updateProfile(appForm)">
                  <div class="form-group">
                    <label for="exampleInputEmail1">First Name<span class="redCrl">*</span></label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="Enter your name" [(ngModel)]="user.firstName" name="otpfirstName"
                      #otpfirstName="ngModel" required>
                    <span class="text-danger"
                      *ngIf="(otpfirstName.touched || appForm.submitted) && otpfirstName.errors?.required">Name is
                      required</span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Last Name<span class="redCrl">*</span></label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="Enter your name" [(ngModel)]="user.lastName" name="lastName" #lastName="ngModel"
                      required>
                    <span class="text-danger"
                      *ngIf="(lastName.touched || appForm.submitted) && lastName.errors?.required">Name is
                      required</span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email <span class="redCrl">*</span></label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="Enter email" [(ngModel)]="user.email" name="optemail" #optemail="ngModel" email required>
                      <span class="text-danger"
                      *ngIf="(optemail.touched || appForm.submitted) && optemail.errors?.required">Email is
                      required</span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Mobile<span class="redCrl">*</span></label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="Enter your mobile no" [(ngModel)]="user.mobile" name="optmobile" #optmobile="ngModel"
                      required (keypress)="validatePattern($event)" minlength=10 maxlength=10>
                    <span class="text-danger"
                      *ngIf="(optmobile.touched || appForm.submitted) && optmobile.errors?.required">Mobile no is
                      required</span>
                    <span class="text-danger"
                      *ngIf="(optmobile.touched || appForm.submitted) && optmobile.errors?.minlength">Mobile no must be
                      10
                      digit</span>
                  </div>
                  <br>
                  <div class="tCenter">
                    <button type="submit" class="btn but-color font-color blackClr btn-primary rectangleBtn4 appButton submitBtn"
                      [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                      <span class="button__text">SAVE</span></button>
                  </div>
                </form>
              </div>
            </div>

            <div class="signWithSection" *ngIf="loginTab == 'withEmailPassword'">
              <hr>
              <div class="signWithlabel"> Sign In With</div>
            </div>
            <br>

            <div class="row">
              <div class="col-4">

              </div>

              <div class="col-4 " *ngIf="loginTab == 'withOtp'" (click)="openLoginPop('withPassword')">
                <div class="signupwith fbContainer fbSection floatR">
                  <div class="themeColor"><i class="fa fa-lock fb" aria-hidden="true"></i></div>
                  <div class="secondThemeColor signwithLabel signwithLabelFb">Password</div>
                </div>
              </div>


            </div>
            <br>
            <div class="createAccount" (click)="openSignUP('createAccount')">New to
              <span
                *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>?
              <span class="underLine">Create
                Account</span>
            </div>
          </div>

          <!------CREATE ACCOUNT------>
          <div *ngIf="signUpInPopup == 'createAccount'">
            <br>
            <div class="tCenter">
              <div class="but-color font-color rectangleBtn createAccBtn" (click)="proccedWithOtp()">
                <div class="createAccountIcon" style="font-size: 23px;">
                  <img src="assets/img/Mobile.png" alt="Icon" style="width: 13px;">
                </div>
                <div class="createAccLabel font-color">
                  &nbsp;&nbsp; &nbsp; Sign up with OTP
                </div>
              </div>


              <!-- <div class="signWithSection">
                <hr>
                <div class="signWithlabel" style="left: 47%;">OR</div>
              </div> -->
              <br>

              <!-- <div class="primaryGradient rectangleBtn createAccBtn blackClr" (click)="openSignUP('singUP')">
                <div class="createAccountIcon">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
                <div class="createAccLabel">
                  &nbsp;&nbsp; &nbsp; Sign up with email
                </div>
              </div> -->
            </div>

            <div class="trmConditionNote">
              By signing up, you are agree <span
                *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>
              <span class="cursorPointer" (click)="goToPrivacyPolicy('Terms_and_Conditions')">Terms and
                conditions</span>
              or <span class="cursorPointer" (click)="goToPrivacyPolicy('Privacy_Policy')">Privacy Policy</span>
            </div>


            <br>
            <div class="createAccount" (click)="openSignUP('signIn')">Member of <span
                *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>?
              <span class="underLine">Login</span>
            </div>
          </div>

          <div *ngIf="signUpInPopup == 'signInOptions'">
            <br>
            <div class="tCenter">
              <div class="secGradient rectangleBtn createAccBtn" (click)="openSignInWithOtp()">
                <div class="createAccountIcon" style="font-size: 23px;">
                  <img src="assets/img/Mobile.png" alt="Icon" style="width: 13px;">
                </div>
                <div class="createAccLabel">
                  &nbsp;&nbsp; &nbsp; Sign In with OTP
                </div>
              </div>


              <div class="signWithSection">
                <hr>
                <div class="signWithlabel" style="left: 47%;">OR</div>
              </div>
              <br>

              <div class="but-color font-color rectangleBtn createAccBtn" (click)="openSignInWithEmail()">
                <div class="createAccountIcon">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
                <div class="createAccLabel">
                  &nbsp;&nbsp; &nbsp; Sign In with email
                </div>
              </div>
            </div>
          </div>

          <!------SIGN UP------>
          <div *ngIf="signUpInPopup == 'singUP'">
            <div class="formContainer">
              <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && signUp()" novalidate>
                <div class="form-group ">
                  <label for="exampleInputEmail1">Your Name<span class="redCrl">*</span></label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter your name" [(ngModel)]="formData.name" name="firstName" #firstName="ngModel"
                    required>
                  <span class="text-danger"
                    *ngIf="(firstName.touched || appForm.submitted) && firstName.errors?.required">Name is
                    required</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email<span class="redCrl">*</span></label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter email" [(ngModel)]="formData.email" name="email" #email="ngModel" required email>
                  <span class="text-danger" *ngIf="(email.touched || appForm.submitted) && email.errors?.required">
                    Email
                    is required</span>
                  <span class="text-danger" *ngIf="email.touched && email.errors?.email">Enter a valid email
                    address</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Mobile<span class="redCrl">*</span></label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter your mobile no" [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel"
                    required (keypress)="validatePattern($event)" minlength=10 maxlength=10>
                  <span class="text-danger"
                    *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">Mobile no is
                    required</span>
                  <span class="text-danger"
                    *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.minlength">Mobile no must be 10
                    digit</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password<span class="redCrl">*</span></label>
                  <div class="positionRelative">
                    <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter password"
                      [(ngModel)]="formData.password" name="password" #password="ngModel" required>
                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                      *ngIf="passIcon == 'show'"></i>
                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                      *ngIf="passIcon == 'hide'"></i>
                  </div>
                  <span class="text-danger"
                    *ngIf="(password.touched || appForm.submitted) && password.errors?.required">Password is
                    required</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Confirm Password <span class="redCrl">*</span> </label>
                  <div class="positionRelative">
                    <input [type]="pass1" class="form-control" id="exampleInputPassword1"
                      placeholder="Enter password again" [(ngModel)]="formData.confirmPass" name="Confirm"
                      #Confirm="ngModel" required>
                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                      *ngIf="passIcon1 == 'show'"></i>
                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                      *ngIf="passIcon1 == 'hide'"></i>
                  </div>
                  <span class="text-danger"
                    *ngIf="formData.confirmPass && formData.password && (formData.confirmPass != formData.password)">
                    Password and confirm password must be same
                  </span>
                  <span class="text-danger"
                    *ngIf="(Confirm.touched || appForm.submitted) && Confirm.errors?.required">Confirm Password is
                    required</span>
                </div>
                <br>
                <div class="tCenter">
                  <button type="submit" class="btn btn-primary but-color font-color blackClr rectangleBtn4 appButton submitBtn"
                    [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}"><span
                      class="button__text">SUBMIT</span></button>
                </div>
              </form>
            </div>
            <br>
            <div class="createAccount" (click)="openSignUP('signIn')">Member of <span
                *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>?
              <span class="underLine">Login</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-----FORGOT PASSWORD-->
  <div class="modal fade commonPopUP" id="forgotPassword" tabindex="-1" role="dialog" aria-labelledby="signInModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-header secGradient  blackClr ">
          <h5 class="modal-title " id="signInModalLabel">Forgot password</h5>
          <button type="button" class="close  blackClr" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class=" blackClr">&times;</span>
          </button>
        </div>

        <div class="popupToaster  animated fadeInDown" *ngIf="isShowActionToast"
          [ngClass]="{'warning':isShowWarning,'success':isShowSuccess,'error':isShowError}">{{actionMessage}}</div>

        <div class="modal-body" *ngIf="tab == 'enterno'">
          <div class="formContainer">
            <form name="form" #appForm="ngForm" [formGroup]="forgotPassForm" (ngSubmit)="sendOTP(appForm)">
              <div class="form-group">
                <label for="exampleInputEmail1">Email<span class="redCrl">*</span></label>
                <input class="form-control" type="email" placeholder="Email ID"
                name="email" [(ngModel)]="resendOtp.username"
                  formControlName="email" required>
                <span class="text-danger"
                  *ngIf="forgotPassForm.controls['email'].hasError('required') && (forgotPassForm.controls['email'].dirty || forgotPassForm.controls['email'].touched)">This
                  field is required</span>
                <span class="text-danger"
                  *ngIf="forgotPassForm.controls['email'].hasError('pattern') && (forgotPassForm.controls['email'].dirty || forgotPassForm.controls['email'].touched)">Please
                  Enter valid email address or phone number</span>
              </div>
              <br>
              <div class="tCenter">
                <button type="submit" class="btn but-color font-color btn-primary blackClr rectangleBtn4 appButton submitBtn"
                  [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">SEND OTP</span></button>
              </div>
              <br>
            </form>
          </div>
        </div>

        <div class="modal-body" *ngIf="tab == 'otp'">
          <div class="formContainer">
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && verifyOTP(appForm)">
              <div class="form-group" *ngIf="isLoginOtpRequested">
                <label for="exampleInputPassword1">OTP<span class="redCrl">*</span></label>
                <div class="positionRelative">
                  <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter otp"
                    [(ngModel)]="resendOtp.otp" name="otp" #otp="ngModel" required>
                  <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon1 == 'show'"></i>
                  <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon1 == 'hide'"></i>
                </div>
                <span class="text-danger" *ngIf="(otp.touched || appForm.submitted) && otp.errors?.required">Please
                  enter
                  otp</span>
                <div class="timeLeft">Time left:{{timeLeft}}</div>
              </div>
              <br>
              <div class="tCenter">
                <button type="submit" class="btn but-color font-color  btn-primary blackClr rectangleBtn4 appButton submitBtn"
                  [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">VERIFY OTP</span></button>
                <div class="createAccount" *ngIf="isLoginOtpRequested" (click)="resendOTP()">Didn't get otp? <span
                    class="underLine">Resend</span></div>
              </div>
              <br>
            </form>
          </div>
        </div>

        <div class="modal-body" *ngIf="tab == 'changePass'">
          <div class="formContainer">
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && changePass(appForm)">
              <div class="form-group">
                <label for="exampleInputPassword1">New Password<span class="redCrl">*</span></label>
                <div class="positionRelative">
                  <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter password"
                    [(ngModel)]="resendOtp.newPassword" name="password" #password="ngModel" required>
                  <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon == 'show'"></i>
                  <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon == 'hide'"></i>
                </div>
                <span class="text-danger"
                  *ngIf="(password.touched || appForm.submitted) && password.errors?.required">New
                  Password is required</span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Confirm Password <span class="redCrl">*</span> </label>
                <div class="positionRelative">
                  <input [type]="pass1" class="form-control" id="exampleInputPassword1"
                    placeholder="Enter password again" [(ngModel)]="resendOtp.confirmPass" name="Confirm"
                    #Confirm="ngModel" required>
                  <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                    *ngIf="passIcon1 == 'show'"></i>
                  <i class="fa fa-eye passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                    *ngIf="passIcon1 == 'hide'"></i>
                </div>
                <span class="text-danger"
                  *ngIf="formData.confirmPass && formData.password && (formData.confirmPass != formData.newPassword)">
                  Password and confirm password must be same
                </span>
                <span class="text-danger"
                  *ngIf="(Confirm.touched || appForm.submitted) && Confirm.errors?.required">Confirm Password is
                  required</span>
              </div>
              <br>
              <div class="tCenter">
                <button type="submit" class="btn but-color font-color btn-primary blackClr rectangleBtn4 appButton submitBtn"
                  [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}"><span
                    class="button__text">SUBMIT</span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sign out -->
  <div class="modal fade" id="singOutMessageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p class="msgtitle">Are you sure you want to sign out?</p>
          <p></p>
          <div class="btns d-flex  justify-content-center">
            <button data-dismiss="modal" aria-label="Close" type="button"
              class="btn btn-secondary  blackClr rectangleBtn3 appButton submitBtn"><span
                class="font-color">No</span></button>&nbsp; &nbsp; &nbsp;

            <button (click)="signOutConfim()" type="submit"
              class="btn btn-primary but-color  rectangleBtn3 appButton submitBtn"
              [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}" routerLink="/"><span
                class="font-color" routerLink="/">Yes</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- warehouse popup -->
  <section>
    <div class="modal fade commonPopUP" id="storeModel" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="signInModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header secGradient blackClr ">
            <h5 class="modal-title " id="signInModalLabel">Select a store nearest to your delivery location.</h5>
          </div>
          <div class="modal-body">
            <div class="formContainer">
              <div id="storeCards">
                <div class="row storeScroll">
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngFor="let store of storesList;let i=index">
                    <div class="wrapper" (click)="selectWarehouse(store)"
                      [ngClass]="{'activeStore':warehouseData.warehouseId == store.warehouseId}">
                      <div for="option-1" class="storeLabel"
                        [ngClass]="{'activeStore':warehouseData.warehouseId == store.warehouseId}">
                        <span>{{store.wareHouseName}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</body>
