import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[noWhitespace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NoWhitespaceValidatorDirective, multi: true }]
})
export class NoWhitespaceValidatorDirective implements Validator {
  validate(control: FormControl): ValidationErrors | null {
    if (control.value && typeof control.value === 'string') {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { 'whitespace': true } : null;
    }
    return null; // Handle other types or undefined values
  
    // const isWhitespace = (control.value || '').trim().length === 0;
    // return isWhitespace ? { 'whitespace': true } : null;
  }
}