import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommanservicesService } from 'src/app/services/commanservices.service';
declare var $: any;


@Component({
  selector: 'app-unsubscribe-newsletter',
  templateUrl: './unsubscribe-newsletter.component.html',
  styleUrls: ['./unsubscribe-newsletter.component.scss']
})
export class UnsubscribeNewsletterComponent implements OnInit {


  public reasoneList: any = [
    {
      reason: "I no longer want to receive these emails."
    },
    {
      reason: "I never signed up for this mailing list"
    },
    {
      reason: "The emails are inappropriate."
    },
    {
      reason: "The emails are spam and should be reported"
    },
    {
      reason: "Other(fill in below)"
    }
  ]

  public formData: any = {};
  public isButtonDisabled: any;
  public data: any;
  notificationSettingList: any = [];
  public oneNotificationIsFalse: boolean = true;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public dataService: CommanservicesService) {
    this.route.params.subscribe(params => {
      if (params.tab) {
        this.formData.companyUid = atob(params.tab)
      }
    });

    this.formData.comment = this.reasoneList[0].reason
  }

  ngOnInit(): void {
    this.getAllNotificationSettings()
  }


  cancelSubscription() {
    if (this.formData.comment == 'Other(fill in below)') {
      this.formData.comment = this.formData.customReason;
    }

    this.isButtonDisabled = true;
    this.dataService.postRequest(this.formData, this.dataService.appConstant.SERVER_URLS['CANCLE_NEWS_LETTER']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {

        this.isButtonDisabled = false;
        $('#unsubscribed').modal('show');
        setTimeout(() => {
          $('#unsubscribed').modal('hide');
          this.router.navigate(['']);
        }, 1000);
      } else {
        this.isButtonDisabled = false;
      }
    }, err => {
      this.isButtonDisabled = false;
    })


  }

  changeAllNotification(event) {
    if (event == true) {
      event = false;
      this.oneNotificationIsFalse = false;
    } else {
      event = true;
      this.oneNotificationIsFalse = true;
    }
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["CHANGE_ALL_NOTIFICATION_SETTINGS"] + this.formData.companyUid + '/' + event).subscribe(result => {
      this.data = result;
      if (this.data.status === 200) {
        this.getAllNotificationSettings();
        this.dataService.appConstant.presentToast(this.data.message);
      } else {
      }
    })
  }

  getAllNotificationSettings() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_NOTIFICATION_SETTINGS"] + this.formData.companyUid).subscribe(result => {
      this.data = result;
      if (this.data.status === 200) {
        this.notificationSettingList = this.data.result;
        let array: any = [];
        this.notificationSettingList.forEach(element => {
          if (element.settingList) {
            element.settingList.forEach(element1 => {
              array.push(element1.status)
            });
          }
        });

        if (array.includes(false)) {
          this.oneNotificationIsFalse = false;
        } else if (array.every(v => v === true)) {
          this.oneNotificationIsFalse = true;
        }

      } else {
      }
    })
  }

  changeNotificationSettingById(userNotificationSettingId) {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["CHANGE_NOTIFICATION_SETTING_BY_ID"] + userNotificationSettingId).subscribe(result => {
      this.data = result;
      if (this.data.status === 200) {
        this.getAllNotificationSettings();
        this.dataService.appConstant.presentToast(this.data.message);
      } else {
      }
    })
  }

}
