import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { UnderMantananceComponent } from './component/under-mantanance/under-mantanance.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { StoreLocationComponent } from './component/store-location/store-location.component';
import { UnsubscribeNewsletterComponent } from './component/unsubscribe-newsletter/unsubscribe-newsletter.component';
import { AuthguardGuard } from './services/authguard.guard'
import { NewHeaderComponent } from './component/new-header/new-header.component';
import{NewHomeComponent}from './component/new-home/new-home.component';
import { ProductDetailPageComponent } from './component/product-detail-page/product-detail-page.component';
import { FaqPageComponent } from './component/faq-page/faq-page.component';

const routes: Routes = [
  
  {
    path: '',
    component: NewHomeComponent,
   
  },
  // {
  //   path: '',
  //   component: HomeComponent,
  // },
  // {
  //   path: 'web/home',
  //   component: HomeComponent
  // },
  {
    path: 'web/unsubscribe-notificationsetting/:tab',
    component: UnsubscribeNewsletterComponent
  },
  {
    path: 'web/product-detail',
    component: ProductDetailPageComponent
  },
  {
    path: 'web/store_location',
    component: StoreLocationComponent
  },
  {
    path: 'web/policyPage/:tab',
    component: PrivacyPolicyComponent
  },
  {
    path: 'web/not-available',
    component: UnderMantananceComponent
  },
  {
    path: 'web/faq',
    component: FaqPageComponent,
   
  },
  { path: 'web/ag', loadChildren: () => import('./website-pages/website-pages.module').then(m => m.WebsitePagesModule) }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // relativeLinkResolution: 'legacy',
    // useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
