

<footer class="mt-4" [ngStyle]="{'background': orgnizationData.isAddGradientNavBar && orgnizationData.navBarColor ? orgnizationData.navBarColor : '', 'important': 'true'}">
  <div class="container-fluid footer-row" >
    <div class="row">
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xxl-2 footer-title text-center" *ngIf="orgnizationData" >
        <h4 class="card-img-top" routerLink="">
          <!-- <b
                      *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</b> -->
          <!-- <img class="review-image"
          src="{{dataService.appConstant.IMAGE_URL}}{{orgnizationData.filePath}}"
          loading="lazy" onError="this.src='assets/img/errorLogo.png'"> -->
          <!-- <img class="review-image" src='assets/img/logo-2.png'> -->
          <img class="review-image" src="{{dataService.appConstant.IMAGE_URL}}{{orgnizationData.filePath}}"
            loading="lazy" onError="this.src='assets/img/errorLogo.png'">
          <!-- {{orgnizationData.organizationName}} -->
        </h4>
        <ul class="Social-icons">
          <li><a *ngIf="footerData && footerData.fbLink" href="{{footerData.fbLink}}"><i
                class="fa-brands fa-facebook-f"></i></a></li>
          <li><a *ngIf="footerData && footerData.instagramLink" href="{{footerData.instagramLink}}"><i
                class="fa-brands fa-instagram"></i></a></li>
         <li><a *ngIf="footerData && footerData.linkedinLink" href="{{footerData.linkedinLink}}"><i
                  class="fa-brands fa-linkedin"></i></a></li>
        </ul>
        <div class="d-flex">
          <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
            <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
          </a>
          <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
            <img class="playstore-icon" src="assets/img/AppStore.png" alt="Logo">
          </a>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xxl-2 text  font-color footer-title" *ngIf="categoryList && categoryList.length>3">
        <div>
          <h2 >CATEGORIES</h2>
          <ul>
            <li class="cursorPointer" *ngFor="let category of categoryList | slice:0:7;let last=last"
              routerLink="/web/ag/products"
              [queryParams]="{category:category.name,category_uid:category.categoryUid,onPage: true }"
              (click)="scrollTop()">
              <a class="capitalize-first">{{category.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-brand text font-color footer-title" *ngIf="brandList && brandList.length>3">
        <div>
          <h2>BRANDS</h2>
          <ul>
            <li class="cursorPointer" *ngFor="let brand of brandList | slice:0:7;let last=last"
              routerLink="/web/ag/products"
              [queryParams]="{brand:brand.brandName,brand_uid:brand.brandUid,onPage: true }" (click)="scrollTop()">
              <a class="capitalize-first">{{brand.brandName}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xxl-2 font-color footer-title">
        <h2>COMPANY</h2>
        <ul class="cursorPointer">
          <li *ngIf="faqList && faqList.length>0">
            <a class="font-color"  href="/web/faq"> FAQs</a>
            <br>
          </li>
           <li *ngIf="footerData.isShowAboutUs" (click)="goToPrivacyPolicy('About_Us')">
               <a>About Us</a>
            </li>
          <li *ngIf="footerData.isShowPrivacyPolicy" (click)="goToPrivacyPolicy('Privacy_Policy')">
            <a>Privacy Policy</a>
          </li>
          <li *ngIf="footerData.isShowShippingPolicy" (click)="goToPrivacyPolicy('Shipping_Policy')">
            <a>Shipping Policy</a>
          </li>
          <li *ngIf="footerData.isShowTermsConditions" (click)="goToPrivacyPolicy('Terms_and_Conditions')">
            <a>Terms and Conditions</a>
          </li>
          <li *ngIf="footerData.isShowReturnPolicy" (click)="goToPrivacyPolicy('Return_Policy')">
            <a>Refund and Return Policy</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xxl-2 font-color text-width footer-title">
        <div>
          <h2>Contact US</h2>
          <ul  class="cursorPointer">
            <li *ngIf="footerData  ||  orgnizationData" class="row">
              <!-- <img src="assets/img/Mobile.png" alt="Icon" width="100%" height="auto" class="footer-icon"> -->
              <div class="col-md-0 col-sm-0 col-lg-0 col-xl-0 col-xxl-0">
              <i class="fa fa-mobile-alt address-icon"></i>
              </div>
              <div class="col-md-11 col-sm-11 col-lg-11 col-xl-11 col-xxl-11 pl-1">
              <a *ngIf="footerData.mobileTags && footerData.mobileTags.length == 0" class="font-color" href="tel:+{{orgnizationData.mobile}}"> +91 {{orgnizationData.mobile}}</a>
              <a *ngIf="footerData.mobileTags && footerData.mobileTags.length>0" class="font-color" href="tel:+{{footerData.mobileTags}}">
                 +91 {{ footerData.mobileTags.join(', ') }}</a>
                 </div>
              <br>
            </li>
            <li *ngIf="footerData  ||  orgnizationData" class="row">
              <!-- <img src="assets/img/Email.png" alt="Icon" width="100%" height="auto" class="footer-icon w-12px"> -->
              <div class="col-md-0 col-sm-0 col-lg-0 col-xl-0 col-xxl-0">
              <i class="fa fa-envelope address-icon envolopesize"></i>
              </div>
              <div class="col-md-11 col-sm-11 col-lg-11 col-xl-11 col-xxl-11 pl-1">
              <a *ngIf="footerData.emailTags && footerData.emailTags.length == 0" class="font-color" href="mailto: {{orgnizationData.email}}"> {{orgnizationData.email}}</a>
              <a *ngIf="footerData.emailTags && footerData.emailTags.length>0" class="font-color" href="mailto: {{footerData.emailTags}}">
                {{ footerData.emailTags.join(', ') }}</a>
                </div>
              <!-- <a class="font-color" href="mailto: {{orgnizationData.email}}"> {{orgnizationData.email}}</a> -->
              <br>
            </li>
            <li *ngIf="footerData && footerData.address" class="row">
              <!-- <img src="assets/img/Current Location.svg" alt="Icon" width="100%" height="auto" class="footer-icon"> -->

              <div class="col-md-0 col-sm-0 col-lg-0 col-xl-0 col-xxl-0">
                <i class="fa fa-location address-icon"></i>
              </div>
              <div class="col-md-11 col-sm-11 col-lg-11 col-xl-11 col-xxl-11 pl-1">
              <a class="font-color">  <span *ngIf="footerData.address.addressLine1">  {{ footerData.address.addressLine1 }} </span>
                <span *ngIf="footerData.address.addressLine2"> ,{{ footerData.address.addressLine2 }} </span>
                <span *ngIf="footerData.address.city">, {{ footerData.address.city }}</span>
                <span *ngIf="footerData.address.state">, {{ footerData.address.state }} </span>
                <span *ngIf="footerData.address.country">, {{ footerData.address.country }} </span>
                <span *ngIf="footerData.address.pinCode">, {{ footerData.address.pinCode }}</span></a>
                </div>
              <br>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xxl-2 font-color text-width footer-title" style="text-align: left;">
        <!-- <div class="d-flex"> -->
         <div>
         <img *ngIf="footerData.poweredByFilePath" class="review-image" src="{{dataService.appConstant.IMAGE_URL}}{{footerData.poweredByFilePath}}">
         </div>
          <!-- <div>
            <a href="https://apnagrahak.com/" target="_blank">
              <img class="poweredby" src="assets/img/Powered By AG 5.png" alt="Logo"></a>
          </div> -->
          <!-- <div>
            <a href="https://sheleadsindia.in/" target="_blank">
              <img class="communityPartner" src="assets/img/shelead100-2.png" alt="Logo"></a>
          </div> -->
          <div class="mt-3">
            <img *ngIf="footerData.initiativeByFilePath" class="review-image" src="{{dataService.appConstant.IMAGE_URL}}{{footerData.initiativeByFilePath}}">
            </div>
      <!-- </div> -->
      </div>
    </div>
  </div>

  <section id="footer-copyright" >
    <div class="container">
      <div class="row">
        <div class="col-md-8 copy-right" *ngIf="orgnizationData && orgnizationData.organizationName">
          <p class="" *ngIf="footerData && footerData.copyright">{{footerData.copyright}}</p>
        </div>
       
        <div class="col-md-8 copy-right cursorPointer" *ngIf="orgnizationData && orgnizationData.organizationName">
          <p>Copyright © 2023 <a href="https://apnagrahak.com/" target="_blank">Apna Grahak.</a> All Rights Reserved.</p>
        </div>
        <div class="col-md-4 copy-top">
          <!-- <img src="assets/img/Payments.webp" alt="Banks" width="100%" height="auto"> -->
        </div>
      </div>
    </div>
  </section>
</footer>