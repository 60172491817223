
<header id="header-main" class="desktopView">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 logo cursorPointer"  routerLink="">
        <img src="assets/img/Logo.png" alt="Logo" width="100%" height="auto">
      </div>
      <div class="col-md-5 search-btn">
      </div>
      <div class="col-md-4 store">
  
      </div>
    </div>
  </div>
</header>


<div class="pageDiv">
<div class="row">
    <div class="col-6">
      <div class="content">
        <h5>Website is currently down for maintenance</h5>
        <span class="subMsg">We expect to be back in a couple hours. Thanks for your patience</span>
        <div class="btnDiv">
            <button type="button" (click)="getOrganization1()" class="btn btn-primary secGradient whiteClr rectangleBtn appButton submitBtn"
            [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
              <span class="button__text">Check Again</span>
            </button>
        </div>
      </div>
    </div>
    <div class="col-6">
        <div class="section_container">
            <img src="assets/img/serverMaintenance.png">
            </div>            
    </div>
</div>
</div>

<!-- <div *ngFor="let item of array;let i=index">
    {{i}}] Main
    <div *ngIf="item.type ==1 && item.uType == 1">{{item.name}}</div>
    <div *ngIf="item.type == 2 && item.uType == 3">{{item.name}}</div>
    <div *ngIf="item.type == 1 && item.uType == 2">{{item.name}}</div>
    <hr>
</div> -->