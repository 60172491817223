import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouterEvent } from '@angular/router';
import Drift from 'drift-zoom';
declare const $: any;

@Component({
  selector: 'app-product-detail-page',
  templateUrl: './product-detail-page.component.html',
  styleUrls: ['./product-detail-page.component.scss']
})
export class ProductDetailPageComponent implements OnInit {
  isSelectedVarient: boolean = false;

  @ViewChild('scrollDiv') scrollDiv: ElementRef;
  hoveredImage: string | null = null;
  advertisingSlider1: OwlOptions = {
    loop: false,
    items: 5,
    autoWidth: false,
    mouseDrag: true,
    autoplay: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<span class="text-info"><i class="fa fa-chevron-left"></i></span>', // Previous button
      '<span class="text-info"><i class="fa fa-chevron-right"></i></span>' // Next button]
    ],


    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      },
    },
    nav: true
  };
  detailslider: OwlOptions = {
    loop: false,
    items: 5,
    autoWidth: false,
    mouseDrag: true,
    autoplay: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      },
    },
    nav: true
  };
  current: any;
  shipmentData: any = {};


  public comboList: any = [{}, {}, {}]
  public isCategoryLoading: boolean = false;
  public pagination: any = { pageNum: 1, numPerPage: 4 };
  public reviewListpagination: any = { pageNum: 1, numPerPage: 4 };
  numPerPage: any = 4;
  public categoryList: any = []
  public user: any = {};
  public data: any;
  public isLoading: boolean = false;
  prodName: any;
  itemUid: any;
  brandUid: any;
  categoryUid: any;
  categoryName: any;
  brandName: any;

  public item: any = {};
  public initialPrice: any;
  subscription: Subscription;
  tempCartListCount: number = 0;
  public currencySymbol: any;
  public cartList: any = [];
  public browserRefresh = false;
  public level1CategoryName: any;
  public level2CategoryName: any;
  public level3CategoryName: any;
  isLikeDislikeData: any = {};
  questionForm: boolean = false;
  questionFormData: any = {};
  //GET REVIEW LIST

  isReviewLikeDislikeData: any = {};
  reviewForm: boolean = false;
  reviewFormData: any = {};
  orgnizationData: any = { "isShiprocketAvailable": true };
  otherChildItemsBOList: any = [];
  userUid: any;
  isShiprocketAvailable: boolean = false;
  selectedValue: any = 'Latest';

  sessionArray: any = [];
  specificationTag: any = [];
  itemImages: any = [];
  //CART
  public proceedToAdd: any = {};
  public frequentlyBroughtFreshProd: any = []
  public frequentlyBroughtProd: any = []
  public isButtonDisabled: boolean = false


  public freshComboItemsList: any = []
  public comboItemsList: any = []
  recentlyViewList: any = [];
  freshRecentlyViewList: any = [];
  reviewRatingobject: any = {};
  reviewresponse: any = [];
  listcount: any;
  ratingCount: any;
  usernameFirstLetter: any;
  reviewUsername: any;
  reviewFirstUsername: any;
  reviewLastUsername: any;
  pageNo: any;
  carouselOptions = {
    items: 4,
    loop: false,
    touchDrag: true,
    pullDrag: true,
    mouseDrag: true,
    dots: false,
    nav: true,
    autoplay: true,
    navText: ['<span class="custom-nav-button "><i class="fa fa-chevron-left"></i></span>', // Previous button
      '<span class="custom-nav-button mx-3"><i class="fa fa-chevron-right "></i></span>' // Next button]
    ],
    // navText: ['<i class="fa fa-circle-chevron-left"></i>', '<i class="fa fa-circle-chevron-right"></i>'],
    responsive: {
      0: {
        items: 2
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      }
    }
  };
  currentimageIndex: number = 0;
  warehouseData: any = {};
  delivaryData: any = {};
  isLengthValid: boolean = true;
  inputNumber: string;
  reviewRatingCnt: any = 1;
  delivaryResultData: any = {};
  isZoomed: boolean = false;
  currentimageIndexpopup: number = 0;
  selectedWarehouse: any = {};
  zipcode: any;
  // new
  parentItemAttributeBOList: any = [];
  selectedValues: { [key: string]: any } = {};
  selectedAttributeName: String;
  selectedSizeIndices: { [key: string]: number } = {};
  itemVarientList: any = [];
  sizes: any = [];
  colors: any = [];
  materials: any = [];
  selectedSize: any;
  selectedColor: string = "";
  selectedMaterial: string = "";
  selectedColorIndex: number = 0;
  selectedMaterialIndex: number = 0;
  otherChildItemsMap = new Map<string, any>();
  myOptions = {
    'show-delay': 1000,
    'animation-duration': 300,
  }



  constructor(public dataService: CommanservicesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private _location: Location,
    private route: ActivatedRoute,
    private metaService: Meta,
    private titleService: Title) {
    if (isPlatformBrowser(this.platformId)) {
      this.orgnizationData = JSON.parse(localStorage.getItem("User_Org"));
      if (this.orgnizationData) {
        this.isShiprocketAvailable = this.orgnizationData.isShiprocketAvailable;
      }

      this.selectedWarehouse = JSON.parse(localStorage.getItem("warehouse"));
      this.user = JSON.parse(localStorage.getItem("userInfo"));
      this.currencySymbol = this.dataService.appConstant.getCurrencySymbol();
      this.cartList = JSON.parse(localStorage.getItem("cartList"));
      this.categoryList = JSON.parse(sessionStorage.getItem("categoryList"))



      this.route.params.subscribe(params => {
        this.prodName = params.prodName
        if (this.prodName) {
          this.getItemByName();
        }
      });


      this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.browserRefresh = !router.navigated;
        }
      });
      this.route.queryParams.subscribe(params => {
        if (params.itemUid) {
          this.itemUid = params.itemUid;
          this.getProductDetail();
        }

        if (params.brandUid) {
          this.brandUid = params.brandUid;
        }
        if (params.categoryUid) {
          this.categoryUid = params.categoryUid;
        }
        if (params.categoryName) {
          this.categoryName = params.categoryName;
        }
        if (params.brandName) {
          this.brandName = params.brandName;
        }

        if (params.category) {
          this.level1CategoryName = params.category;
        } else {
          this.level1CategoryName = null;
        }
        if (params.sub_category) {
          this.level2CategoryName = params.sub_category;
        } else {
          this.level2CategoryName = null;
        }
        if (params.ch_category) {
          this.level3CategoryName = params.ch_category;
        } else {
          this.level3CategoryName = null;
        }
      });
    }
    this.dataService.getMessage().subscribe((msg) => {
      if (msg === "LoggedIn") {
        this.user = JSON.parse(localStorage.getItem("userInfo"));
        if (sessionStorage.getItem('questionForm') && sessionStorage.getItem('questionForm') == "true") {
          this.questionForm = true;
          sessionStorage.removeItem('questionForm');
        }

        if (sessionStorage.getItem('reviewForm') && sessionStorage.getItem('reviewForm') == "true") {
          this.reviewForm = true;
          sessionStorage.removeItem('reviewForm');
        }


      }
      if (msg === "productDetail") {
        this.addToCart(this.item)
        sessionStorage.removeItem('pageName');
        sessionStorage.removeItem('productListIndex');
        sessionStorage.removeItem('selectedVariantIndex');
      }
      if (msg == 'cartListUpdated') {
        this.cartList = JSON.parse(localStorage.getItem("cartList"));
        this.updateProductList();
      }
      if (msg == 'categoryUpdated') {
        this.categoryList = JSON.parse(sessionStorage.getItem("categoryList"))
      }
    });
  }

  ngOnInit(): void {


    if (this.selectedWarehouse) {
      this.zipcode = this.selectedWarehouse.zipCode;
    } else {
      this.getStore();
    }

    var btn = $('#backToTopbuttonDetails');
    if (this.user) {
      this.getRecentlyViewData();
    }

    this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
    // let a = JSON.stringify(this.warehouseData);

    this.dataService.getDomainWiseOrgnization();
    this.orgnizationData = this.dataService.currentMessage.subscribe(message => this.orgnizationData = message)
  }

  getStore() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_WAREHOUSE"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        let tempWarehouseList = this.data.result;
        if (tempWarehouseList && tempWarehouseList.length > 0) {
          this.zipcode = tempWarehouseList[0].zipCode;
        }
      }
    })
  }
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event) {
  //     const scrollTop = window.pageYOffset;
  //     this.scrollDiv.nativeElement.scrollTop = scrollTop;
  // }
  back() {
    this._location.back();
  }
  itemDetail(item) {
    // if (item.itemLink) {
    //   this.router.navigate(['/web/ag/product', item.itemLink]);
    // } else {
    this.router.navigate(['web/product-detail'], { queryParams: { itemUid: item.itemUid } });
    window.scrollTo(0, 0)
    this.currentimageIndex = 0
    this.item.imageFilePath = this.itemImages[0].filePath;
    // }
  }
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.inputNumber = inputElement.value;
  }
  showDelivaryTime() {

    if (this.inputNumber) {
      this.isLengthValid = this.inputNumber.length == 6;
    } else {
      this.isLengthValid = false
    }

    if (this.isLengthValid) {
      this.shipmentData.pickupPincode = this.zipcode;
      this.shipmentData.deliveryPincode = String(this.shipmentData.deliveryPincode);
      this.shipmentData.weight = null;
      this.dataService.postRequest(this.shipmentData, this.dataService.appConstant.SERVER_URLS['ADD_SHIPMENT']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.delivaryResultData = this.data.result

        }
        else {
          this.dataService.appConstant.errorMsg(this.data.message);
          this.delivaryResultData = {};
        }
      })
    }

  }
  getProductDetail() {
    this.isLoading = true;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    this.pagination.itemUid = this.itemUid;
    this.dataService.openPostRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['ITEM_DETAILS_API']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.item = this.data.result;

        this.title.setTitle(this.item.displayName);
        this.meta.updateTag({ name: 'description', content: this.item.longDesc });

        this.item.isAdded = false
        this.item.quantity = 1;
        if (this.user && this.cartList && this.cartList.length > 0) {

          const foundObject = this.cartList.find(catObj => catObj.itemUid === this.item.itemUid);
          if (foundObject) {
            this.item.isAdded = true
            this.item.quantity = foundObject.quantity;
            this.item.cartId = foundObject.cartId;
          }

        }

        if (!this.user) {
          let cartList = JSON.parse(localStorage.getItem('tempCartList'));
          if (cartList && cartList.length > 0) {
            const foundObject = cartList.find(catObj => catObj.itemUid === this.item.itemUid);
            if (foundObject) {
              this.item.isAdded = true
              this.item.quantity = foundObject.quantity;
            }
          }
        }

        if (this.item && this.item.ecommItemBOList && this.item.ecommItemBOList?.length > 0) {
          this.item.ecommItemBOList.forEach(element => {
            element.isAdded = false
            element.quantity = 1;
            if (this.cartList && this.cartList.length > 0) {
              this.cartList.forEach(element3 => {
                if (element3.itemUid == element.itemUid) {
                  element.isAdded = true
                  element.quantity = element3.quantity;
                  element.cartId = element3.cartId;
                }
              });
            }

            if (!this.user) {
              let cartList = JSON.parse(localStorage.getItem('tempCartList'));
              if (cartList && cartList.length > 0) {
                cartList.forEach(element3 => {
                  if (element3.itemUid == element.itemUid) {
                    element.isAdded = true
                    element.quantity = element3.quantity;
                    element.cartId = element3.cartId;
                  }
                });
              }
            }

          });
        }

        this.getReviewList();
        // this.getQuestionList();

        if (!this.browserRefresh) {
          if (this.user && this.user.userUniqueId) {
            this.addRecentlyViewData()
          }
        }

        if (!this.item.quantity) {
          this.item.quantity = 1;
        }
        this.initialPrice = this.item.salePrice;
        this.sessionArray.sort((a, b) => 0 - (a > b ? -1 : 1));

        if (this.item && this.item.avgRating > 0) {
          this.item.blankRating = 5 - this.item.avgRating;
        }
        this.specificationTag = this.item.itemSpecBoList;

        if (this.item.itemImageBoList) {
          this.itemImages = this.item.itemImageBoList;
        }
        this.otherChildItemsBOList = this.item.otherChildItemsBOList;
        this.isLoading = false;
        this.getRelatedProducts();


        for (const itemObject of this.otherChildItemsBOList) {
          const varientStr = itemObject.varientStr;
          const itemUid = itemObject.itemUid;
          this.otherChildItemsMap.set(varientStr, itemUid);
        }

        // multiple varients------------>
        this.parentItemAttributeBOList = this.item.parentItemAttributeBOList;
        if (this.parentItemAttributeBOList && this.parentItemAttributeBOList.length > 0) {
          this.parentItemAttributeBOList.forEach((attribute: any) => {
            const attributeName = attribute.attributeName;
            if (this.selectedValues[attributeName] === undefined) {
              this.selectedValues[attribute.attributeName] = attribute.attributeValueList[0];
              this.selectedSizeIndices[attribute.attributeName] = 0;
              //  this.selectedValues[attributeName] =  this.selectedValues[attribute.attributeName]

            }
          });
        }
        this.itemVarientList = this.item.itemVarientBoList;
        if (this.otherChildItemsBOList.length > 0) {
          this.getUniqueValuesFromOtherChildList();
        }
        this.isLoading = false;
        this.getRelatedProducts();
      } else {
        this.isLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);

      }
    }, err => {
      this.isLoading = false;
    })


    // else{
    //   this.isLoading = false;
    //   this.dataService.appConstant.errorMsg(this.data.message) 
    // }  



  }
  addRecentlyViewData() {
    let obj: any = {
      "itemUid": this.itemUid,
      "userUid": this.user.userUniqueId
    }
    this.dataService.postRequest(obj, this.dataService.appConstant.SERVER_URLS['ADD_RECENTLY_VIEW_LIST']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.getRecentlyViewData();
      }
    })
  }
  updateProductList() {
    this.item.isAdded = false
    this.item.quantity = 1;
    this.cartList = JSON.parse(localStorage.getItem("cartList"));


    if (this.user) {
      if (this.cartList && this.cartList.length > 0) {
        this.cartList.forEach(element3 => {
          if (element3.itemUid == this.item.itemUid) {
            this.item.isAdded = true
            this.item.quantity = element3.quantity;
            this.item.cartId = element3.cartId;
          }
        });
      }
    }

    if (!this.user) {
      let cartList = JSON.parse(localStorage.getItem('tempCartList'));
      if (cartList && cartList.length > 0 && cartList.some(cart => cart.itemUid === this.item.itemUid)) {
        cartList.forEach(element3 => {
          if (element3.itemUid == this.item.itemUid) {
            this.item.isAdded = true
            this.item.quantity = element3.quantity;
          }
        });
      }
    }

    if (this.item && this.item.ecommItemBOList && this.item.ecommItemBOList?.length > 0) {
      this.item.ecommItemBOList.forEach(element => {
        element.isAdded = false
        element.quantity = 1;
        if (this.cartList && this.cartList.length > 0) {
          this.cartList.forEach(element3 => {
            if (element3.itemUid == element.itemUid) {
              element.isAdded = true
              element.quantity = element3.quantity;
              element.cartId = element3.cartId;
            }
          });
        }

        if (!this.user) {
          let cartList = JSON.parse(localStorage.getItem('tempCartList'));
          if (cartList && cartList.length > 0 && cartList.some(cart => cart.itemUid === element.itemUid)) {
            cartList.forEach(element3 => {
              if (element3.itemUid == element.itemUid) {
                element.isAdded = true
                element.quantity = element3.quantity;
              }
            });
          }
        }
      });
    }


    if (this.user) {
      this.recentlyViewList.forEach(element => {
        element.isAdded = false;
        element.quantity = 1;
        if (this.cartList && this.cartList.length > 0) {
          this.cartList.forEach(element3 => {
            if (element3.itemUid == element.itemUid) {
              element.isAdded = true
              element.quantity = element3.quantity;
              element.cartId = element3.cartId;
            }
          });
        }
      });
    }


    if (this.user) {
      this.frequentlyBroughtProd.forEach(element => {
        element.isAdded = false;
        element.quantity = 1;
        if (this.cartList && this.cartList.length > 0) {
          this.cartList.forEach(element3 => {
            if (element3.itemUid == element.itemUid) {
              element.isAdded = true
              element.quantity = element3.quantity;
              element.cartId = element3.cartId;
            }
          });
        }

        if (!this.user) {
          let cartList = JSON.parse(localStorage.getItem('tempCartList'));
          if (cartList && cartList.length > 0 && cartList.some(cart => cart.itemUid === element.itemUid)) {
            cartList.forEach(element3 => {
              if (element3.itemUid == element.itemUid) {
                element.isAdded = true
                element.quantity = element3.quantity;
              }
            });
          }
        }
      });
    }
  }

  public getRelatedProducts(): void {
    this.pagination.pageNum = 1;
    this.pagination.numPerPage = 6;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    this.pagination.itemUid = this.itemUid;
    this.pagination.isFeatured = null;
    this.dataService.postRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['GET_RELATED_PRODUCT']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.item.ecommItemBOList = this.data.result;

        if (this.user && this.cartList && this.cartList.length > 0) {
          this.item.ecommItemBOList.forEach(element => {
            element.isAdded = false;
            element.quantity = 1;
            if (this.cartList && this.cartList.length > 0) {
              this.cartList.forEach(element3 => {
                if (element3.itemUid == element.itemUid) {
                  element.isAdded = true
                  element.quantity = element3.quantity;
                  element.cartId = element3.cartId;
                }
              });
            }
          });
        }
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.dataService.throwError(err)
    })
  }
  // Post Review Button method
  saveReviewforms(WriteReviewform: NgForm) {
    if (this.user) {
      this.reviewFormData.rating = parseInt(this.reviewFormData.rating)
      if (this.reviewFormData.rating) {
        this.isButtonDisabled = true;
        this.reviewFormData.itemUid = this.item.itemUid,
          this.reviewFormData.userUid = this.user.userUniqueId;
        this.dataService.postRequest(this.reviewFormData, this.dataService.appConstant.SERVER_URLS['ADD_REVIEWS']).subscribe(result => {
          this.data = result;
          if (this.dataService.appConstant.SUCCESS === this.data.status) {
            this.dataService.appConstant.successMsg(this.data.message)
            this.reviewFormData = {};
            this.reviewForm = false;
            this.item.isReviewed = true;
            WriteReviewform.resetForm();
            this.getReviewList();
            this.isButtonDisabled = false;
          } else {
            this.isButtonDisabled = false;
            this.dataService.appConstant.errorMsg(this.data.message);
          }
        }, err => {
          this.isButtonDisabled = false;
        })
      } else {
        this.dataService.appConstant.warningMsg("Please Select Rating Stars");
      }
    } else {
      // this.loginModel1();
    }

  }
  cancleReviewForm() {
    this.reviewForm = false;
  }
  public validatePattern(event: any): void {
    if (!/[0-9]/.test(String.fromCharCode(event.charCode))) {
      event.preventDefault();
    }
  }
  getReviewList() {
    this.reviewListpagination.itemUid = this.itemUid;
    this.reviewListpagination.type = this.selectedValue
    if (this.user) {
      this.reviewListpagination.userUid = this.user.userUniqueId
    }
    this.dataService.openPostRequest(this.reviewListpagination, this.dataService.appConstant.SERVER_URLS['REVIEW_LIST']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.reviewRatingobject = this.data.result;
        this.listcount = this.reviewRatingobject.listCount;
        this.ratingCount = this.reviewRatingobject.ratingCount;
        this.pageNo = Math.ceil(this.ratingCount / this.numPerPage);

        if (this.reviewRatingobject && this.reviewRatingobject.avgRating > 0) {
          this.reviewRatingobject.blankRating = 5 - this.reviewRatingobject.avgRating;
        }
        this.reviewresponse = this.reviewRatingobject.itemRatingsList;
        if (this.reviewresponse && this.reviewresponse.length > 0) {
          this.reviewresponse.forEach(element => {
            if (element.rating) {
              element.blankRating = 5 - element.rating;
            }
          });

        }
        if (this.reviewresponse && this.reviewresponse.length > 0) {
          this.reviewresponse.forEach(element => {
            this.usernameFirstLetter = element.userName;
            this.reviewFirstUsername = this.usernameFirstLetter.split(" ");
            this.reviewLastUsername = this.reviewFirstUsername[0]
            let fname, lname, fchar, lchar: string;
            if (this.reviewFirstUsername[0]) {
              fname = this.reviewFirstUsername[0];
              fchar = fname.substring(0, 1)
            }
            if (this.reviewFirstUsername[1]) {
              lname = this.reviewFirstUsername[1];
              lchar = lname.substring(0, 1);
            }
            element.name = fchar + lchar;
            if (fchar && lchar) {
              element.name = fchar + lchar;
            } else if (fchar) {
              element.name = fchar;
            } else if (lchar) {
              element.name = lchar;
            }
          });
        }
      }
      else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    })
  }


  changeReviewCount(value: any) {
    if (value == 'increase') {
      if (this.reviewRatingCnt < this.pageNo) {
        this.reviewRatingCnt++;
      }
    } else {
      if (this.reviewRatingCnt > 1) {
        this.reviewRatingCnt--;
      }
    }
    this.reviewListpagination.pageNum = this.reviewRatingCnt;
    this.getReviewList();
  }


  getPagination(pageNum) {
    this.reviewListpagination.pageNum = pageNum;
    this.getReviewList();
  }
  writeReview() {

    if (this.user) {

      this.reviewForm = true;

    } else {
      this.dataService.sendMessage("ProceedForLogin")
      sessionStorage.setItem("reviewForm", "true");
    }


  }
  getItemByName() {
    let listObject = {
      "pageNum": 1,
      "numPerPage": 3,
      "customizeLink": this.prodName,
      "userUid": this.user.userUniqueId
    }
    this.isLoading = true;
    this.dataService.openPostRequest(listObject, this.dataService.appConstant.SERVER_URLS['ITEM_DETAIL_BY_NAME']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {

        this.item = this.data.result;
        if (this.item) {
          this.itemUid = this.item.itemUid
          this.title.setTitle(this.item.displayName);
          this.meta.updateTag({ name: 'description', content: this.item.longDesc });
        }
        this.item.isAdded = false
        this.item.quantity = 1;
        if (this.user && this.cartList && this.cartList.length > 0) {
          if (this.cartList && this.cartList.length > 0) {
            this.cartList.forEach(element3 => {
              if (element3.itemUid == this.item.itemUid) {
                this.item.isAdded = true
                this.item.quantity = element3.quantity;
                this.item.cartId = element3.cartId;
              }
            });
          }
        }

        if (this.item && this.item.ecommItemBOList && this.item.ecommItemBOList?.length > 0) {
          this.item.ecommItemBOList.forEach(element => {
            element.isAdded = false
            element.quantity = 1;
            if (this.cartList && this.cartList.length > 0) {
              this.cartList.forEach(element3 => {
                if (element3.itemUid == element.itemUid) {
                  element.isAdded = true
                  element.quantity = element3.quantity;
                  element.cartId = element3.cartId;
                }
              });
            }
          });
        }

        this.getReviewList();

        // this.getQuestionList();
        if (!this.browserRefresh) {
          if (this.user && this.user.userUniqueId) {
            this.addRecentlyViewData()
          }
        }
        if (!this.item.quantity) {
          this.item.quantity = 1;
        }
        this.initialPrice = this.item.salePrice;

        this.sessionArray.sort((a, b) => 0 - (a > b ? -1 : 1));

        if (this.item && this.item.avgRating > 0) {
          this.item.blankRating = 5 - this.item.avgRating;
        }
        this.specificationTag = this.item.itemSpecBoList;

        if (this.item.itemImageBoList) {
          this.itemImages = this.item.itemImageBoList;
        }
        this.otherChildItemsBOList = this.item.otherChildItemsBOList;
        this.isLoading = false;
        this.getRelatedProducts();
      } else {
        this.isLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isLoading = false;
    })
  }
  openImg(index) {
    this.currentimageIndex = index;
    this.currentimageIndexpopup = index;
    this.item.imageFilePath = this.itemImages[index].filePath;
  }

  selectVariant(item) {
    if (item.itemLink) {
      this.router.navigate(['web/product-detail', item.itemLink]);
    } else {
      this.router.navigate(['web/product-detail'], { queryParams: { itemUid: item.itemUid } });
    }
    this.currentimageIndex = 0
    this.itemUid = item.itemUid
    this.getProductDetail();
  }
  //ADD TO CART

  public addToCart(item): void {
    if (this.user) {
      this.proceedToAdd = this.item;
      let items = {
        'itemUid': item.itemUid,
        'productNo': item.productNo,
        'itemName': item.itemName,
        "userUid": this.user.userUniqueId,
        "isPackage": false,
        "otherOffer": true,
        "hasComboOffer": true,
        'purchasePrice': item.purchasePrice,
        'salePrice': item.salePrice,
        'finalPrice': item.finalPrice,
        'productGroupId': item.productGroupId,
        'mainImage': item.mainImage,
        'shipping': item.shippingcharges,
        'longDesc': item.longDesc,
        'quantity': 1,
        'taxId': item.taxId,
        'taxPerc': item.taxPerc,
        'taxAmt': item.taxAmt,
        'total': item.total,
        'brandName': item.brandName,
      };
      this.dataService.postRequest(items, this.dataService.appConstant.SERVER_URLS['ADD_TO_CART']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.item.isAdded = true;
          if (this.item.selectedItem) {
            this.item.selectedItem = this.item;
          }
          this.dataService.appConstant.successMsg("Item added into cart....")
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      }, err => {
        this.dataService.throwError(err)
      })

    } else {

      let cartList = JSON.parse(localStorage.getItem('tempCartList'));
      let product = item;
      // this.item.isAdded = true;
      // if (this.item.selectedItem) {
      //   this.item.selectedItem = this.item;
      // }
      if (cartList && cartList.some(cart => cart.itemUid === product.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === product.itemUid) {
            cart.quantity = cart.quantity + 1;
          }

        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      } else {
        let tempCartObj: any = {
          'quantity': null, "itemUid": null, "salePrice": null,
          "discountUid": null, "displayName ": null, "brandName": null, "varientStr": null, "isMrpDiscount": null,
          "isBuyXGetYDiscount": null, "itemName": null, "discPerc": null, "discountName": null
          , "discAmt": null, "finalPrice": null, "withoutDiscAmount": null, "mrp": null, "totalFreeQty": null, "imageFilePath": null
          , "isStockAvailable": null
        };
        tempCartObj.quantity = 1;
        tempCartObj.itemUid = product.itemUid;
        tempCartObj.displayName = product.displayName;
        tempCartObj.salePrice = product.finalPrice;
        tempCartObj.discountUid = product.discountUid;
        tempCartObj.isBuyXGetYDiscount = product.isBuyXGetYDiscount;
        tempCartObj.itemName = product.itemName;
        tempCartObj.varientStr = product.varientStr;
        tempCartObj.isMrpDiscount = product.isMrpDiscount;
        tempCartObj.discPerc = product.discPerc;
        tempCartObj.discountName = product.discountName;
        tempCartObj.discAmt = product.discAmt;
        tempCartObj.finalPrice = product.finalPrice;
        tempCartObj.withoutDiscAmount = product.withoutDiscAmount;
        tempCartObj.mrp = product.mrp;
        tempCartObj.totalFreeQty = product.totalFreeQty;
        tempCartObj.imageFilePath = product.imageFilePath;
        tempCartObj.isStockAvailable = true;
        let tempCartList = [];
        if (cartList) {
          tempCartList = JSON.parse(localStorage.getItem('tempCartList'));
        }
        tempCartList.push(tempCartObj);
        localStorage.setItem("tempCartList", JSON.stringify(tempCartList));
      }
      this.dataService.getCartList();
      this.updateProductList();
    }
  }
  public updateCartQuantity(type, item): void {
    this.proceedToAdd = item;
    if (this.user) {

      if (type == "increase") {
        this.proceedToAdd.quantity += 1;
      } else {
        this.proceedToAdd.quantity -= 1;
      }

      if (this.proceedToAdd.quantity < 1) {
        this.item.quantity = 1;
        this.item.isAdded = false;
        this.removeItemFromCart(this.proceedToAdd.cartId);
      } else {
        let items = {
          'itemUid': item.itemUid,
          'productNo': item.productNo,
          'itemName': item.itemName,
          "userUid": this.user.userUniqueId,
          "isPackage": false,
          "otherOffer": true,
          "hasComboOffer": true,
          'purchasePrice': item.purchasePrice,
          'salePrice': item.salePrice,
          'finalPrice': item.finalPrice,
          'productGroupId': item.productGroupId,
          'mainImage': item.mainImage,
          'shipping': item.shippingcharges,
          'longDesc': item.longDesc,
          'quantity': 1,
          'taxId': item.taxId,
          'taxPerc': item.taxPerc,
          'taxAmt': item.taxAmt,
          'total': item.total,
          'brandName': item.brandName,
          "cartId": item.cartId,
        };
        this.dataService.putRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.item.quantity = this.data.result.quantity;
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {
      console.log("---22--");
      let Qty = this.proceedToAdd.quantity;

      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.item.quantity = 1;
        this.item.isAdded = false;
        this.removeItemFromTempCart(this.proceedToAdd.itemUid);
      } else {
        this.item.quantity = Qty;
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === this.proceedToAdd.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === this.proceedToAdd.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }
      this.checkTempCartCount();
      this.dataService.getCartList();
      this.updateProductList();
    }
  }

  public removeItemFromCart(carId): void {
    this.dataService.deleteRequest(this.dataService.appConstant.SERVER_URLS['EDIT_CART'] + carId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.dataService.getCartList();
        // this.updateProductList();
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    });
  }
  public removeItemFromTempCart(itemUid: String): void {
    let cartList = JSON.parse(localStorage.getItem('tempCartList'));
    if (cartList && cartList.some(cart => cart.itemUid === itemUid)) {
      let index = cartList.findIndex(cart => cart.itemUid === itemUid);
      cartList.splice(index, 1);
      localStorage.setItem("tempCartList", JSON.stringify(cartList));
    }
  }
  public checkTempCartCount() {
    if (!this.user) {
      let tempCartList = JSON.parse(localStorage.getItem("tempCartList"));
      if (tempCartList) {
        this.tempCartListCount = tempCartList.length;
      }

    }
  }
  public getRecentlyViewData(): void {
    this.pagination.pageNum = 1;
    this.pagination.numPerPage = 30;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    this.pagination.itemUid = null;
    this.pagination.isFeatured = null;
    this.dataService.postRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['GET_RECENTLY_VIEW_LIST']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.freshRecentlyViewList = this.data.result;
        this.recentlyViewList = this.data.result;

        if (this.user && this.cartList && this.cartList.length > 0) {
          this.recentlyViewList.forEach(element => {
            element.isAdded = false;
            element.quantity = 1;
            if (this.cartList && this.cartList.length > 0) {
              this.cartList.forEach(element3 => {
                if (element3.itemUid == element.itemUid) {
                  element.isAdded = true
                  element.quantity = element3.quantity;
                  element.cartId = element3.cartId;
                }
              });
            }
          });
        }
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.dataService.throwError(err)
    })
  }

  a() {
    var demoTrigger = document.querySelector('.main-image-height');
    var paneContainer = document.querySelector('.detail');
    var zoomed = false;
    new Drift(demoTrigger, {
      paneContainer: paneContainer,
      inlinePane: false,
      zoomed: true
    });
  }

  share(shareBy) {

    let shareUrl;
    if (this.orgnizationData && this.orgnizationData.website) {
      shareUrl = this.orgnizationData.website + "/web/product-detail?itemUid=" + this.itemUid
      let itemDescription = "";
      if (this.orgnizationData.organizationName) {
        itemDescription += this.orgnizationData.organizationName+" \n";
      }
      if (this.item.mainImage) { itemDescription += this.dataService.appConstant.IMAGE_URL + this.item.imageFilePath + " \n"; }
      if (this.item.itemName) { itemDescription += this.item.itemName + " \n"; }
      if (this.item.salePrice) { itemDescription += "Price : " + this.item.salePrice + " \n"; }
      if (this.item.shortDesc) { itemDescription += this.item.shortDesc + " \n"; }
      let desc = encodeURIComponent(itemDescription);

      if (shareBy == 'whatsApp') {
        desc = desc + "\n" + shareUrl;
        if (window.innerWidth < 768) {
          window.open(`whatsapp://send?text=` + desc, 'sharer');
        } else {
          window.open(`https://web.whatsapp.com/send?text=` + desc, 'sharer');
        }
      }

      // if (shareBy == 'fb') {
      //   window.open(`https://www.facebook.com/sharer/sharer.php?u=http://aggrocery.apnagrahak.in/` + `&text=${desc}`, 'sharer');
      // }
      if (shareBy == 'fb') {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}/` + `&text=${desc}`, 'sharer');
      }

      if (shareBy == 'instagram') {
        window.open(`https://instagram.com/accounts/login/?text=${shareUrl} ${desc}`, 'sharer');
      }

      if (shareBy == 'twitter') {
        window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${desc}`, 'sharer');

      }

      // if (shareBy == 'Mail') {
      //   shareUrl = "mailto:''+?subject=files&body=" + itemDescription; // add the links to body
      //   window.location.href = shareUrl;
      // }
      if (shareBy == 'Mail') {
        const currentUrl = shareUrl;
        const body = itemDescription + " \n" + currentUrl //
        shareUrl = `mailto:''+?subject=files&body=${body}`  // add the links to body
        console.log("shareUrl-------->", shareUrl)
        window.location.href = shareUrl;
      }

      if (shareBy == 'share') {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        // selBox.value = itemDescription + "\n" + shareUrl;
        selBox.value = shareUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.dataService.appConstant.successMsg("Product details copied to clipboard")
      }
    }
  }



  apnagrocerySlider: OwlOptions = {
    items: 4,
    loop: false,
    dots: false,
    nav: true,
    autoplay: true,
    navText: ['<span class="custom-nav-button"><i class="fa fa-chevron-left"></i></span>', // Previous button
      '<span class="custom-nav-button mr-3 ml-3"><i class="fa fa-chevron-right"></i></span>' // Next button]
    ],
    responsive: {
      0: {
        items: 2
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      }
    }
  };

  public updateCartQuantityRecentlyViwe(type, item, index: number): void {
    this.proceedToAdd = item;
    if (this.user) {
      if (type == "increase") {
        this.proceedToAdd.quantity += 1;
      } else {
        this.proceedToAdd.quantity -= 1;
      }

      if (this.proceedToAdd.quantity < 1) {
        this.item.quantity = 1;
        this.item.isAdded = false;
        this.recentlyViewList[index].quantity = 1;
        this.recentlyViewList[index].isAdded = false;
        this.removeItemFromCart(this.proceedToAdd.cartId);
      } else {
        let items = {
          'itemUid': item.itemUid,
          'productNo': item.productNo,
          'itemName': item.itemName,
          "userUid": this.user.userUniqueId,
          "isPackage": false,
          "otherOffer": true,
          "hasComboOffer": true,
          'purchasePrice': item.purchasePrice,
          'salePrice': item.salePrice,
          'finalPrice': item.finalPrice,
          'productGroupId': item.productGroupId,
          'mainImage': item.mainImage,
          'shipping': item.shippingcharges,
          'longDesc': item.longDesc,
          'quantity': 1,
          'taxId': item.taxId,
          'taxPerc': item.taxPerc,
          'taxAmt': item.taxAmt,
          'total': item.total,
          'brandName': item.brandName,
          "cartId": item.cartId,
        };
        this.dataService.putRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.recentlyViewList[index].quantity = this.data.result.quantity;
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {
      let Qty = this.proceedToAdd.quantity;

      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.item.quantity = 1;
        this.item.isAdded = false;
        this.removeItemFromTempCart(this.proceedToAdd.itemUid);
      } else {
        this.item.quantity = Qty;
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === this.proceedToAdd.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === this.proceedToAdd.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }
      this.checkTempCartCount();
      this.dataService.getCartList();
      this.updateRecentlyViewProductList(index);
    }
  }

  updateRecentlyViewProductList(index: number) {
    this.item.isAdded = false
    this.item.quantity = 1;
    this.cartList = JSON.parse(localStorage.getItem("cartList"));


    if (this.user) {
      if (this.cartList && this.cartList.length > 0) {
        this.cartList.forEach(element3 => {
          if (element3.itemUid == this.item.itemUid) {
            this.item.isAdded = true
            this.item.quantity = element3.quantity;
            this.item.cartId = element3.cartId;
          }
        });
      }
    }

    if (!this.user) {
      let cartList = JSON.parse(localStorage.getItem('tempCartList'));
      if (cartList && cartList.length > 0 && cartList.some(cart => cart.itemUid === this.item.itemUid)) {
        cartList.forEach(element3 => {
          if (element3.itemUid == this.item.itemUid) {
            this.item.isAdded = true
            this.item.quantity = element3.quantity;
          }
        });
      }
    }



    if (this.user) {
      this.recentlyViewList[index].isAdded = false;
      this.recentlyViewList[index].quantity = 1;
      if (this.cartList && this.cartList.length > 0) {
        this.cartList.forEach(element3 => {
          if (element3.itemUid == this.recentlyViewList[index].itemUid) {
            this.recentlyViewList[index].isAdded = true
            this.recentlyViewList[index].quantity = element3.quantity;
            this.recentlyViewList[index].cartId = element3.cartId;
          }
        });
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element1 = document.getElementById('desktopnavebar');
    if (window.pageYOffset > 10) {
      // let element = document.getElementById('navbar');
      // element.classList.add('sticky');
      element1.classList.add('desktop-header-sticky');
    } else {
      // let element = document.getElementById('navbar');
      // element.classList.remove('sticky');
      element1.classList.remove('desktop-header-sticky');
    }
  }

  // Function to extract unique values for a specific attribute
  getUniqueValuesFromOtherChildList() {
    const uniqueSizeValues = new Set();
    const uniqueColorValues = new Set();
    const uniqueMaterialValues = new Set();
    this.otherChildItemsBOList.forEach(item => {
      item.itemVarientBoList.forEach(variant => {
        if (variant.attributeName == 'Size') {
          if (this.selectedSize == '') {
            this.selectedSize = variant.attributeValue;
          }
          uniqueSizeValues.add(variant.attributeValue);
        }
        else if (variant.attributeName == 'Color') {
          if (this.selectedColor == '') {
            this.selectedColor = variant.attributeValue;
          }
          uniqueColorValues.add(variant.attributeValue);
        }
        else if (variant.attributeName == 'Material ') {
          if (this.selectedMaterial == '') {
            this.selectedMaterial = variant.attributeValue;
          }
          uniqueMaterialValues.add(variant.attributeValue);
        }
      });
    });
    this.sizes = uniqueSizeValues;
    this.colors = uniqueColorValues;
    this.materials = uniqueMaterialValues;
  }
  selectNewVariant(event: MouseEvent, j, attributeName, itemValue) {
    event.preventDefault();

    this.selectedAttributeName = attributeName;
    this.selectedSizeIndices[attributeName] = j;
    this.selectedValues[attributeName] = itemValue;
    this.compareSelectedAttributes();
    // this.updateAvailableOptions();
  }

  compareSelectedAttributes() {
    console.log("----->" + JSON.stringify(this.selectedValues));

    const selectedAttributesString = Object.values(this.selectedValues).join('/ ');

    console.log("-------selectedAttributesString-----" + selectedAttributesString);

    const matchedItemUid = this.otherChildItemsMap.get(selectedAttributesString);
    if (matchedItemUid) {
      this.itemUid = matchedItemUid;
      this.isSelectedVarient = false;
      console.log('--=this.isSelectedVarient==', this.isSelectedVarient);
      this.getProductDetail();
    }
    else {
      this.isSelectedVarient = true;
      console.log('--=this.isSelectedVarient==', this.isSelectedVarient);

      console.log('No match found');
      // this.showCustomAlert();
    }
  }

  showCustomAlert(): void {
    const alertContainer = document.querySelector('.custom-alert');

    if (alertContainer) {

      alertContainer.classList.add('active');
      // setTimeout(() => {
      //   alertContainer.classList.remove('active');

      //   // if (this.parentItemAttributeBOList && this.parentItemAttributeBOList.length > 0) {
      //   //   this.parentItemAttributeBOList.forEach((attribute: any) => {
      //   //     const attributeName = attribute.attributeName;


      //   //     if (this.selectedValues && this.selectedValues[attributeName]) {

      //   //       this.selectedValues[attribute.attributeName] = attribute.attributeValueList[0];
      //   //       console.log('====>>>>>>',this.selectedValues[attribute.attributeName]);

      //   //       this.selectedSizeIndices[attribute.attributeName] = 0;
      //   //      }
      //   //    });
      //   // } 
      // }, 3000);
    }
  }
  setHoveredImage(imageUrl: string, index: number) {
    this.currentimageIndex = index;
    this.hoveredImage = imageUrl;
  }

  // updateAvailableOptions() {
  //   // Logic to filter available options based on selected attributes
  //   // Assuming otherChildItemsBOList contains available variants
  //   // and selectedValues contains the currently selected attributes
  //   this.availableOptions = this.otherChildItemsBOList.filter(variant => {
  //       // Check if the variant matches all selected attribute values
  //       return Object.keys(this.selectedValues).every(attributeName => {
  //           return variant[attributeName] === this.selectedValues[attributeName];
  //       });
  //   });
  // }



}



