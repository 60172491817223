<div class="card">
  <div class="row">
    <div class="col-md-12 col-12 col-sm-12 col-xl-12 col-lg-12">
      <!-- <form #appFormUser="ngForm" (ngSubmit)="appFormUser.form.valid && cancelSubscription()" novalidate>

        <h4>You have been unsubscribed.</h4>
        <div class="subMsg1">You have been removed from the list</div>
        <div class="subMsg2"><b>If you have a moment, please let us know why you unsubscribed</b></div>
        <div class="inputGroup">
          <div *ngFor="let reasone  of reasoneList">
            <input type="radio" [(ngModel)]="formData.comment" [value]="reasone.reason"
              (ngModelChange)="formData.customReason == null" name="comment"> {{reasone.reason}}
          </div>
        </div>
        <div *ngIf="formData.comment == 'Other(fill in below)' || formData.customReason ">
          <textarea class="writeAReasone" rows="2" cols="200" [(ngModel)]="formData.customReason" required
            placeholder="Write unsubscribed reason here" name="reason"> </textarea>
        </div>

        <div>
          <br>
          <button type="submit" class="btn btn-secondary submitBtn" [disabled]="isButtonDisabled"
            [ngClass]="{'button--loading':isButtonDisabled}">
            <span class="button__text">SUBMIT</span></button>
        </div>

      </form> -->
      <div class="storemaindiv card">
        <ul>
          <li>
            <div class="row">
              <div class="col-6 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label>
                  <span class="font16">Enable All</span>
                  <div class="belowtext">
                    Active All Notifications
                  </div>
                </label>
              </div>
              <div class="col-6 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label class="switch">
                  <input type="checkbox" name="all" (ngModelChange)="changeAllNotification(oneNotificationIsFalse)"
                    [(ngModel)]="oneNotificationIsFalse">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="storemaindiv card" *ngFor="let data of notificationSettingList">
        <ul>
          <li>
            <label>
              <span class="font18">{{data.groupName}}</span>
              <div class="belowtext">
                Receive newsletter to stay up-to date with whats brewing in
                food industry
              </div>
            </label>
          </li>
          <li *ngFor="let settings of data.settingList;let i=index">
            <div class="row">
              <div class="col-6 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 marautocol">
                <label>
                  <span class="font16">{{settings.settingType}}</span>
                </label>
              </div>
              <div class="col-6 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 marautocol">
                <label class="switch">
                  <input type="checkbox" name="allsettings{{i}}" [(ngModel)]="settings.status"
                    (ngModelChange)="changeNotificationSettingById(settings.userNotificationSettingId)">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<br>



<div class="modal fade commonPopUP" id="unsubscribed" tabindex="-1" role="dialog" aria-labelledby="signInModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="tCenter">
          <h5 style="color:green"><b>You have been unsubscribed successfully.</b></h5>
        </div>
      </div>
    </div>
  </div>
</div>