import { Component, OnInit,PLATFORM_ID,Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {

    list:any = [{},{},{},{},{},{},{},{},{},{},{},{},]

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object) { 

      if (isPlatformBrowser(this.platformId)) {
        // MouseEvent code
     }
    }

  ngOnInit(): void {
  }

}
